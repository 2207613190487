import { SpaConfiguration } from '@configuration/SpaConfiguration';
import { Authentication } from '@app/classes/Authentication';
import { BotProtection } from '@src/app/classes/BotProtection';
import * as QueryString from 'query-string';
import { EntityDefinition } from '@definitions/EntityDefinition';
import { IRetrieveMultipleResponse } from '../interfaces/IRetrieveMultipleResponse';
import { MultitenantProvider } from '@app/classes/MultitenantProvider';
import { sanitizeGuid } from '@app/Functions';
import { DomParser } from '@app/Constants';
import { sendMetadataGetRequest } from '@definitions/MetadataApi';
import { Liquid } from 'liquidjs';
import { updateFetchXmlWithPaging } from '@src/components/controls/DatasetControl/utils/FetchXmlUtils';

export interface IInterceptor {
    action: { (fetchXml: string): Promise<string> };
    priority: number;
}

interface IParameterType {
    typeName: string;
    structuralProperty: number;
    enumProperties: [{
        name: string;
        value: number;
    }]
}

interface IRetrieveCurrentOrganizationRequest {
    AccessType: number;
    getMetadata: () => {
        boundParameter: string;
        parameterTypes: {
            AccessType: IParameterType;
        };
        operationType: 1;
        operationName: "RetrieveCurrentOrganization";
    }
}
export interface IAssociateRequest {
    target: {
        entityType: string;
        id: string;
    };
    relatedEntities: [{
        entityType: string;
        id: string;
    }];
    relationship: string;
    getMetadata: () => {
        boundParameter: string;
        parameterTypes: {
            target?: IParameterType;
            relatedEntities?: IParameterType;
            relationship?: IParameterType;
        };
        operationType: 2;
        operationName: "Associate";
    }

}
export interface IDisassociateRequest {
    target: {
        entityType: string;
        id: string;
    };
    relatedEntityId: string;
    relationship: string;
    getMetadata: () => {
        boundParameter: string;
        parameterTypes: {
            target?: IParameterType;
            relatedEntities?: IParameterType;
            relationship?: IParameterType;
        };
        operationType: 2;
        operationName: "Disassociate";
    }

}
interface IInitializeFileBlocksDownloadRequest {
    Target: {
        "@odata.type": string;
        [PrimaryIdAttribute: string]: string;
    };
    FileAttributeName: string;
    getMetadata: () => {
        boundParameter: string;
        parameterTypes: {
            Target: IParameterType;
            FileAttributeName: IParameterType;
        };
        operationType: number;
        operationName: "InitializeFileBlocksDownload";
    }
}

interface IInitializeFileBlocksUploadRequest {
    Target: {
        "@odata.type": string;
        [PrimaryIdAttribute: string]: string;
    };
    FileAttributeName: string;
    FileName: string;
    getMetadata: () => {
        boundParameter: string;
        parameterTypes: {
            Target: IParameterType;
            FileAttributeName: IParameterType;
            FileName: IParameterType;
        };
        operationType: number;
        operationName: "InitializeFileBlocksUpload";
    }
}

interface IInitializeFileBlocksDownloadResponse {
    FileContinuationToken: string;
    FileName: string;
    FileSizeInBytes: number;
}

interface IInitializeFileBlocksUploadResponse {
    FileContinuationToken: string;
}

interface IDownloadBlockRequest {
    Offset: number;
    BlockLength: number;
    FileContinuationToken: string;
    getMetadata: () => {
        boundParameter: string;
        parameterTypes: {
            Offset: IParameterType;
            BlockLength: IParameterType;
            FileContinuationToken: IParameterType;
        };
        operationType: number;
        operationName: string;
    }
}

interface IUploadBlockRequest {
    BlockId: string;
    BlockData: string;
    FileContinuationToken: string;
    getMetadata: () => {
        boundParameter: string;
        parameterTypes: {
            BlockId: IParameterType;
            BlockData: IParameterType;
            FileContinuationToken: IParameterType;
        };
        operationType: number;
        operationName: "UploadBlock";
    }
}

export interface ICreateRequest {
    etn: string;
    payload: object;
    suppressDupeDetection?: boolean;
    getMetadata: () => {
        boundParameter: string;
        parameterTypes: {
        };
        operationType: number;
        operationName: "Create";
    }
}

export interface IUpdateRequest {
    etn: string;
    id: string;
    payload: object;
    suppressDupeDetection?: boolean;
    getMetadata: () => {
        boundParameter: string;
        parameterTypes: {
        };
        operationType: number;
        operationName: "Update";
    }
}

export interface IExecuteWorkflowRequest {
    EntityId: {
        guid: string    // record id you want to run the workflow for
    };
    entity: Xrm.LookupValue;
    getMetadata: () => {
        boundParameter: string;
        parameterTypes: {
        };
        operationType: number;
        operationName: "ExecuteWorkflow";
    }
}

export enum WebApiErrors {
    SOMETHING_WENT_WRONG = 0, // Custom and non official WebApiError code
    DUPLICATE_ERROR = 2147746611,
    RECORD_IS_UNAVAILABLE = 2147746327,
    // https://learn.microsoft.com/en-us/power-apps/developer/data-platform/reference/web-service-error-codes
    // https://dev.azure.com/thenetworg/INT0015/_wiki/wikis/INT0015.wiki/4078/Differences?anchor=duplicate-keys-detection
    DUPLICATE_RECORD_ENTITY_KEY = 2147879058,
    DUPLICATE_RECORD = 2147746359,
}

interface IExecuteRequest {
    getMetadata: () => {
        boundParameter: string;
        parameterTypes: {
        };
        operationType: number;
        operationName: string;
    }
}

interface IDownloadBlockResponse {
    Data: string;
}

interface IRetrieveAuditDetails {
    entity: {
        entityType: string
        id: string
    }
}

interface ICommitFileBlocksUploadResponse {
    FileId: string;
    FileSizeInBytes: number;
}

interface IDownloadFileValueResponse {
    FileName: string;
    FileSizeInBytes: number;
    Base64: string;
    ResponseContentLength: number;
}

export class WebApi implements ComponentFramework.WebApi {
    private static _registeredFetchXmlInterceptors: { [key: string]: IInterceptor } = {};
    private static _registeredODataInterceptors: { [key: string]: IInterceptor } = {};
    private _apiVersion = 'v9.2';
    public async execute(request: any): Promise<Response> {

        switch (request.getMetadata().operationName) {
            case "InitializeFileBlocksDownload":
                const initializeFileBlocksDownloadRequest = request as IInitializeFileBlocksDownloadRequest;
                const entityName = initializeFileBlocksDownloadRequest.Target['@odata.type'].replace("Microsoft.Dynamics.CRM.", "");
                const primaryKeyAttributeName = (await EntityDefinition.getAsync(entityName)).PrimaryIdAttribute;
                const entitySetName = (await EntityDefinition.getAsync(entityName)).EntitySetName;
                const fileAttributeValueUrl = `${SpaConfiguration.get().edsApi}/${this._apiVersion}/${entitySetName}(${initializeFileBlocksDownloadRequest.Target[primaryKeyAttributeName]})/${initializeFileBlocksDownloadRequest.FileAttributeName}/$value`;

                //Try downloading the file with a range of 0-0 in the headers.
                //If chunking is supported, the server will respond with an empty body.
                //If not (e.g., EDS), the server will send the complete file in the response which we include in the continuation token of InitializeFileBlocksDownload below
                //FileContinuationToken is used by the subsequent DownloadBlock request which returns the acquired file to the client code calling these requests.
                //This maintains Xrm.WebApi API consistency across different backend capabilities.
                const fileReponse = await this._downloadFileAsBase64(fileAttributeValueUrl, 0, 1);

                const initializeFileBlocksDownloadResponse: IInitializeFileBlocksDownloadResponse = {
                    FileContinuationToken: "",
                    FileName: decodeURIComponent(fileReponse.FileName),
                    FileSizeInBytes: fileReponse.FileSizeInBytes
                };

                //API does not support chunking - probably EDS => return the whole file in FileContinuationToken
                if (fileReponse.ResponseContentLength > 1) {
                    initializeFileBlocksDownloadResponse.FileContinuationToken = "data:application/octet-stream;base64," + fileReponse.Base64;
                }
                //API supports chunking - pass the URL in FileContinuationToken
                else if (fileReponse.ResponseContentLength == 1) {
                    initializeFileBlocksDownloadResponse.FileContinuationToken = fileAttributeValueUrl;
                }

                return {
                    json: async () => {
                        return initializeFileBlocksDownloadResponse;
                    },
                    ok: true,
                    headers: null,
                    redirected: null,
                    status: null,
                    statusText: null,
                    type: null,
                    url: null,
                    clone: null,
                    body: null,
                    bodyUsed: null,
                    arrayBuffer: null,
                    blob: null,
                    formData: null,
                    text: null
                };
            case "InitializeFileBlocksUpload":
                const initializeFileBlocksUploadRequest = request as IInitializeFileBlocksUploadRequest;
                const uploadEntityName = initializeFileBlocksUploadRequest.Target['@odata.type'].replace("Microsoft.Dynamics.CRM.", "");
                const uploadPrimaryKeyAttributeName = (await EntityDefinition.getAsync(uploadEntityName)).PrimaryIdAttribute;
                const uploadEntitySetName = (await EntityDefinition.getAsync(uploadEntityName)).EntitySetName;
                const uploadFileAttributeValueUrl = `${SpaConfiguration.get().edsApi}/${this._apiVersion}/${uploadEntitySetName}(${initializeFileBlocksUploadRequest.Target[uploadPrimaryKeyAttributeName]})/${initializeFileBlocksUploadRequest.FileAttributeName}`;

                const initializeFileBlocksUploadResponse: IInitializeFileBlocksUploadResponse = {
                    FileContinuationToken: ``
                };

                const initializeFileRequestInit: RequestInit = {
                    headers: {
                        'x-ms-transfer-mode': `chunked`,
                        'x-ms-file-name': `${initializeFileBlocksUploadRequest.FileName}`
                    },
                    method: 'PATCH'
                };

                //TODO: finish chunking
                //const initializeFileRequestResponse = await this.executeAuthenticatedRequest(`${uploadFileAttributeValueUrl}`, initializeFileRequestInit);
                //const initializeFileRequestResponseLocationHeader = initializeFileRequestResponse.headers.get('Location');
                //initializeFileBlocksUploadResponse.FileContinuationToken = `url:${encodeURIComponent(uploadFileAttributeValueUrl)},filename:${encodeURIComponent(initializeFileBlocksUploadRequest.FileName)},token:${initializeFileRequestResponseLocationHeader.substring(initializeFileRequestResponseLocationHeader.lastIndexOf('?sessiontoken=') + '?sessiontoken='.length)}`;
                initializeFileBlocksUploadResponse.FileContinuationToken = `url:${encodeURIComponent(uploadFileAttributeValueUrl)},filename:${encodeURIComponent(initializeFileBlocksUploadRequest.FileName)},token:123`;

                return {
                    json: async () => {
                        return initializeFileBlocksUploadResponse;
                    },
                    ok: true,
                    headers: null,
                    redirected: null,
                    status: null,
                    statusText: null,
                    type: null,
                    url: null,
                    clone: null,
                    body: null,
                    bodyUsed: null,
                    arrayBuffer: null,
                    blob: null,
                    formData: null,
                    text: null
                };
            case "DownloadBlock":

                const downloadBlockRequest = request as IDownloadBlockRequest;
                const downloadBlockResponse: IDownloadBlockResponse = { Data: "" };

                //FileContinuationToken property is used as a workaround to maintain API compatibility with Xrm.WebApi when working with EDS.
                //It allows passing the actual file contents between the InitializeFileBlocksDownload and DownloadBlock requests.
                //EDS doesn't implement chunked file download, so downloading the entire file here again would be inefficient.
                if (downloadBlockRequest.FileContinuationToken.startsWith("data:")) {
                    downloadBlockResponse.Data = downloadBlockRequest.FileContinuationToken.substring(downloadBlockRequest.FileContinuationToken.indexOf(",") + 1);
                }
                //CDS - Chunking in $value using Range header supported
                //FileContinuationToken is used to pass $value URL
                else if (downloadBlockRequest.FileContinuationToken.startsWith("https:") || downloadBlockRequest.FileContinuationToken.startsWith(SpaConfiguration.get().edsApi)) {
                    downloadBlockResponse.Data = (await this._downloadFileAsBase64(downloadBlockRequest.FileContinuationToken, downloadBlockRequest.Offset, downloadBlockRequest.BlockLength)).Base64;
                }
                else {
                    throw new Error("TALXIS Portal implements 'data:' and 'https:' proprietary FileContinuationTokens only or the token must start with the API path! Offending request: " + downloadBlockRequest.FileContinuationToken);
                }

                return {
                    json: async () => {
                        return downloadBlockResponse;
                    },
                    ok: true,
                    headers: null,
                    redirected: null,
                    status: null,
                    statusText: null,
                    type: null,
                    url: null,
                    clone: null,
                    body: null,
                    bodyUsed: null,
                    arrayBuffer: null,
                    blob: null,
                    formData: null,
                    text: null
                };
            case "UploadBlock":
                const uploadBlockRequest = request as IUploadBlockRequest;

                var uploadFileBinaryString = window.atob(uploadBlockRequest.BlockData);

                const uploadContentCharCodeArray: number[] = new Array(uploadFileBinaryString.length);
                for (let i = 0; i < uploadFileBinaryString.length; i++) {
                    uploadContentCharCodeArray[i] = uploadFileBinaryString.charCodeAt(i);
                }

                const uploadContentByteArray = new Uint8Array(uploadContentCharCodeArray);

                const startIndexOfUrl = uploadBlockRequest.FileContinuationToken.indexOf('url:') + 'url:'.length;
                const fileBlockUrl = decodeURIComponent(uploadBlockRequest.FileContinuationToken.substring(startIndexOfUrl, uploadBlockRequest.FileContinuationToken.indexOf(',', startIndexOfUrl)));
                const startIndexOfFileName = uploadBlockRequest.FileContinuationToken.indexOf('filename:') + 'filename:'.length;
                const fileName = decodeURIComponent(uploadBlockRequest.FileContinuationToken.substring(startIndexOfFileName, uploadBlockRequest.FileContinuationToken.indexOf(',', startIndexOfFileName)));
                const startIndexOfToken = uploadBlockRequest.FileContinuationToken.indexOf('token:') + 'token:'.length;
                const token = uploadBlockRequest.FileContinuationToken.substring(startIndexOfToken);

                const initializeUploadRequestInit: RequestInit = {
                    headers: {
                        //TODO: finish chunking
                        //'Content-Range': `bytes=${0}-${uploadContentByteArray.length}/${uploadContentByteArray.length}`,
                        'Content-Type': `application/octet-stream`,
                        'x-ms-file-name': encodeURIComponent(`${fileName}`)
                    },
                    method: 'PATCH',
                    body: uploadContentByteArray
                };
                //TODO: finish chunking
                //return this.executeAuthenticatedRequest(`${fileBlockUrl}?sessiontoken=${token}`, initializeUploadRequestInit);
                return this.executeAuthenticatedRequest(`${fileBlockUrl}`, initializeUploadRequestInit);
            case "CommitFileBlocksUpload":
                //TODO: finish chunking
                break;
            case "Associate":
                const associateRequest = request as IAssociateRequest;
                const url = `${SpaConfiguration.get().edsApi}/${this._apiVersion}/${(await EntityDefinition.getAsync(associateRequest.target.entityType)).EntitySetName}(${sanitizeGuid(associateRequest.target.id)})/${associateRequest.relationship}/$ref`;
                const responses: Response[] = [];
                for (const relatedEntity of associateRequest.relatedEntities) {
                    //@odata.id needs an an absolute URL
                    const edsApi = SpaConfiguration.get().edsApi.startsWith('/') ? `${window.location.protocol}//${window.location.host}${SpaConfiguration.get().edsApi}` : SpaConfiguration.get().edsApi;
                    const requestInit: RequestInit = {
                        method: 'POST',
                        body: JSON.stringify({
                            "@odata.id": `${edsApi}/${this._apiVersion}/${(await EntityDefinition.getAsync(relatedEntity.entityType)).EntitySetName}(${sanitizeGuid(relatedEntity.id)})`
                        })
                    };
                    responses.push(await this.executeAuthenticatedRequest(url, requestInit));
                }
                // TODO: We should create our own response indicating success or failure
                return responses[0];
            case "Disassociate":
                const disassociateRequest = request as IDisassociateRequest;
                const disassociateUrl = `${SpaConfiguration.get().edsApi}/${this._apiVersion}/${(await EntityDefinition.getAsync(disassociateRequest.target.entityType)).EntitySetName}(${disassociateRequest.target.id})/${disassociateRequest.relationship}(${disassociateRequest.relatedEntityId})/$ref`;
                const requestInit: RequestInit = {
                    method: 'DELETE'
                };
                return this.executeAuthenticatedRequest(disassociateUrl, requestInit);
            case "RetrieveCurrentOrganization":
                const retrieveCurrentOrganizationRequest = request as IRetrieveCurrentOrganizationRequest;
                const retrieveCurrentOrganizationUrl = `${SpaConfiguration.get().metadataApi}/${this._apiVersion}/RetrieveCurrentOrganization(AccessType=Microsoft.Dynamics.CRM.EndpointAccessType'${retrieveCurrentOrganizationRequest.getMetadata().parameterTypes.AccessType.enumProperties.find(x => x.value === retrieveCurrentOrganizationRequest.AccessType).name}')`;

                return sendMetadataGetRequest(retrieveCurrentOrganizationUrl);
            case "RetrieveAvailableLanguages":
                const retrieveAvailableLanguagesUrl = `${SpaConfiguration.get().metadataApi}/${this._apiVersion}/RetrieveAvailableLanguages`;

                return sendMetadataGetRequest(retrieveAvailableLanguagesUrl);
            case "RetrieveAuditDetails":
                const retrieveAuditDetailsRequest = request as IRetrieveAuditDetails;
                const retrieveAuditDetailsUrl = `${SpaConfiguration.get().edsApi}/${this._apiVersion}/audits(${retrieveAuditDetailsRequest.entity.id})/Microsoft.Dynamics.CRM.RetrieveAuditDetails()`;
                const retrieveAuditDetailsRequestInit: RequestInit = {
                    method: 'GET'
                };

                return this.executeAuthenticatedRequest(retrieveAuditDetailsUrl, retrieveAuditDetailsRequestInit);
            case "Create":
                const createRequest = request as ICreateRequest;
                const createEntityDefinition = await EntityDefinition.getAsync(createRequest.etn);
                const createUrl = `${SpaConfiguration.get().edsApi}/${this._apiVersion}/${createEntityDefinition.EntitySetName}`;
                const createResponse = await this.executeAuthenticatedRequest(createUrl, {
                    method: 'POST',
                    body: JSON.stringify(createRequest.payload),
                    headers: {
                        "MSCRM.SuppressDuplicateDetection": (createRequest.suppressDupeDetection ?? false) ? "true" : "false"
                    }
                });

                if (!createResponse.ok && createResponse.status === 412) {
                    const result: IWebApiError = await createResponse.json();
                    const code = parseInt(result.error.code);
                    throw new XrmWebApiException(code, code, result.error.message, result.error.message);
                }

                return createResponse;
            case "Update":
                const updateRequest = request as IUpdateRequest;
                const updateEntityDefinition = await EntityDefinition.getAsync(updateRequest.etn);
                const updateUrl = `${SpaConfiguration.get().edsApi}/${this._apiVersion}/${updateEntityDefinition.EntitySetName}(${updateRequest.id})`;
                const updateResponse = await this.executeAuthenticatedRequest(updateUrl, {
                    method: 'PATCH',
                    body: JSON.stringify(updateRequest.payload),
                    headers: {
                        "MSCRM.SuppressDuplicateDetection": (updateRequest.suppressDupeDetection ?? false) ? "true" : "false"
                    }
                });

                if (!updateResponse.ok && updateResponse.status === 412) {
                    const result: IWebApiError = await updateResponse.json();
                    const code = parseInt(result.error.code);
                    throw new XrmWebApiException(code, code, result.error.message, result.error.message);
                }

                return updateResponse;
            case "ExecuteWorkflow":
                const executeWorkflowRequest = request as IExecuteWorkflowRequest;
                const executeWorkflowUrl = `${SpaConfiguration.get().edsApi}/${this._apiVersion}/workflows(${executeWorkflowRequest.entity.id})/Microsoft.Dynamics.CRM.ExecuteWorkflow`;
                const executeWorkflowResponse = await this.executeAuthenticatedRequest(executeWorkflowUrl, {
                    method: 'POST',
                    body: JSON.stringify({
                        EntityId: executeWorkflowRequest.EntityId.guid,
                        entity: {
                            "@odata.type": "Microsoft.Dynamics.CRM.workflow",
                            workflowid: executeWorkflowRequest.entity.id
                        }
                    }),
                });

                if (!executeWorkflowResponse.ok) {
                    const result: IWebApiError = await updateResponse.json();
                    const code = parseInt(result.error.code);
                    throw new XrmWebApiException(code, code, result.error.message, result.error.message);
                }

                return executeWorkflowResponse;
            default:
                let requestMetadata = request.getMetadata();
                let actionUrl = `${SpaConfiguration.get().edsApi}/${this._apiVersion}/`;

                let entityMetadata = await EntityDefinition.getAsync(request.entity.entityType);

                let actionRequestInit: RequestInit;

                if (requestMetadata.boundParameter === "entity") {
                    actionUrl += `${entityMetadata.EntitySetName}(${request.entity.id})/Microsoft.Dynamics.CRM.${requestMetadata.operationName}`;

                    request["entity"] = {
                        ["@odata.type"]: "Microsoft.Dynamics.CRM." + entityMetadata.LogicalName,
                        [entityMetadata.PrimaryIdAttribute]: request.entity.id
                    };

                } else {
                    actionUrl += `${requestMetadata.operationName}`;
                }

                delete request.getMetadata;

                actionRequestInit = {
                    method: "POST",
                    body: JSON.stringify(request)
                };

                return this.executeAuthenticatedRequest(actionUrl, actionRequestInit);

                throw new Error("Operation not implemented in the portal!");
        }
    }

    private async _downloadFileAsBase64(url: string, offset: number = 0, length: number = 0): Promise<IDownloadFileValueResponse> {
        const requestInit: RequestInit = {
            headers: {
                ...(length > 0 && { 'Range': `bytes=${offset}-${offset + length - 1}` })
            },
            method: 'GET'
        };
        const apiResponse = await this.executeAuthenticatedRequest(url, requestInit);
        let binary = '';
        let bytes = new Uint8Array(await apiResponse.arrayBuffer());
        let len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        let base64File = window.btoa(binary);

        console.groupCollapsed("File Download Response Headers");
        for (let header of apiResponse.headers.entries()) {
            console.log(header);
        }
        console.groupEnd();

        return {
            Base64: base64File,
            FileName: apiResponse.headers.get("x-ms-file-name")?.replaceAll('\"', '').replaceAll('%22', ''),
            FileSizeInBytes: Number.parseInt(apiResponse.headers.get("x-ms-file-size")),
            ResponseContentLength: Number.parseInt(apiResponse.headers.get("content-length"))
        };
    }

    public async executeAuthenticatedRequest(input: RequestInfo, init?: RequestInit): Promise<Response> {
        return executeAuthenticatedRequest(input, init);
    }

    public async createRecord(entityType: string, data: ComponentFramework.WebApi.Entity): Promise<ComponentFramework.LookupValue> {
        const entitySetName = (await EntityDefinition.getAsync(entityType)).EntitySetName;
        const response = await fetch(`${SpaConfiguration.get().edsApi}/${this._apiVersion}/${entitySetName}`, {
            method: 'POST',
            headers: {
                ...MultitenantProvider.getFetchHeader().headers,
                ...(await Authentication.getAuthorizationHeader()).headers,
                ...(!Authentication.isAuthenticated() && (await (BotProtection.getBotProtectionHeader())).headers),
                ...(this._getReturnPermissionHeader().headers),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            const body = await response.text();
            throw new PortalWebApiError(`Data of entity ${entityType} could not be created!`, body);
        }

        const entityId = response.headers.get("OData-EntityId").replace(/(^.*\(|\).*$)/g, '');
        const reference: ComponentFramework.LookupValue = {
            id: entityId,
            entityType: entityType
        };
        return reference;
    }
    public async deleteRecord(entityType: string, id: string): Promise<ComponentFramework.LookupValue> {
        const entitySetName = (await EntityDefinition.getAsync(entityType)).EntitySetName;
        const response = await fetch(`${SpaConfiguration.get().edsApi}/${this._apiVersion}/${entitySetName}(${sanitizeGuid(id)})`, {
            method: 'DELETE',
            headers: {
                ...MultitenantProvider.getFetchHeader().headers,
                ...(await Authentication.getAuthorizationHeader()).headers,
                ...(this._getReturnPermissionHeader().headers),
                ...(!Authentication.isAuthenticated() && (await (BotProtection.getBotProtectionHeader())).headers)
            }
        });

        if (!response.ok) {
            const body = await response.text();
            throw new PortalWebApiError(`Data of entity ${entityType} could not be deleted!`, body);
        }

        const reference: ComponentFramework.LookupValue = {
            id: id,
            entityType: entityType
        };
        return reference;
    }
    public async updateRecord(entityType: string, id: string, data: ComponentFramework.WebApi.Entity): Promise<ComponentFramework.LookupValue> {
        const entitySetName = (await EntityDefinition.getAsync(entityType)).EntitySetName;
        const response = await fetch(`${SpaConfiguration.get().edsApi}/${this._apiVersion}/${entitySetName}(${sanitizeGuid(id)})`, {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                ...MultitenantProvider.getFetchHeader().headers,
                ...(await Authentication.getAuthorizationHeader()).headers,
                ...(this._getReturnPermissionHeader().headers),
                ...(!Authentication.isAuthenticated() && (await (BotProtection.getBotProtectionHeader())).headers),
                "Content-Type": "application/json",
                // Automatically handle null values on PATCH request (this is undocumented, but MS does it from Power Apps)
                "autodisassociate": "true"
            }
        });
        if (!response.ok) {
            const error = await response.json();
            throw new Error(JSON.stringify(error));
        }
        const reference: ComponentFramework.LookupValue = {
            id: id,
            entityType: entityType
        };
        return reference;
    }
    public async retrieveMultipleRecords(entityType: string, options?: string, maxPageSize?: number): Promise<IRetrieveMultipleResponse> {
        const api = this._getApiUrl(entityType);
        const entitySetName = await this._getEntitySetNameAsync(entityType);

        const hasFetchXml = options?.toLowerCase().includes("fetchxml=");

        if (hasFetchXml) {
            const qs = QueryString.parse(options, {
                parseBooleans: true,
                parseNumbers: true
            });
            let fetchXml = qs["fetchXml"].toString();

            if (Object.keys(WebApi._registeredFetchXmlInterceptors).length > 0) {
                const orderedInterceptors = Object.values(WebApi._registeredFetchXmlInterceptors).sort((a, b) => a.priority - b.priority);

                for (const interceptor of orderedInterceptors) {
                    try {
                        fetchXml = await interceptor.action(fetchXml);
                    } catch (error) {
                        const foundInterceptor = Object.entries(WebApi._registeredFetchXmlInterceptors).find(([key, value]) => value === interceptor);
                        console.error(`FetchXml interceptor ${foundInterceptor[0]} failed!`, error);
                    }
                }

                // https://dev.azure.com/thenetworg/INT0015/_wiki/wikis/INT0015.wiki/4078/Differences?anchor=eq-userid-equivalent
                fetchXml = fetchXml.replace("{userId}", Authentication.getUser()?.accessPrincipalId);

                qs["fetchXml"] = fetchXml;
            }

            options = QueryString.stringifyUrl({
                url: "",
                query: qs
            }, {
                skipNull: true
            });
        } else {
            if (Object.keys(WebApi._registeredODataInterceptors).length > 0) {
                const orderedInterceptors = Object.values(WebApi._registeredODataInterceptors).sort((a, b) => a.priority - b.priority);

                for (const interceptor of orderedInterceptors) {
                    try {
                        options = await interceptor.action(options);
                    } catch (error) {
                        const foundInterceptor = Object.entries(WebApi._registeredODataInterceptors).find(([key, value]) => value === interceptor);
                        console.error(`OData interceptor ${foundInterceptor[0]} failed!`, error);
                    }
                }
            }
        }

        if (maxPageSize == null) {
            maxPageSize = 5000;
        }
        const response = await fetch(`${api}/${this._apiVersion}/${entitySetName}${options ?? ""}`, {
            headers: {
                ...MultitenantProvider.getFetchHeader().headers,
                ...(await Authentication.getAuthorizationHeader()).headers,
                ...(this._getReturnPermissionHeader().headers),
                ...(!Authentication.isAuthenticated() && (await (BotProtection.getBotProtectionHeader())).headers),
                ...(!hasFetchXml && { 'Prefer': `odata.maxpagesize=${maxPageSize}` })
            }
        });
        if (!response.ok) {
            const body = await response.text();
            throw new PortalWebApiError(`Data of entity ${entityType} could not be fetched!`, body);
        }

        const responseJson = await response.json();
        let nextLink = responseJson["@odata.nextLink"] ?? null;
        const fetchXmlPagingCookie = responseJson["@Microsoft.Dynamics.CRM.fetchxmlpagingcookie"] ?? null;
        // This is only for back-compatibility and will be eventually removed
        if (!nextLink && fetchXmlPagingCookie) {
            const qs = QueryString.parse(options, {
                parseBooleans: true,
                parseNumbers: true
            });
            let pageNumber = parseInt(DomParser.parseFromString(qs["fetchXml"].toString(), "text/xml").getElementsByTagName("fetch")[0].getAttribute("page"));
            if (isNaN(pageNumber)) pageNumber = 1;

            let fetchXml = updateFetchXmlWithPaging(qs["fetchXml"].toString(), fetchXmlPagingCookie, pageNumber + 1, maxPageSize);
            qs["fetchXml"] = fetchXml;
            options = QueryString.stringifyUrl({
                url: "",
                query: qs
            }, {
                skipNull: true
            });
            nextLink = `${api}/${this._apiVersion}/${entitySetName}${options ?? ""}`;
        }

        let totalRecordCount = responseJson["@Microsoft.Dynamics.CRM.totalrecordcount"];
        if (responseJson["@Microsoft.Dynamics.CRM.totalrecordcountlimitexceeded"] == true) {
            totalRecordCount = -1;
        }

        const multipleResponse: IRetrieveMultipleResponse = {
            entities: responseJson.value,
            nextLink: nextLink,
            fetchXmlPagingCookie: fetchXmlPagingCookie,
            _totalRecordCount: totalRecordCount
        };

        return multipleResponse;
    }
    public async retrieveRecord(entityType: string, id: string, options?: string): Promise<ComponentFramework.WebApi.Entity> {
        const api = this._getApiUrl(entityType);
        const entitySetName = await this._getEntitySetNameAsync(entityType);

        const response = await fetch(`${api}/${this._apiVersion}/${entitySetName}(${sanitizeGuid(id)})${options ? options : ''}`, {
            headers: {
                ...MultitenantProvider.getFetchHeader().headers,
                ...(await Authentication.getAuthorizationHeader()).headers,
                ...(this._getReturnPermissionHeader().headers),
                ...(!Authentication.isAuthenticated() && (await (BotProtection.getBotProtectionHeader())).headers)
            }
        });

        if (!response.ok) {
            if (response.status === 404) {
                throw new XrmWebApiException(
                    WebApiErrors.RECORD_IS_UNAVAILABLE, // code
                    WebApiErrors.RECORD_IS_UNAVAILABLE, // errorCode
                    window.TALXIS.Portal.Translations.getLocalizedString(`components/forms/WebApiError/Code/${WebApiErrors.RECORD_IS_UNAVAILABLE}`), // message
                    window.TALXIS.Portal.Translations.getLocalizedString(`components/forms/WebApiError/RecordIsUnavailable`) // title
                );
            }
            if (response.status === 400) {
                throw new XrmWebApiException(
                    WebApiErrors.SOMETHING_WENT_WRONG, // code
                    WebApiErrors.SOMETHING_WENT_WRONG, // errorCode
                    new Liquid().parseAndRenderSync(window.TALXIS.Portal.Translations.getLocalizedString(`components/forms/WebApiError/Code/${WebApiErrors.SOMETHING_WENT_WRONG}`), { entityName: entityType, id: id }), // message
                    window.TALXIS.Portal.Translations.getLocalizedString(`components/forms/WebApiError/SomethingWentWrong`) // title
                );
            }
            const body = await response.text();
            throw new PortalWebApiError(`Data of entity ${entityType} could not be fetched!`, body);
        }
        const entity: ComponentFramework.WebApi.Entity = await response.json();

        return entity;
    }
    public static registerFetchXmlInterceptor(name: string, interceptor: IInterceptor): void {
        WebApi._registeredFetchXmlInterceptors[name] = interceptor;
    }

    public static registerODataInterceptor(name: string, interceptor: IInterceptor): void {
        WebApi._registeredODataInterceptors[name] = interceptor;
    }

    private static _metadataEntities: { [entityName: string]: string } = {
        // 'environmentvariabledefinition': 'environmentvariabledefinitions'
    };

    private _getApiUrl(entityName: string): string {
        if (WebApi._metadataEntities[entityName]) {
            console.warn("WARNING: Call to metadata API via WebApi!", entityName);
            return SpaConfiguration.get().metadataApi;
        }
        else {
            return SpaConfiguration.get().edsApi;
        }
    }
    private async _getEntitySetNameAsync(entityName: string): Promise<string> {
        if (WebApi._metadataEntities[entityName]) {
            return WebApi._metadataEntities[entityName];
        }
        else {
            return (await EntityDefinition.getAsync(entityName)).EntitySetName;
        }
    }
    private _getReturnPermissionHeader(): RequestInit {
        if (localStorage.getItem("RETURN_PERMISSION_HEADER_IN_RESPONSE") === "true") {
            return {
                headers: {
                    "RETURN_PERMISSION_HEADER_IN_RESPONSE": "true"
                }
            };
        }

        return {};
    }
}

export const executeAuthenticatedRequest = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    const authorizationHeader = await Authentication.getAuthorizationHeader();

    if (typeof input === "string") {
        input = input
            .replace("{edsHost}", SpaConfiguration.get().edsApi)
            .replace("{metadataHost}", SpaConfiguration.get().metadataApi);
    }
    return fetch(input, {
        ...init, headers: {
            ...MultitenantProvider.getFetchHeader().headers,
            ...{ "Content-Type": "application/json" },
            ...init?.headers,
            ...authorizationHeader.headers,
            ...(!Authentication.isAuthenticated() && (await (BotProtection.getBotProtectionHeader())).headers),
        }
    });

};

class XrmWebApiException {
    constructor(code: number, errorCode: number, message: string, title: string, raw?: string) {
        this.code = code;
        this.errorCode = errorCode;
        this.message = message;
        this.title = title;
        this.raw = raw;
    }
    code: number;
    errorCode: number;
    message: string;
    raw?: string;
    title: string
}
interface IWebApiError {
    error: {
        code: string;
        message: string;
    }
}
export class PortalWebApiError extends Error {
    details: string;
    constructor(message: string, details: string) {
        super(message);
        this.name = "PortalWebApiError";
        this.details = details;
    }
}