import React, { useEffect, useRef } from 'react';
import { mergeStyles, IconButton, Nav, INavLinkGroup, INavLink, Text, ThemeProvider, IRenderGroupHeaderProps, CommandButton } from '@fluentui/react';
import styles from './VerticalNavigation.module.css';
import { SitemapDefinition } from '@definitions/SitemapDefinition';
import { useContext } from 'react';
import { AppContext } from '@providers/AppProvider';
import { useState } from 'react';
import { ThemeDefinition } from '@definitions/ThemeDefinition';
import { DefaultIcon } from '@loaders/IconLoader';
import TabCommandBar from '@src/components/navigation/commandbars/TabCommandBar';
import { ICommandBarItemProps } from '@talxis/react-components/dist/components/CommandBar/CommandBar.types';
import { InstallPrompt } from './InstallPrompt/InstallPrompt';
import { AppModule } from '@src/app/classes/configuration/AppModule';
import { ManifestDefinition } from '@src/app/classes/definitions/ManifestDefinition';
import { HistoryContext, history } from '@src/providers/HistoryProvider/HistoryProvider';

interface IVerticalPageNavigationProps {
    expanded: boolean;
    areasAsTabs: boolean;
    visible?: boolean;
    onToggleExpandVerticalNavigation(expand: boolean): void;
    onToggleVerticalNavigationVisibility(visible: boolean): void;
}

export const VerticalPageNavigation: React.FC<IVerticalPageNavigationProps> = (props) => {
    const theme = ThemeDefinition.getTheme().getNavigationTheme();
    const ref = useRef<HTMLDivElement>(null);
    const appContext = useContext(AppContext);
    const historyContext = useContext(HistoryContext);
    const [groups, setGroups] = useState<INavLinkGroup[]>(() => SitemapDefinition.getCurrentSiteMap().getVerticalNavigationItems());
    const [currentGroup, setCurrentGroup] = useState<INavLinkGroup>();
    const [overflownGroupNames, setOverflownGroupNames] = useState<string[]>([]);

    const setAreaWithFirstVisibleSubArea = (groups: INavLinkGroup[]) => {
        for (const area of groups)
            if (area.groupData?.visible && !area.groupData?.disabled)
                for (const group of area.links)
                    //TODO: Add group visibility to condition
                    for (const subArea of group.links)
                        if (subArea.visible && !subArea.disabled) {
                            history.push(subArea.url);
                            return;
                        }
    };

    useEffect(() => {
        const _groups = SitemapDefinition.getCurrentSiteMap().getVerticalNavigationItems();
        setGroups(_groups);
        const _currentGroup = _groups.find(x => x.groupData?.id === currentGroup?.groupData?.id);
        // If current area is not visible, navigate to first visible area
        if (_currentGroup?.groupData?.visible === false)
            setAreaWithFirstVisibleSubArea(_groups);
    }, [appContext.refreshSiteMap]);

    useEffect(() => {
        if (!props.areasAsTabs) {
            return;
        }
        for (const area of groups) {
            for (const group of area.links) {
                for (const subArea of group.links) {
                    if (subArea.key === historyContext.currentPage.sitemapKey) {
                        setCurrentGroup(area);
                        return;
                    }
                }
            }
        }
    }, [historyContext.currentPage]);

    const getVerticalNavigationClassName = () => {
        let className = 'TALXIS__navigation--vertical';
        return `${className} ${styles.root} ${mergeStyles({
            backgroundColor: theme.semanticColors.bodyBackground,
            '.ms-Nav-groupContent::after': {
                borderBottom: `1px solid ${theme.semanticColors.menuDivider}`
            },
            '.ms-Nav-groupContent:first-child::before': {
                borderTop: `1px solid ${theme.semanticColors.menuDivider}`
            },
            '[class*="VerticalNavigation_areaHeader"]': {
                borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`
            },
            '[class*="VerticalNavigation_areaPicker"]': {
                borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`
            }
        })}`;
    };

    const renderLink = (linkProps: INavLink) => {
        return (
            <div title={getTitle(linkProps)} onClick={() => props.onToggleVerticalNavigationVisibility(false)} className={styles.link}>
                {!linkProps.isExpanded &&
                    <svg
                        className={mergeStyles({
                            width: 18,
                            height: 18,
                            'path': {
                                fill: theme.palette.themePrimary
                            }
                        })}
                        data-src={linkProps.dataIcon?.value ?? DefaultIcon}
                    />
                }
                <Text>{linkProps.name}</Text>
            </div >);
    };

    const prepareItemsForTabView = (): ICommandBarItemProps[] => {

        const selectedItem = groups.find(group => group.name === currentGroup?.name);

        if (selectedItem && overflownGroupNames.includes(selectedItem.name)) {

            // In case selected area was hidden before, push it to the top
            const shuffledGroups = [
                selectedItem,
                ...groups.filter(group => group.name !== currentGroup?.name)
            ];

            setOverflownGroupNames([]);
            setGroups(shuffledGroups);

        }
        return groups.filter(group => group.groupData?.visible === true).map(group => { return { key: group.name, title: group.name, text: group.name, onClick: () => selectGroup(group.name), disabled: group.groupData?.disabled }; });
    };

    const saveOverflownGroup = (data: ICommandBarItemProps): void => {
        if (data.renderedInOverflow && !overflownGroupNames.includes(data.title)) {
            setOverflownGroupNames([...overflownGroupNames, data.title]);
        }
    };

    const renderTabHeader = () => {
        return (<div className={styles.areaPicker}>
            <TabCommandBar
                items={
                    prepareItemsForTabView()
                }
                onDataReduced={saveOverflownGroup}
                selectedKey={currentGroup?.name}
            />
        </div>);
    };

    const renderAreaHeader = (headerProps: IRenderGroupHeaderProps) => {
        return !props.areasAsTabs ? <div className={styles.areaHeader}>
            <Text variant='xLarge'>{headerProps.name}</Text>
        </div> : <></>;
    };

    const selectGroup = (itemKey: string) => {
        setCurrentGroup(groups.find(group => group.name === itemKey));
    };

    const getTitle = (link?: INavLink) => {
        if (!link) return;

        if (!props.expanded && link.parentGroupTitle)
            return `${link.parentGroupTitle} - ${link.title}`;

        return link.title;
    };

    return (
        <ThemeProvider style={props.visible !== undefined ? {
            //@ts-ignore - CSS variables
            '--display': props.visible ? 'flex' : 'none',
        } : undefined} ref={ref} data-expanded={props.expanded} className={getVerticalNavigationClassName()} theme={theme}>
            {props.areasAsTabs &&
                renderTabHeader()
            }
            <Nav
                selectedKey={historyContext.currentPage.sitemapKey}
                groups={currentGroup ? [currentGroup] : groups}
                styles={{
                    link: {
                        height: 38
                    }
                }}
                onRenderLink={(groups) => (renderLink(groups))}
                onRenderGroupHeader={renderAreaHeader}
            />
            <div className={styles.footerWrapper}>
                {props.expanded &&
                    <InstallPrompt
                        imageSrc={AppModule.get().icon.value}
                        onUserSettingsSelected={(selectedSubAreas) => ManifestDefinition.getManifest().injectSubAreaShortcuts(selectedSubAreas)} />
                }
                <IconButton
                    iconProps={{
                        iconName: props.expanded ? 'ClosePaneMirrored' : 'OpenPaneMirrored',
                    }}
                    className={styles.expandCollapseButton}
                    styles={
                        {
                            icon: {
                                fontSize: 20
                            }
                        }
                    }
                    onClick={() => { props.onToggleExpandVerticalNavigation(!props.expanded); }}
                />
            </div>
        </ThemeProvider>
    );
};