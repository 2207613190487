import humanizeDuration, { Unit } from "humanize-duration";
import numeral from "numeral";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { UserSettings } from "@src/app/classes/models/UserSettings";
import lcid from 'lcid';
dayjs.extend(utc);
dayjs.extend(weekOfYear);

export const CURRENCY_POSITIVE_PATTERN: { [key: number]: string } = {
    0: '$n',
    1: 'n$',
    2: '$ n',
    3: 'n $',
};
export const CURRENCY_NEGATIVE_PATTERN: { [key: number]: string } = {
    0: '($n)',
    1: '-$n',
    2: '$-n',
    3: '$n-',
    4: '(n$)',
    5: '-n$',
    6: 'n-$',
    7: 'n$-',
    8: '-n $',
    9: '-$ n',
    10: 'n $-',
    11: '$ n-',
    12: '$ -n',
    13: 'n- $',
    14: '($ n)',
    15: '(n $)',
    16: '$- n'
};
export const NUMBER_NEGATIVE_PATTERN: { [key: number]: string } = {
    0: '(n)',
    1: '-n',
    2: '- n',
    3: 'n-',
    4: 'n -'
};
export const PERCENT_POSITIVE_PATTERN: { [key: number]: string } = {
    0: 'n %',
    1: 'n%',
    2: '%n',
    3: '% n'
};
export const PERCENT_NEGATIVE_PATTERN: { [key: number]: string } = {
    0: '-n %',
    1: '-n%',
    2: '-%n',
    3: '%-n',
    4: '%n-',
    5: 'n-%',
    6: 'n%-',
    7: '-% n',
    8: 'n %-',
    9: '% n-',
    10: '% -n',
    11: 'n- %'
};

export class Formatting implements ComponentFramework.Formatting {
    private _userSettings: UserSettings

    constructor(userSettings: UserSettings) {
        this._userSettings = userSettings;
    }
    formatCurrency(value: number, precision?: number, symbol?: string): string {
        const precisionFormat = Array.from(Array(precision ?? this._numberFormattingInfo.numberDecimalDigits).keys()).map(x => "0").join('');
        const pattern = value >= 0 ? this._currencyPositivePattern : this._currencyNegativePattern;
        symbol = symbol ?? this._numberFormattingInfo.currencySymbol;
        const formatString = pattern.replace('n', `n,n.${precisionFormat}`);
        Numeral.currency({
            ...this._userSettings.numberFormattingInfo,
            currencySymbol: symbol ?? this._numberFormattingInfo.currencySymbol
        });
        return numeral(value).format(formatString);
    }
    //portal only!
    formatDuration(value: number): string {
        const durationInMilliseconds = value * 60000;
        const units: Unit[] = value < 60 ? ['m'] : value >= 1440 ? ['d'] : ['h'];
        const options = {
            units: units,
            maxDecimalPoints: 2,
            //@ts-ignore - update typings
            language: this._userSettings.locale.split('-')[0],
            decimal: this._userSettings.numberFormattingInfo.numberDecimalSeparator,
            fallbacks: ["en"]
        };
        return humanizeDuration(durationInMilliseconds, options);
    }
    formatInteger(value: number): string {
        const pattern = value < 0 ? this._negativeNumberPattern : null;
        Numeral.decimal(this._userSettings.numberFormattingInfo);
        return numeral(value).format(pattern);
    }
    formatDecimal(value: number, precision?: number): string {
        const precisionFormat = Array.from(Array(precision ?? this._numberFormattingInfo.numberDecimalDigits).keys()).map(x => "n").join('');
        const pattern = value >= 0 ? this._negativeNumberPattern : null;
        let formatString = `n,n.${precisionFormat}`;
        if (pattern) {
            formatString = pattern.replace('n', formatString);
        }
        Numeral.decimal(this._numberFormattingInfo);
        return numeral(value).format(formatString);
    }
    formatDateAsFilterStringInUTC(value: Date, includeTime?: boolean): string {
        const dateString = dayjs(value).format('YYYY-MM-DD');
        if (!includeTime) {
            return dateString;
        }
        //This is how Power Apps does it
        const timeString = dayjs(value).format('H:mm:ss');
        return `${dateString}T${timeString}`;
    }
    formatDateLong(value: Date): string {
        return dayjs(value).format(this._dateFormattingInfo.longDatePattern);
    }
    formatDateLongAbbreviated(value: Date): string {
        return dayjs(value).format(this._dateFormattingInfo.longDateAbbreviatedPattern);
    }
    formatDateShort(value: Date, includeTime?: boolean): string {
        if (includeTime) {
            return this.formatTime(value, 0);
        }
        return dayjs(value).format(this._dateFormattingInfo.shortDatePattern);
    }
    formatDateYearMonth(value: Date): string {
        return dayjs(value).format(this._dateFormattingInfo.yearMonthPattern);
    }
    formatLanguage(value: number): string {
        const isoCode = lcid.from(value).split('_')[0];
        const displayNames = new Intl.DisplayNames(this._userSettings.locale, { type: 'language' });
        return displayNames.of(isoCode);
    }
    //dates in JavaScript are always in local time
    formatTime(value: Date, behavior: ComponentFramework.FormattingApi.Types.DateTimeFieldBehavior): string {
        if (behavior === 3) {
            return dayjs(value).utc().format(this._shortDateTimePattern);
        }
        return dayjs(value).format(this._shortDateTimePattern);

    }
    getWeekOfYear(value: Date): number {
        return dayjs(value).week();
    }

    private get _numberFormattingInfo() {
        return this._userSettings.numberFormattingInfo;
    }
    private get _dateFormattingInfo() {
        return this._userSettings.dateFormattingInfo;
    }
    private get _negativeNumberPattern() {
        return NUMBER_NEGATIVE_PATTERN[this._numberFormattingInfo.numberNegativePattern];
    }
    private get _currencyNegativePattern() {
        return CURRENCY_NEGATIVE_PATTERN[this._numberFormattingInfo.currencyNegativePattern];
    }
    private get _currencyPositivePattern() {
        return CURRENCY_POSITIVE_PATTERN[this._numberFormattingInfo.currencyPositivePattern];
    }
    //this is what MS does for formatTime function
    private get _shortDateTimePattern() {
        return `${this._dateFormattingInfo.shortDatePattern} ${this._dateFormattingInfo.shortTimePattern}`;
    }
}

export class Numeral {
    public static decimal(formatting: ComponentFramework.UserSettingApi.NumberFormattingInfo) {
        const locale = {
            ordinal: (num: number) => {
                return '.';
            },
            currency: {
                symbol: formatting.currencySymbol
            },
            abbreviations: {
                thousand: 'k',
                million: 'm',
                billion: 'b',
                trillion: 't'
            },
            delimiters: {
                decimal: formatting.numberDecimalSeparator,
                thousands: formatting.numberGroupSeparator,
            },

        };
        if (!this._locales.includes('__pcfcustomdecimal')) {
            numeral.register('locale', '__pcfcustomdecimal', locale);
        }
        else {
            numeral.locales['__pcfcustomdecima'] = locale;
        }
        numeral.locale('__pcfcustomdecimal');
    }

    public static currency(formatting: ComponentFramework.UserSettingApi.NumberFormattingInfo) {
        const locale = {
            ordinal: (num: number) => {
                return '.';
            },
            currency: {
                symbol: formatting.currencySymbol
            },
            abbreviations: {
                thousand: 'k',
                million: 'm',
                billion: 'b',
                trillion: 't'
            },
            delimiters: {
                decimal: formatting.currencyDecimalSeparator,
                thousands: formatting.currencyGroupSeparator,
            }
        };
        if (!this._locales.includes('__pcfcustomcurrency')) {
            numeral.register('locale', '__pcfcustomcurrency', locale);
        }
        else {
            numeral.locales['__pcfcustomcurrency'] = locale;
        }
        numeral.locale('__pcfcustomcurrency');
    }
    private static get _locales() {
        return Object.keys(numeral.locales);
    }
}