import React from 'react';
import styles from './Taskbar.module.css';
import { Icon } from '@fluentui/react/lib/components/Icon/Icon';

export const Taskbar: React.FC = () => {
  return (
    <div className={styles.taskbar}>
      <div className={styles.taskbarIconWrapper} data-move-left>
        <svg xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 0 443.704 443.704" version="1.1">
          <g>
            <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(0%,47.099304%,83.099365%)', fillOpacity: 1 }} d="M 227.570313 307.621094 L 227.570313 387.675781 L 387.675781 387.675781 L 387.675781 227.570313 L 227.570313 227.570313 Z M 56.03125 307.621094 L 56.03125 387.675781 L 216.132813 387.675781 L 216.132813 227.570313 L 56.03125 227.570313 Z M 227.570313 136.082031 L 227.570313 216.132813 L 387.675781 216.132813 L 387.675781 56.03125 L 227.570313 56.03125 Z M 56.03125 136.082031 L 56.03125 216.132813 L 216.132813 216.132813 L 216.132813 56.03125 L 56.03125 56.03125 Z M 56.03125 136.082031 " />
          </g>
        </svg>
      </div>
      <div className={styles.taskbarIconWrapper} data-move-left>
        <Icon iconName='Settings' style={{ fontSize: '1.8rem', position: 'relative', top: 3 }} />
      </div>
      <div className={styles.taskbarIconWrapper} data-move-left>
        <img src="/images/ms365/word.svg" />
      </div>
      <div className={styles.taskbarIconWrapper} data-move-left>
        <img src="/images/ms365/powerpoint.svg" />
      </div>
      <div className={styles.taskbarIconWrapper} data-move-left>
        <img src="/images/ms365/excel.svg" />
      </div>
      <div className={styles.taskbarIconWrapper} data-move-right>
        <img src="/images/ms365/office.svg" />
      </div>
      <div className={styles.taskbarIconWrapper} data-move-right>
        <img src="/images/ms365/outlook.svg" />
      </div>
      <div className={styles.taskbarIconWrapper} data-move-right>
        <img src="/images/ms365/teams.svg" />
      </div>
    </div>
  );
};