import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";
import { TextField } from "@talxis/react-components/dist/components/TextField";
import React, { useState } from 'react';
import { IShortcut } from "../InstallationDialog";

interface IEditShortcutNameDialogProps {
    shortcut: IShortcut
    onSave: (shortcut: IShortcut) => void;
    onDismiss: () => void;
}

export const EditShortcutNameDialog: React.FC<IEditShortcutNameDialogProps> = (props) => {
    const [value, setValue] = useState<string>(props.shortcut.text);
    const getTranslation = (key: string) => window.TALXIS.Portal.Translations.getLocalizedString(key);
    const onSaveHandler = () => {
        const updatedShorcut = {
            ...props.shortcut,
            text: value
        };
        props.onSave(updatedShorcut);
    };
    return <Dialog dialogContentProps={{
        showCloseButton: true,
        title: getTranslation('@pages/Layout/components/PageNavigation/VerticalPageNavigation/InstallPrompt/InstallationDialog/EditShortcutTitle')
    }}
        onDismiss={props.onDismiss}
        hidden={false}>
        <TextField value={value} onChange={(e) => setValue(e.currentTarget.value)} />
        <DialogFooter>
            <PrimaryButton onClick={onSaveHandler}>{getTranslation('components/dialogs/ErrorDialog/Ok')}</PrimaryButton>
            <DefaultButton onClick={props.onDismiss}>{getTranslation('components/dialogs/ErrorDialog/Close')}</DefaultButton>
        </DialogFooter>
    </Dialog>;
};