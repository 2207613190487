import { EntityDefinition as IEntityDefinition, Attribute, IRelationship, FormatType, DateTimeBehaviorType, EntityDescriptor, IManyToManyRelationship, RelationshipType } from "@app/interfaces/entitydefinition";
import { LocalizeLabel } from "@localization/helpers";
import { IOptionSetDefinition } from "@app/interfaces/optionset";
import { IODataResponse } from "@app/interfaces/general";
import { AppComponents } from "@src/app/classes/configuration/AppComponents";
import { Option } from "@app/interfaces/optionset";

export class GetEntityMetadataResponse {
    private _entityMetadata: IEntityDefinition;
    private _attributes: string[];
    public _optionSets: IOptionSetDefinition[];
    constructor(entityMetadata: IEntityDefinition, attributes: string[], optionSets: IODataResponse<IOptionSetDefinition>) {
        this._entityMetadata = entityMetadata;
        this._attributes = attributes?.map(x => x.toLowerCase()) ?? [];
        this._optionSets = optionSets.value;
    }
    public get ActivityTypeMask(): number {
        throw new Error("Not implemented!");
    }
    public get AutoRouteToOwnerQueue(): boolean {
        throw new Error("Not implemented!");
    }
    public get CanEnableSyncToExternalSearchIndex(): boolean {
        throw new Error("Not implemented!");
    }
    public get CanTriggerWorkflow(): boolean {
        throw new Error("Not implemented!");
    }
    public get Description(): string {
        return LocalizeLabel(this._entityMetadata.Description.LocalizedLabels);
    }
    public get DisplayCollectionName(): string {
        return LocalizeLabel(this._entityMetadata.DisplayCollectionName.LocalizedLabels);
    }
    public get DisplayName(): string {
        return LocalizeLabel(this._entityMetadata.DisplayName.LocalizedLabels);
    }
    public get EnforceStateTransitions(): boolean {
        throw new Error("Not implemented!");
    }
    public get EntityColor(): string {
        throw new Error("Not implemented!");
    }
    public get EntitySetName(): string {
        return this._entityMetadata.EntitySetName;
    }
    public get IsActivity(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsActivityParty(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsBusinessProcessEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsChildEntity(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsConnectionsEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsCustomEntity(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsCustomizable(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsDocumentManagementEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsDocumentRecommendationsEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsDuplicateDetectionEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsEnabledForCharts(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsImportable(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsInteractionCentricEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsKnowledgeManagementEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsMailMergeEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsManaged(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsOneNoteIntegrationEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsOptimisticConcurrencyEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsQuickCreateEnabled(): boolean {
        return this._entityMetadata.IsQuickCreateEnabled;
    }
    public get IsReadOnlyInMobileClient(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsStateModelAware(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsValidForAdvancedFind(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsVisibleInMobileClient(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsEnabledInUnifiedInterface(): boolean {
        throw new Error("Not implemented!");
    }
    public get LogicalCollectionName(): string {
        throw new Error("Not implemented!");
    }
    public get LogicalName(): string {
        return this._entityMetadata.LogicalName;
    }
    public get ObjectTypeCode(): number {
        throw new Error("Not implemented!");
    }
    public get OwnershipType(): number { // Is actually EntityOwnershipType
        throw new Error("Not implemented!");
    }
    public get PrimaryIdAttribute(): string {
        return this._entityMetadata.PrimaryIdAttribute;
    }
    public get PrimaryImageAttribute(): string {
        throw new Error("Not implemented!");
    }
    public get PrimaryNameAttribute(): string {
        return this._entityMetadata.PrimaryNameAttribute;
    }
    public get Privileges(): SecurityPrivilegeMetadata[] {
        throw new Error("Not implemented!");
    }
    public get SchemaName(): string {
        return this._entityMetadata.SchemaName;
    }
    public get Attributes(): IItemCollection<AttributeMetadata> {
        const findAttribute = (name: string): AttributeMetadata => {
            if (!this._attributes.includes(name.toLowerCase())) return null;

            const attribute = this._entityMetadata.Attributes.find(x => x.LogicalName.toLowerCase() === name.toLowerCase());
            if (!attribute) return null;

            return new AttributeMetadata(this, this._entityMetadata, attribute, this._optionSets.find(x => x.LogicalName == attribute.LogicalName));
        };
        const getAll = (): AttributeMetadata[] => {
            const attributes: AttributeMetadata[] = [];

            for (const attribute of this._entityMetadata.Attributes.filter(x => this._attributes.includes(x.LogicalName.toLowerCase()))) {
                attributes.push(new AttributeMetadata(this, this._entityMetadata, attribute, this._optionSets.find(x => x.LogicalName == attribute.LogicalName)));
            }

            return attributes;
        };
        return {
            get: (name?: string) => {
                if (name) {
                    return findAttribute(name);
                }
                return getAll();
            },
            getAll: () => getAll(),
            getByName: (name: string) => {
                return findAttribute(name);
            },
            getLength: () => {
                return this._entityMetadata.Attributes.filter(x => this._attributes.includes(x.LogicalName.toLowerCase())).length;
            }
        };
    }
    public get OneToManyRelationships(): IItemCollection<OneToManyRelationship> {
        return {
            getAll: () => {
                const attributes: OneToManyRelationship[] = [];

                for (const attribute of this._entityMetadata.OneToManyRelationships) {
                    attributes.push(new OneToManyRelationship(this._entityMetadata, attribute));
                }

                return attributes;
            },
            getByName: (name: string) => {
                const attribute = this._entityMetadata.OneToManyRelationships.find(x => x.SchemaName.toLowerCase() === name.toLowerCase());
                if (!attribute) return null;
                return new OneToManyRelationship(this._entityMetadata, attribute);
            },
            getLength: () => {
                return this._entityMetadata.Attributes.length;
            },
            get: () => {
                throw new Error('Not implemented');
            }
        };
    }
    public get ManyToOneRelationships(): IItemCollection<ManyToOneRelationship> {
        return {
            getAll: () => {
                const attributes: ManyToOneRelationship[] = [];

                for (const attribute of this._entityMetadata.ManyToOneRelationships) {
                    attributes.push(new ManyToOneRelationship(this._entityMetadata, attribute));
                }

                return attributes;
            },
            getByName: (name: string) => {
                const attribute = this._entityMetadata.ManyToOneRelationships.find(x => x.SchemaName.toLowerCase() === name.toLowerCase());
                if (!attribute) return null;
                return new ManyToOneRelationship(this._entityMetadata, attribute);
            },
            getLength: () => {
                return this._entityMetadata.Attributes.length;
            },
            get: () => {
                throw new Error('Not implemented');
            }
        };
    }
    public get ManyToManyRelationships(): IItemCollection<ManyToManyRelationship> {
        return {
            getAll: () => {
                const attributes: ManyToManyRelationship[] = [];
                for (const attribute of this._entityMetadata.ManyToManyRelationships) {
                    attributes.push(new ManyToManyRelationship(this._entityMetadata, attribute));
                }
                return attributes;
            },
            getByName: (name: string) => {
                const attribute = this._entityMetadata.ManyToManyRelationships.find(x => x.SchemaName.toLowerCase() === name.toLowerCase());
                if (!attribute) return null;
                return new ManyToManyRelationship(this._entityMetadata, attribute);
            },
            getLength: () => {
                return this._entityMetadata.Attributes.length;
            },
            get: () => {
                throw new Error('Not implemented');
            }
        };
    }
    public get _entityDescriptor(): EntityDescriptor {
        return {
            AttributeNames: this._entityMetadata.Attributes.map(att => att.LogicalName)
        };
    }
}

class AttributeMetadata implements Xrm.Metadata.AttributeMetadata {
    private _attribute: Attribute;
    private _entityMetadata: IEntityDefinition;
    private _optionSet: IOptionSetDefinition;
    private _parent: GetEntityMetadataResponse;
    constructor(parent: GetEntityMetadataResponse, entityMetadata: IEntityDefinition, attribute: Attribute, optionSet?: IOptionSetDefinition) {
        this._attribute = attribute;
        this._entityMetadata = entityMetadata;
        this._optionSet = optionSet;
        this._parent = parent;
    }
    public get attributeDescriptor(): ExtendedAttribute {
        const entityMetadata = this._entityMetadata;
        const that = this;
        const descriptor = {
            ...this._attribute,
            get Targets(): string[] {
                const appComponents = AppComponents.get().filter(x => x.componenttype === 1);
                const allTargets = entityMetadata.Attributes.find(x => x.LogicalName === this.LogicalName).Targets;
                // Targets are filtered by entities included in AppModule. "talxis_accessprincipal" and "contact" are not filtered because PowerApps also does not filter "systemuser". Same goes for "transactioncurrency"
                const targets = allTargets?.filter(
                    x => appComponents.some(y => y.componentlogicalname === x) || x === "talxis_accessprincipal" || x === "contact" || x === "transactioncurrency"
                );
                if (!targets || targets.length === 0) {
                    console.warn(`There are no available targets for this app module! Make sure that the app module includes one of the targets${allTargets ? `: '${allTargets.join(', ')}'` : ``}!`);
                };
                return targets;
            },
            get OptionSet(): (Omit<Option, 'Label'> & { Label?: string })[] {
                return that._optionSet.OptionSet.Options.map(x => {
                    return {
                        ...x,
                        Label: LocalizeLabel(x.Label.LocalizedLabels)
                    };
                });
            }

        };
        return descriptor;
    };
    public get AttributeType(): XrmEnum.AttributeTypeCode {
        switch (this._attribute.AttributeType) {
            case "Boolean":
                return AttributeTypeCode.Boolean;
            case "BigInt":
                return AttributeTypeCode.BigInt;
            case "CalendarRules":
                return AttributeTypeCode.CalendarRules;
            case "Customer":
                return AttributeTypeCode.Customer;
            case "DateTime":
                return AttributeTypeCode.DateTime;
            case "Decimal":
                return AttributeTypeCode.Decimal;
            case "Double":
                return AttributeTypeCode.Double;
            case "EntityName":
                return AttributeTypeCode.EntityName;
            case "Integer":
                return AttributeTypeCode.Integer;
            case "Lookup":
                return AttributeTypeCode.Lookup;
            case "ManagedProperty":
                return AttributeTypeCode.ManagedProperty;
            case "Memo":
                return AttributeTypeCode.Memo;
            case "Money":
                return AttributeTypeCode.Money;
            case "Owner":
                return AttributeTypeCode.Owner;
            case "PartyList":
                return AttributeTypeCode.PartyList;
            case "Picklist":
                return AttributeTypeCode.Picklist;
            case "State":
                return AttributeTypeCode.State;
            case "Status":
                return AttributeTypeCode.Status;
            case "String":
                return AttributeTypeCode.String;
            case "Uniqueidentifier":
                return AttributeTypeCode.Uniqueidentifier;
            case "Virtual":
                return AttributeTypeCode.Virtual;
            default:
                throw new Error("Unknown attribute type!");
        }
    }
    public get AttributeTypeName(): string {
        // return this._attribute.AttributeTypeName.Value;
        // TODO: This may need a little revisit to make sure the returned values are aligned with Xrm
        return this._attribute.AttributeType.toLowerCase();
    }
    public get Description(): string {
        return LocalizeLabel(this._attribute.Description.LocalizedLabels);
    }
    public get DisplayName(): string {
        return LocalizeLabel(this._attribute.DisplayName.LocalizedLabels);
    }
    public get EntityLogicalName(): string {
        return this._attribute.EntityLogicalName;
    }
    public get Format(): FormatType {
        return this._attribute.Format;
    }
    public get DateTimeBehavior(): DateTimeBehaviorType {
        return this._attribute.DateTimeBehavior.Value;
    }
    //Power Apps returns '2' if you set a field behavior as 'Date Only'
    public get Behavior(): ComponentFramework.FormattingApi.Types.DateTimeFieldBehavior | 2 {
        switch (this.DateTimeBehavior) {
            case 'UserLocal': return 1;
            case 'TimeZoneIndependent': return 3;
            case 'DateOnly': return 2;
            case 'None': return 0;
        }
    }
    public get IsValidForGrid(): string {
        throw new Error("Not implemented!");
    }
    public get LogicalName(): string {
        return this._attribute.LogicalName;
    }
    public get DefaultFormValue(): number {
        return this._optionSet.DefaultFormValue;
    }
    public getDefaultStatus(state: number): number {
        if (this._attribute.LogicalName !== "statecode") {
            throw "getDefaultStatus can be only called on statecode field!";
        }
        const stateDefinition = this._optionSet.OptionSet.Options.find(x => x.Value === state);
        return stateDefinition.DefaultStatus;
    }
    public getStatusValuesForState(state: number): number[] {
        if (this._attribute.LogicalName !== "statecode") {
            throw "getDefaultStatus can be only called on statecode field!";
        }
        const statusCodes = this._parent._optionSets.find(x => x.LogicalName === "statuscode").OptionSet;
        return statusCodes?.Options.filter(x => x.State === state).map(x => x.Value) ?? [];
    }
    public getState(status: number): number {
        if (this._attribute.LogicalName !== "statuscode") {
            throw "getState can be only called on statuscode field!";
        }
        const stateDefinition = this._optionSet.OptionSet.Options.find(x => x.Value === status);
        if (!stateDefinition) {
            throw `Invalid value ${status} for status`;
        }
        return stateDefinition.State;
    }
    // @ts-ignore - OptionSet type is incorrect in Xrm
    public get OptionSet(): { [key: number]: object } {
        return this._optionSet.OptionSet.Options.reduce((accumulator: { [key: number]: object }, x) => {
            // For some reason, Xrm types are wrong, because OptionSet in Power Apps returns only `text` and `value` properties, so we return them too and also return the required values as undefined
            accumulator[x.Value] = {
                text: LocalizeLabel(x.Label.LocalizedLabels),
                value: x.Value,
            };

            return accumulator;
        }, {});
    }
}
class ManyToOneRelationship {
    private _attribute: IRelationship;
    private _entityMetadata: IEntityDefinition;
    constructor(entityMetadata: IEntityDefinition, attribute: IRelationship) {
        this._attribute = attribute;
        this._entityMetadata = entityMetadata;
    }
    public get relationshipMetadata(): IRelationship {
        return this._attribute;
    }
    public get AssociatedMenuConfiguration(): object {
        throw new Error("Not implemented!");
    }
    public get CascadeConfiguration(): object {
        throw new Error("Not implemented!");
    }
    public get IntroducedVersion(): string {
        throw new Error("Not implemented!");
    }
    public get IsCustomRelationship(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsCustomizable(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsHierarchical(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsManaged(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsValidForAdvancedFind(): boolean {
        return this._attribute.IsValidForAdvancedFind;
    }
    public get ReferencedAttribute(): string {
        return this._attribute.ReferencedAttribute;
    }
    public get ReferencedEntity(): string {
        return this._attribute.ReferencedEntity;
    }
    public get ReferencedEntityNavigationPropertyName(): string {
        return this._attribute.ReferencedEntityNavigationPropertyName;
    }
    public get ReferencingAttribute(): string {
        return this._attribute.ReferencingAttribute;
    }
    public get ReferencingEntity(): string {
        return this._attribute.ReferencingEntity;
    }
    public get ReferencingEntityNavigationPropertyName(): string {
        return this._attribute.ReferencingEntityNavigationPropertyName;
    }
    public get RelationshipType(): number {
        return Relationship.mapRelationshipType(this._attribute.RelationshipType);
    }
    public get SchemaName(): string {
        return this._attribute.SchemaName;
    }
    public get SecurityTypes(): string {
        throw new Error("Not implemented!");
    }
}

class OneToManyRelationship {
    private _attribute: IRelationship;
    private _entityMetadata: IEntityDefinition;
    constructor(entityMetadata: IEntityDefinition, attribute: IRelationship) {
        this._attribute = attribute;
        this._entityMetadata = entityMetadata;
    }
    public get relationshipMetadata(): IRelationship {
        return this._attribute;
    }
    public get AssociatedMenuConfiguration(): object {
        throw new Error("Not implemented!");
    }
    public get CascadeConfiguration(): object {
        throw new Error("Not implemented!");
    }
    public get IntroducedVersion(): string {
        throw new Error("Not implemented!");
    }
    public get IsCustomRelationship(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsCustomizable(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsHierarchical(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsManaged(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsValidForAdvancedFind(): boolean {
        return this._attribute.IsValidForAdvancedFind;
    }
    public get ReferencedAttribute(): string {
        return this._attribute.ReferencedAttribute;
    }
    public get ReferencedEntity(): string {
        return this._attribute.ReferencedEntity;
    }
    public get ReferencedEntityNavigationPropertyName(): string {
        return this._attribute.ReferencedEntityNavigationPropertyName;
    }
    public get ReferencingAttribute(): string {
        return this._attribute.ReferencingAttribute;
    }
    public get ReferencingEntity(): string {
        return this._attribute.ReferencingEntity;
    }
    public get ReferencingEntityNavigationPropertyName(): string {
        return this._attribute.ReferencingEntityNavigationPropertyName;
    }
    public get RelationshipType(): number {
        return Relationship.mapRelationshipType(this._attribute.RelationshipType);
    }
    public get SchemaName(): string {
        return this._attribute.SchemaName;
    }
    public get SecurityTypes(): string {
        throw new Error("Not implemented!");
    }
}

class ManyToManyRelationship {
    private _attribute: IManyToManyRelationship;
    private _entityMetadata: IEntityDefinition;
    constructor(entityMetadata: IEntityDefinition, attribute: IManyToManyRelationship) {
        this._attribute = attribute;
        this._entityMetadata = entityMetadata;
    }
    public get relationshipMetadata(): IManyToManyRelationship {
        return this._attribute;
    }
    public get Entity1LogicalName(): string {
        return this._attribute.Entity1LogicalName;
    }
    public get Entity1IntersectAttribute(): string {
        return this._attribute.Entity1IntersectAttribute;
    }
    public get Entity1NavigationPropertyName(): string {
        return this._attribute.Entity1NavigationPropertyName;
    }
    public get Entity2LogicalName(): string {
        return this._attribute.Entity2LogicalName;
    }
    public get Entity2IntersectAttribute(): string {
        return this._attribute.Entity2IntersectAttribute;
    }
    public get Entity2NavigationPropertyName(): string {
        return this._attribute.Entity2NavigationPropertyName;
    }
    public get IntersectEntityName(): string {
        return this._attribute.IntersectEntityName;
    }
    public get RelationshipType(): number {
        return Relationship.mapRelationshipType(this._attribute.RelationshipType);
    }
    public get SchemaName(): string {
        return this._attribute.SchemaName;
    }
    public get IsValidForAdvancedFind(): boolean {
        return this._attribute.IsValidForAdvancedFind;
    }
}

class Relationship {
    public static mapRelationshipType(relationshipType: string): number {
        switch (relationshipType) {
            case RelationshipType.ManyToMany:
                return 1;
            case RelationshipType.OneToMany:
                return 0;
            default:
                throw new Error('Invalid relationship type');
        }
    }
}

interface ExtendedAttribute extends Attribute {
    Targets: string[];
}

interface SecurityPrivilegeMetadata {
    CanBeBasic: boolean;
    CanBeDeep: boolean;
    CanBeGlobal: boolean;
    CanBeLocal: boolean;
    CanBeEntityReference: boolean;
    CanBeParentEntityReference: boolean;
    Name: string;
    PrivilegeId: string;
    PrivilegeType: Constants.PrivilegeType;
}

declare namespace Constants {
    /**
     * Entity privilege types.
     */
    const enum PrivilegeType {
        None = 0,
        Create = 1,
        Read = 2,
        Write = 3,
        Delete = 4,
        Assign = 5,
        Share = 6,
        Append = 7,
        AppendTo = 8,
    }
}
// Comes from XrmEnum.AttributeTypeCode
class AttributeTypeCode {
    static Boolean: number = 0;
    static Customer: number = 1;
    static DateTime: number = 2;
    static Decimal: number = 3;
    static Double: number = 4;
    static Integer: number = 5;
    static Lookup: number = 6;
    static Memo: number = 7;
    static Money: number = 8;
    static Owner: number = 9;
    static PartyList: number = 10;
    static Picklist: number = 11;
    static State: number = 12;
    static Status: number = 13;
    static String: number = 14;
    static Uniqueidentifier: number = 15;
    static CalendarRules: number = 16;
    static Virtual: number = 17;
    static BigInt: number = 18;
    static ManagedProperty: number = 19;
    static EntityName: number = 20;
}
interface IItemCollection<T> {
    // forEach(delegate: IterativeDelegate<T>): void;
    // get(delegate: MatchingDelegate<T>): T[];
    // get(itemNumber: number): T;
    // get(itemName: string): T;
    get(name?: string): T[] | T;
    getAll(): T[];
    getByName(name: string): T;
    getLength(): number;
}
interface IterativeDelegate<T> {
    /**
     * Called for each item in an array
     *
     * @param	{T} item   The item.
     * @param	{number} index	 Zero-based index of the item array.
     */
    (item: T, index: number): void;
}
interface MatchingDelegate<T> {
    /**
     * Called for each item in an array
     *
     * @param	{T} item   The item.
     * @param	{number} index	 Zero-based index of the item array.
     *
     * @return	true if the item matches, false if it does not.
     */
    (item: T, index: number): boolean;
}