import * as React from 'react';
import { ComboBox } from '@talxis/react-components/dist/components/ComboBox';
import { IMultiSelectOptionSetProps } from '../interfaces/index';
import { IComboBox, IComboBoxOption, IContextualMenuItem } from '@fluentui/react';

export const MultiSelectOptionSetPicker: React.FunctionComponent<IMultiSelectOptionSetProps> = (props) => {
    const selectedKeys = props.selectedKey as number[];

    const onChange = React.useCallback((event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => {
        let _value: number[] = [];
        if (option) {
            _value = option.selected ? [...selectedKeys as any, option.key as number] : selectedKeys.filter(k => k !== option.key);
            props.onOptionsChange(_value);
        }
    }, [selectedKeys]);

    const onClick = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem): void => {
        props.onOptionsChange([]);
    };

    return (
        <ComboBox
            {...props}
            selectedKey={selectedKeys}
            onChange={onChange}
            deleteButtonProps={selectedKeys.length !== 0 ? { onClick: onClick, iconProps: { iconName: 'Cancel' }, showOnlyOnHover: true, key: 'Delete' } : null}
        />
    );
};
export default MultiSelectOptionSetPicker;