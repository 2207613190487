import { ITextFieldProps, TextField } from "@talxis/react-components";
import React, { useEffect, useState } from "react";

export const CurrencyComponent: React.FC<ITextFieldProps> = (props) => {
    const [value, setValue] = useState<string>(props.value);

    useEffect(() => {
        if (!props.errorMessage) {
            setValue(props.value);
        }
    }, [props]);
    return <TextField
        {...props}
        value={value}
        onChange={(e, newValue) => setValue(newValue)} />;
};