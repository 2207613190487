import React, { Component, Fragment } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from './TelemetryService';

type TelemetryProviderProps = {
    history: any;
    after?: () => void
};

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component<TelemetryProviderProps> {
    state = {
        initialized: false
    };

    componentDidMount() {
        const { history } = this.props;
        const { initialized } = this.state;
        if (!Boolean(initialized) && Boolean(process.env.REACT_APP_AI_CONNSTR) && Boolean(history)) {
            ai.initialize(process.env.REACT_APP_AI_CONNSTR, history, process.env.REACT_APP_AI_APPID);
            this.setState({ initialized: true });
        }

        if (this.props.after) {
            this.props.after();
        }
    }

    render() {
        const { children } = this.props;
        return (
            <Fragment>
                {children}
            </Fragment>
        );
    }
}

export default withAITracking(ai.reactPlugin, TelemetryProvider);