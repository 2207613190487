import { Grid } from "@src/components/controls/DatasetControl/Grid";
import { Ribbon } from './Ribbon';
import { SCRIPT_ISOLATION } from "@src/app/Constants";

export class DatasetRibbon extends Ribbon {
    private _grid: Grid;
    constructor(grid: Grid) {
        super({
            shouldIsolateScripts: SCRIPT_ISOLATION,
            isInitialized: () => this._grid.isInitialized,
            getEntityName: () => this._grid.entityName,
            getLocation: () => this._getRibbonLocation(),
            getFormContext: () => this._grid.formContext?.xrmExecutionContext?.getFormContext(),
            getParameterValuesOverride: (parameters, selectedRecordIds) => this._getParameterValues(parameters, selectedRecordIds),
            isEnabled: () => this.isEnabled()
        });
        this._grid = grid;
    }

    public isEnabled() {
        if (this._grid.isFullyVirtual) {
            return false;
        }
        return this._grid.cdsOptions.displayCommandBar;
    }

    /**
     * @param {string} commandButtonIds: Specifies the exact commands you want to retrieve. If not defined, all commands will be returned.
     * @param {boolean} selectedRecordIds Specify the selected records. If not specified, the selected records on grid will be used instead
     */
    public async getCommands(commandButtonIds?: string[], selectedRecordIds?: string[]): Promise<Ribbon.Command[]> {
        const ribbonDefinition = await this.getDefinition(commandButtonIds, selectedRecordIds);
        const allButtons: Ribbon.Definition.Button[] = [];
        const collectButtonsRecursively = (buttons: Ribbon.Definition.Button[]) => {
            for (const button of buttons) {
                allButtons.push(button);
                if (button.menuSections) {
                    for (const menuSection of button.menuSections) {
                        collectButtonsRecursively(menuSection.buttons);
                    }
                }
            }
        };
        for (const group of ribbonDefinition.groups) {
            collectButtonsRecursively(group.buttons);
        }
        return allButtons.map(button => {
            return {
                commandButtonId: button.id,
                commandId: button.command,
                shouldBeVisible: button.visible,
                canExecute: true,
                children: [],
                label: button.label,
                icon: button.icon?.value,
                controlType: '',
                tooltip: '',
                __isInline: button.isInline,
                execute: () => button.function?.execute()
            };
        }).filter(button => !commandButtonIds || commandButtonIds.includes(button.commandButtonId));
    }

    public async getCommand(id: string): Promise<Ribbon.Command> {
        return (await this.getCommands([id]))[0];
    }

    private _getRibbonLocation(): Ribbon.Location {
        if (this._grid.isHomepageGrid) {
            return "HomepageGrid" as Ribbon.Location;
        }
        return "SubGrid" as Ribbon.Location;
    }
    protected _getParameterValues(parameters: Ribbon.Definition.ButtonFunctionParameter[], selectedRecordIds?: string[]): string[] {
        const _selectedRecordIds = selectedRecordIds ?? this._grid.selectedRecordIds;
        const parameterValues: any[] = [];
        for (const parameter of parameters) {
            //TODO: should be parameter.name
            switch (parameter.value) {
                case "SelectedEntityTypeCode":
                case "SelectedEntityTypeName": {
                    parameterValues.push(this._grid.entityName);
                    break;
                }
                case "FirstSelectedItemId":
                    const id: string = _selectedRecordIds[0];
                    parameterValues.push(id ?? null);
                    break;
                case "SelectedControl":
                    parameterValues.push(this._grid.gridContext);
                    break;
                case "SelectedControlSelectedItemCount":
                    parameterValues.push(_selectedRecordIds.length);
                    break;
                case "SelectedControlSelectedItemIds":
                    parameterValues.push(_selectedRecordIds);
                    break;
                case "SelectedControlSelectedItemReferences":
                    parameterValues.push(_selectedRecordIds.map(recordId => {
                        return {
                            Id: recordId,
                            TypeName: this._grid.entityName
                        };
                    }));
                    break;
                case "SelectedControlAllItemCount":
                    parameterValues.push(this._grid.sortedRecordIds.length);
                    break;
                case "SelectedControlAllItemIds":
                case "PrimaryItemIds": {
                    parameterValues.push(this._grid.sortedRecordIds);
                    break;
                }
                case "SelectedControlAllItemReferences":
                    parameterValues.push(this._grid.sortedRecordIds.map(recordId => {
                        return {
                            Id: recordId,
                            TypeName: this._grid.entityName
                        };
                    }));
                    break;
                case "entity": {
                    parameterValues.push(this._grid.getRecords().filter(x => _selectedRecordIds.includes(x.getRecordId()))?.[0] ?? null);
                    break;
                }
                case "SelectedControlUnselectedItemCount":
                    parameterValues.push(this._grid.sortedRecordIds.length - _selectedRecordIds.length);
                    break;
                case "SelectedControlUnselectedItemIds":
                    parameterValues.push(this._grid.sortedRecordIds.filter(id => !_selectedRecordIds.includes(id)));
                    break;
                case "SelectedControlUnselectedItemReferences":
                    const unSelectedItemIds = this._grid.sortedRecordIds.filter(id => !_selectedRecordIds.includes(id));
                    parameterValues.push(unSelectedItemIds.map(recordId => {
                        return {
                            Id: recordId,
                            TypeName: this._grid.entityName
                        };
                    }));
                    break;
                default:
                    parameterValues.push(super._getParameterValues([parameter])[0] ?? null);
                    break;
            }

        }
        return parameterValues;
    }
}