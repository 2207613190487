import { isDev } from "../../Functions";
import { ThemeDesigner, ThemeType } from "@utilities/ThemeDesigner";
import { getTheme, IPalette, ITheme, loadTheme } from "@fluentui/react";
import { buildMetadataUrl, getWebResourceUrl } from "@definitions/MetadataApi";
import { DEFAULT_BODY_MAIN_BACKGROUND_COLOR, FRONTEND_VERSION } from "@src/app/Constants";

export enum SitemapOrientation {
    Horizontal,
    Vertical
}
export enum SitemapAlignment {
    Center = "Center",
    Left = "Left"
}
export class Theme {
    private _logoUrl?: string;
    private _faviconUrl = `images/favicon.ico?v=${FRONTEND_VERSION}`;
    private _ribbonTheme?: ITheme;
    private _navbarTheme?: ITheme;
    private _navigationTheme?: ITheme;
    private _customCSS?: string;
    private _mainTheme: ITheme;
    public bodyBackgroudColor?: string;
    public sitemapOrientation = SitemapOrientation.Vertical;
    public horizontalSitemapAlignment: SitemapAlignment;
    public sitemapVerticalAreasAsTabs: boolean;
    public showHorizontalSitemapIcons: boolean;
    public gradientBackground: boolean;
    public horizontalSitemapBodyWidth: number;
    public globalNotificationCollapseCount: number;

    constructor(entity?: ComponentFramework.WebApi.Entity) {
        this.sitemapOrientation = entity?.["talxis_sitemaporientationtypecode"] === 742070000 ? SitemapOrientation.Horizontal : SitemapOrientation.Vertical;
        this.showHorizontalSitemapIcons = this.sitemapOrientation === SitemapOrientation.Horizontal ? (entity?.["talxis_showsitemapitemicons"] ?? false) : undefined;
        this.horizontalSitemapAlignment = entity?.["talxis_sitemapalignmenttypecode"] === 742070001 ? SitemapAlignment.Left : SitemapAlignment.Center;
        this.horizontalSitemapBodyWidth = this.sitemapOrientation === SitemapOrientation.Horizontal && entity?.["talxis_bodywidth"];
        this.sitemapVerticalAreasAsTabs = entity?.["talxis_areasastabs"];
        //default value set to 2 to follow PowerApps design
        this.globalNotificationCollapseCount = entity?.["talxis_globalnotification_collapsecount"] ?? 2;
        this.gradientBackground = entity?.["talxis_backgroundgradient"];
        //this color refers to the background color of entire page and isn't reflected on surfaces
        this.bodyBackgroudColor = entity?.['talxis_bodybackgroundcolor'] ?? DEFAULT_BODY_MAIN_BACKGROUND_COLOR;
        const primaryColor = entity?.["talxis_maincolor"];
        const textColor = entity?.["talxis_textcolor"];
        //this applies to the background color of all fluent surfaces (card, callout, panel...)
        //we currently only support white background since a lot of PCF's and portal code expects these surfaces to be white
        //changing this color will break a lot of things, thus why it is currently hardcoded to white
        const backgroundColor = 'white';
        const ribbonBackgroundColor = entity?.["talxis_ribbonbackgroundcolor"];
        const ribbonTextColor = entity?.["talxis_ribbontextcolor"];
        const ribbonPrimaryColor = entity?.["talxis_ribbonprimarycolor"];
        const navbarBackgroundColor = entity?.["talxis_navbarbackgroundcolor"];
        const navbarTextColor = entity?.["talxis_navbartextcolor"];
        const navbarPrimaryColor = entity?.["talxis_navbarprimarycolor"];
        const navigationBackgroundColor = entity?.["talxis_navigationbackgroundcolor"];
        const navigationTextColor = entity?.["talxis_navigationtextcolor"];
        const navigationPrimaryColor = entity?.["talxis_navigationprimarycolor"];
        this._customCSS = entity?.["talxis_cascadingstylesheets"];
        if (entity) {
            if (entity['talxis_logotooltip']) {
                this._logoUrl = buildMetadataUrl(`v9.1/talxis_configuration_themes(${entity['talxis_configuration_themeid']})/talxis_logotooltip/$value`, false, isDev());
            }
            if (entity['talxis_faviconlogoid']) {
                const url = new URL(buildMetadataUrl(`v9.1/talxis_configuration_themes(${entity['talxis_configuration_themeid']})/talxis_faviconlogoid/$value`, false, isDev()));
                //&talxisFavicon parameter is used so the url does not end with .com since it breaks icon fetching
                url.searchParams.append('talxis_favicon', 'true');
                this._faviconUrl = url.toString();
            }
        }
        const faviconElement = document.createElement('link');
        faviconElement.setAttribute('rel', 'icon');
        faviconElement.setAttribute('href', this._faviconUrl);
        document.head.appendChild(faviconElement);
        this._mainTheme = this._generateTheme(backgroundColor, primaryColor, textColor, ThemeType.MAIN);
        this._ribbonTheme = this._generateTheme(ribbonBackgroundColor, ribbonPrimaryColor, ribbonTextColor, ThemeType.RIBBON, this._mainTheme);
        this._navbarTheme = this._generateTheme(navbarBackgroundColor, navbarPrimaryColor, navbarTextColor, ThemeType.NAVBAR, this._mainTheme);
        this._navigationTheme = this._generateTheme(navigationBackgroundColor, navigationPrimaryColor, navigationTextColor, ThemeType.NAVIGATION, this._mainTheme);

        const overridenColors: IPalette = JSON.parse(entity?.["talxis_overridecolorsjson"] ?? null);
        if (overridenColors) {
            for (const [key, value] of Object.entries(overridenColors)) {
                //@ts-ignore - key will always be a prop from palette
                this._mainTheme.palette[key] = value;
            }
        }
        this._injectCustomCss();
        loadTheme(this._mainTheme);
    }

    public getRibbonTheme(): ITheme {
        return this._ribbonTheme;;
    }
    public getNavigationTheme(): ITheme {
        return this._navigationTheme;
    }
    public getNavbarTheme(): ITheme {
        return this._navbarTheme;
    }
    public getMainTheme(): ITheme {
        return this._mainTheme;
    }

    public getLogoUrl() {
        if (!this._logoUrl) {
            return this._navbarTheme.isInverted ? `images/talxis_logo_white.png` : `images/talxis_logo_black.png`;
        }
        return this._logoUrl;
    }

    public getCSSVariables() {
        const variables: {
            [name: string]: string;
        } = {};
        const injectVaribles = (name: ThemeType, theme: ITheme) => {
            for (const [key, value] of Object.entries(theme.semanticColors)) {
                variables[`--talxis-${name}-${key}`] = value;
            }
            for (const [key, value] of Object.entries(theme.palette)) {
                variables[`--talxis-${name}-${key}`] = value;
            }
        };
        injectVaribles(ThemeType.MAIN, getTheme());
        injectVaribles(ThemeType.NAVBAR, this._navbarTheme);
        injectVaribles(ThemeType.RIBBON, this._ribbonTheme);
        injectVaribles(ThemeType.NAVIGATION, this._navigationTheme);
        return {
            '--talxis-main-bodyBackgroundMain': this.bodyBackgroudColor,
            ...(this.horizontalSitemapBodyWidth && { '--talxis-main-bodyWidth': `${this.horizontalSitemapBodyWidth}px` }),
            ...variables
        };
    }

    private _injectCustomCss() {
        if (!this._customCSS) {
            return;
        }
        const styleElement = document.createElement('style');
        styleElement.setAttribute('type', 'text/css');
        styleElement.setAttribute('id', 'customCSS');
        styleElement.innerHTML = this._replaceWebResourceLinksInCss();
        document.head.appendChild(styleElement);
    }

    private _replaceWebResourceLinksInCss() {
        const generatedCss = this._customCSS.replace(/: url\(['"]?\/webresources\/(.*)['"]?\)/i, `: url(${getWebResourceUrl(`webresources/$1`)})`);
        return generatedCss;
    }
    private _generateTheme(backgroundColor: string, primaryColor: string, textColor: string, themeType: ThemeType, defaultTheme?: ITheme): ITheme {
        return ThemeDesigner.generateTheme({
            backgroundColor: backgroundColor,
            primaryColor: primaryColor,
            textColor: textColor,
            themeType: themeType,
            defaultTheme: defaultTheme
        });
    }

}