import * as React from 'react';
import './css/styles.css';
import { ICommandBarItemProps, ICommandBarProps } from '@fluentui/react/lib/components/CommandBar/CommandBar.types';
import { getTheme, ITheme, mergeStyles } from '@fluentui/react/lib/Styling';
import { CommandBar } from '@talxis/react-components/dist/components/CommandBar';
import { useState } from 'react';
import { cloneDeep } from 'lodash';
import { ThemeDefinition } from '@src/app/classes/definitions/ThemeDefinition';
import { IButtonProps, CommandBarButton, Icon, IRenderFunction } from '@fluentui/react';
export interface ITabCommandBarProps extends ICommandBarProps {
    selectedKey?: string;
    theme?: ITheme;
}

const TabCommandBar: React.FC<ITabCommandBarProps> = (props) => {
    const [itemsState, setItemsState] = useState<ICommandBarItemProps[]>(props.items);
    const theme = props.theme ?? getTheme();
    const getThemedStyles = (): string => {
        return mergeStyles({
            '[data-selected-top-level="true"]::after': {
                borderBottom: `3px solid ${theme.palette.themePrimary}`,
                position: 'relative',
                zIndex: 2,
                top: -2

            },
            ':global([data-selected="true"] .ms-ContextualMenu-itemText)': {
                fontWeight: 600
            }
        });
    };

    const setActiveItem = () => {
        const items = cloneDeep(props.items);
        for (const item of items) {
            if (markCurrentItem(item)) {
                item["data-selected-top-level"] = true;
                break;
            }
        }
        setItemsState(items);
    };
    const markCurrentItem = (item: ICommandBarItemProps): ICommandBarItemProps => {
        if (item.key === props.selectedKey) {
            item['data-selected'] = true;
            return item;
        }
        const childItems = item.sectionProps?.items ?? item.subMenuProps?.items;
        if (childItems) {
            for (const childItem of childItems) {
                const markedItem = markCurrentItem(childItem);
                if (markedItem) {
                    return markedItem;
                }
            }
        }
    };
    React.useEffect(() => {
        if (props.items.length > 0) {
            setActiveItem();
        }
    }, [props.items, props.selectedKey]);

    const iconRender: IRenderFunction<IButtonProps> = buttonProps => {
        return buttonProps.iconProps?.imageProps?.src ? (
            <svg
                className={mergeStyles({
                    width: 18,
                    height: 18,
                    marginRight: 3,
                    'path': {
                        fill: theme.palette.themePrimary
                    }
                })}
                data-src={buttonProps.iconProps?.imageProps.src}
            />
        ) : (<Icon {...buttonProps.iconProps} />);
    };

    const IconButtonWrapper: React.FunctionComponent<IButtonProps> = buttonProps => {
        return (<CommandBarButton {...buttonProps} onRenderIcon={iconRender} />);
    };

    return (
        <CommandBar
            {...props}
            globalCalloutTheme={ThemeDefinition.getTheme().getMainTheme()}
            items={itemsState}
            buttonAs={IconButtonWrapper}
            className={`TALXIS__tab-bar ${getThemedStyles()} ${props.className}`}
        />
    );
};
export default TabCommandBar;
