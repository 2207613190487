export enum DataType {
    MultiSelectPicklist = "MultiSelectPicklist",
    MultiSelectOptionSet = "MultiSelectOptionSet",
    SingleLineText = "SingleLine.Text",
    SingleLineMail = 'SingleLine.Email',
    SingleLinePhone = 'SingleLine.Phone',
    SingleLineUrl = 'SingleLine.URL',
    Multiple = "Multiple",
    TwoOptions = "TwoOptions",
    DateAndTimeDateOnly = "DateAndTime.DateOnly",
    DateAndTimeDateAndTime = "DateAndTime.DateAndTime",
    OptionSet = "OptionSet",
    LookupCustomer = "Lookup.Customer",
    LookupSimple = "Lookup.Simple",
    LookupRegarding = "Lookup.Regarding",
    LookupOwner = "Lookup.Owner",
    Currency = "Currency",
    Decimal = "Decimal",
    FP = "FP",
    WholeNone = "Whole.None",
    WholeDuration = "Whole.Duration",
    File = "File",
    Image = "Image"
}