import React from 'react';
import styles from './ViewLoading.module.css';
import { Shimmer } from '@fluentui/react/lib/components/Shimmer/Shimmer';
import { ShimmerElementType } from '@fluentui/react/lib/components/Shimmer/Shimmer.types';
import { RibbonController } from '@src/components/navigation/ribbon/RibbonController';

export interface IViewLoadingProps {
    headerPlaceholder?: boolean;
    footerPlaceHoder?: boolean;
    ribbonPlaceholder?: boolean;
    style?: React.CSSProperties;
    viewPageHeaderPlaceholder?: boolean;
    numOfRows?: number;
}

export const ViewLoading: React.FC<IViewLoadingProps> = (props) => {
    return (
        <div style={props.style} className={styles.root}>
            {props.ribbonPlaceholder &&
                <RibbonController />
            }
            {props.viewPageHeaderPlaceholder &&
                <div className={styles.viewPageHeader}>
                    <Shimmer
                        className={styles.sectionHeader}
                        shimmerElements={[
                            { type: ShimmerElementType.gap, width: 15, height: 30 },
                            { type: ShimmerElementType.line, height: 10, width: 200 },
                            { type: ShimmerElementType.gap, width: '100%', height: 30 }
                        ]} />
                </div>
            }
            {props.headerPlaceholder &&
                <div className={styles.header}></div>
            }
            <div className={styles.body}>
                <div className={styles.wrapper}>
                    {Array.from(Array(props.numOfRows * 8 || 200).keys()).map((x) =>
                        <div key={x} className={styles.line} />
                    )}
                </div>
            </div>
            {props.footerPlaceHoder &&
                <div className={styles.footer}></div>
            }
        </div>
    );
};