import { Panel } from "@fluentui/react/lib/components/Panel/Panel";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DefaultButton, Label, mergeStyles, PrimaryButton, Text, useTheme } from '@fluentui/react';
import { FormControlType } from "@src/components/controls/native/Form/interfaces/enums";
import { formatXrmGuid } from "@src/app/Functions";
import { AppContext } from "@src/providers/AppProvider";
import { FieldControl } from "@src/components/controls/FieldControl";
import styles from './LookupPanel.module.css';
import { getLocalizedString } from "@src/localization/helpers";
import { IXrmLookupOptions } from "@src/app/interfaces/IXrmLookupOptions";

export interface ILookupPanelProps extends IXrmLookupOptions {
    isOpen: boolean;
    onSuccessCallback(result: ComponentFramework.LookupValue[]): void
}
export const LookupPanel: React.FC<ILookupPanelProps> = (props) => {
    const appContext = useContext(AppContext);
    const theme = useTheme();
    const controlInstanceRef = useRef<ComponentFramework.StandardControl<any, any>>(null);
    const [refresh, setRefresh] = useState<boolean>(false);

    const className = `${styles.root} ${mergeStyles({
        '.ms-Panel-footer': {
            borderTop: `1px solid ${theme.semanticColors.bodyDivider}`,
            background: theme.semanticColors.bodyBackground
        }
    })}`;
    const getTargetEntitiesXmlString = (): string => {
        const xmlDoc = document.implementation.createDocument("", "", null);
        const targetEntities = xmlDoc.appendChild(xmlDoc.createElement('TargetEntities'));
        for (const targetEntityLogicalName of props.entityTypes ?? []) {
            const targetEntityElement = xmlDoc.createElement('TargetEntity');
            const entityLogicalNameElement = xmlDoc.createElement('EntityLogicalName');
            entityLogicalNameElement.innerHTML = targetEntityLogicalName;
            if (props.defaultViewId) {
                const defaultViewIdElement = xmlDoc.createElement('DefaultViewId');
                defaultViewIdElement.innerHTML = formatXrmGuid(props.defaultViewId);
                targetEntityElement.appendChild(defaultViewIdElement);
            }

            targetEntityElement.appendChild(entityLogicalNameElement);
            targetEntities.appendChild(targetEntityElement);
        }
        return new XMLSerializer().serializeToString(xmlDoc);
    };
    const onDismissHandler = () => {
        props.onSuccessCallback([]);
        appContext.setLookupPanelProps({
            ...appContext.lookupPanelProps,
            isOpen: false
        });
    };
    const onConfirmHandler = () => {
        const result: Xrm.LookupValue[] = controlInstanceRef.current.getOutputs()?.value ?? [];
        for (const value of result) {
            value.id = formatXrmGuid(value.id).toUpperCase();
        }
        props.onSuccessCallback(result);
        appContext.setLookupPanelProps({
            ...appContext.lookupPanelProps,
            isOpen: false
        });
    };
    useEffect(() => {
        setRefresh(true);
    }, [appContext.lookupPanelProps]);

    useEffect(() => {
        if (refresh) {
            setRefresh(false);
        }
    }, [refresh]);

    return (
        <Panel {...props}
            className={className}
            headerText={getLocalizedString("components/navigation/panels/LookupPanel/Header")}
            onDismiss={onDismissHandler}
            isFooterAtBottom
            isBlocking
            isLightDismiss={true}
            onOuterClick={() => { }}
            onRenderFooterContent={() => {
                return (
                    <>
                        <PrimaryButton text={getLocalizedString("components/navigation/panels/LookupPanel/Add")} onClick={onConfirmHandler} />
                        <DefaultButton text={getLocalizedString("components/navigation/panels/LookupPanel/Cancel")} onClick={onDismissHandler} />
                    </>
                );
            }}>
            {props.isOpen && !refresh &&
                <>
                    <Label>{getLocalizedString("components/navigation/panels/LookupPanel/SubHeader")}</Label>
                    <FieldControl name="TALXIS.PCF.Portal.SimpleLookup"
                        bindings={{
                            TargetEntities: {
                                isStatic: true,
                                value: getTargetEntitiesXmlString()
                            },
                            value: {
                                isStatic: true,
                                value: null
                            },
                            EnableBorder: {
                                isStatic: true,
                                value: "true"
                            },
                            MultipleEnabled: {
                                isStatic: true,
                                value: props.allowMultiSelect ? "true" : "false"
                            },
                            IsInlineNewEnabled: {
                                isStatic: true,
                                value: props.talxis_isInlineNewEnabled === false ? "false" : "true"
                            }
                        }}
                        id=""
                        classId=""
                        datafieldname={null}
                        disabled={false}
                        type={FormControlType.Field}
                        visible={true}
                        isUnbound={true}
                        isRequired={false}
                        definition={null}
                        onControlInstanceSet={(instance) => {
                            controlInstanceRef.current = instance;
                        }}
                    />
                </>
            }
        </Panel>
    );
};