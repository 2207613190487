import React from 'react';
import ReactDOM from "react-dom";
import { ITextFieldItemProps, ITextFieldProps, TextField as TextFieldBase } from '@talxis/react-components/dist/components/TextField';

interface IInputs {
    value: ComponentFramework.PropertyTypes.StringProperty;
    enableDeleteButton?: ComponentFramework.PropertyTypes.StringProperty;
    enableCopyButton?: ComponentFramework.PropertyTypes.StringProperty;
    EnableBorder?: ComponentFramework.PropertyTypes.StringProperty;
    ForceDisable?: ComponentFramework.PropertyTypes.StringProperty;
}
interface IOutputs {
    value?: string;
}

export class TextField implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _context: ComponentFramework.Context<IInputs>;
    private _notifyOutputChanged: () => void;
    private _container: HTMLDivElement;
    private _enableDeleteButton: boolean = false;
    private _enableCopyButton: boolean = false;
    private _value: string = "";

    constructor() { }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
        this._context = context;
        this._notifyOutputChanged = notifyOutputChanged;
        this._container = document.createElement("div");
        this._enableDeleteButton = this._context.parameters.enableDeleteButton?.raw === 'true';
        this._enableCopyButton = this._context.parameters.enableCopyButton?.raw === 'true';
        container.appendChild(this._container);
    }
    private _onClick = (): void => {
        this._value = null;
        this._notifyOutputChanged();
    }
    public updateView(context: ComponentFramework.Context<IInputs>): void {
        this._context = context;
        const previousValue = this._value;

        this._value = context.parameters.value.raw ?? "";
        let tempValue = "";
        // TODO: This is workaround to convert the value to string, see setValue comment on FormContext
        if (typeof this._value !== "string") tempValue = this._value + '';
        else tempValue = this._value;

        const deleteButtonProps: ITextFieldItemProps = {
            key: 'clickToDelete',
            showOnlyOnInputHover: true,
            onClick: this._onClick,
            iconProps: { iconName: 'Cancel' },
        };
        const copyButtonProps: ITextFieldItemProps = {
            key: 'clickToCopy',
            showOnlyOnInputHover: true,
            iconProps: { iconName: 'Copy' }
        };
        const textBoxProps: ITextFieldProps = {
            defaultValue: tempValue,
            readOnly: this._context.mode.isControlDisabled,
            disabled: this._context.parameters.ForceDisable?.raw === 'true',
            borderless: this._context.parameters.EnableBorder?.raw !== 'true',
            deleteButtonProps: this._enableDeleteButton && !this._context.mode.isControlDisabled && tempValue && tempValue !== '' ? deleteButtonProps : null,
            maxLength: this._context.parameters.value.attributes.MaxLength,
            clickToCopyProps: this._enableCopyButton ? copyButtonProps : null,
            // TODO: This should be probably on focus change or something, so we don't send updates back and forth
            onChange: (e, value) => {
                this._value = value;
            },
            onBlur: () => {
                this._notifyOutputChanged();
            },
            //notify output changed on Enter key press
            onKeyDown: (event) => {
                if (event.key === 'Enter') {
                    this._notifyOutputChanged();
                }
            }
        };

        // TODO: This fixes an issue with cursor jumping with each update, in future, we should however use a properly controlled field for this
        if (tempValue !== previousValue) {
            ReactDOM.unmountComponentAtNode(this._container);
        }

        ReactDOM.render(React.createElement(TextFieldBase, textBoxProps), this._container);
    }

    public getOutputs(): IOutputs {
        return {
            value: this._value
        };
    }

    public destroy(): void {
    }
}