import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useRibbon } from './useRibbon';
import styles from './Ribbon.module.css';
import { useTheme } from '@fluentui/react/lib/utilities/ThemeProvider/useTheme';
import { mergeStyles, mergeStyleSets, ITheme } from '@fluentui/react/lib/Styling';
import { IconButton } from '@fluentui/react/lib/components/Button/IconButton/IconButton';
import { HistoryContext, history } from '@src/providers/HistoryProvider/HistoryProvider';
import { ThemeProvider } from '@fluentui/react';
import { ThemeDefinition } from '@src/app/classes/definitions/ThemeDefinition';
import { CommandBar } from '@talxis/react-components/dist/components/CommandBar';
import { Text } from '@fluentui/react/lib/Text';
import { Ribbon } from '@src/app/classes/models/Ribbon/Ribbon';

export interface RibbonControllerProps {
    ribbon?: Ribbon;
    className?: string;
    commandBarClassName?: string;
    theme?: ITheme;
    showBackButton?: boolean;
    forceHidden?: boolean;
    hasUnsavedChanges?: boolean;
}

const Loading = () => {
    const theme = useTheme();
    const themedStyles = mergeStyleSets({
        loadingLine: {
            background: `linear-gradient(to right, color-mix(in oklab, ${theme.palette.white}, ${theme.palette.black} 8%) 4%, color-mix(in oklab, ${theme.palette.white}, ${theme.palette.black} 5%) 25%, color-mix(in oklab, ${theme.palette.white}, ${theme.palette.black} 8%) 36%)`
        }
    });
    return (
        <div className={styles.loading}>
            {Array.from(Array(4).keys()).map((x) =>
                <div key={x} className={`${styles.loadingLine} ${themedStyles.loadingLine}`} />
            )}
        </div>
    );
};

const BackButton: React.FC = () => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const historyContext = useContext(HistoryContext);
    const theme = useTheme();
    const className = useMemo(() => {
        return `TALXIS__navigate-back-btn ${mergeStyles({
            height: 44,
            width: 44,
            '&[data-disabled="true"]': {
                pointerEvents: 'none',
                'i': {
                    color: theme.semanticColors.inputIconDisabled
                }
            },
            borderRight: `1px solid ${theme.semanticColors.bodyDivider}`
        })}`;
    }, [isDisabled]);

    useEffect(() => {
        if (historyContext?.currentPage.isFirst()) {
            setIsDisabled(true);
        }
        else {
            setIsDisabled(false);
        }
    }, [historyContext?.currentPage]);
    return (
        <IconButton data-disabled={isDisabled} className={`${className} ${styles.backButton}`} onClick={() => history.goBack()} iconProps={{
            iconName: 'Back',
        }} />
    );
};

export const RibbonController = ({ ribbon, className, commandBarClassName, theme, showBackButton, forceHidden, hasUnsavedChanges }: RibbonControllerProps) => {
    const ribbonTheme = theme ?? useTheme();
    const commands = useRibbon(ribbon);
    if (forceHidden) {
        return <></>;
    }
    if (!ribbon) {
        return <ThemeProvider theme={ribbonTheme} className={`TALXIS__ribbon ${styles.root}${className ? ` ${className}` : ''}`}>
            {showBackButton &&
                <BackButton />
            }
            <Loading />
        </ThemeProvider>;
    }
    return (
        <>
            {/* if ribbon has been fully loaded or is in a process of initial load */}
            {ribbon.isEnabled() && (commands?.length > 0 || commands === null || showBackButton) &&
                <ThemeProvider theme={ribbonTheme} className={`TALXIS__ribbon ${styles.root}${className ? ` ${className}` : ''}`}>
                    {showBackButton &&
                        <BackButton />
                    }
                    {!commands &&
                        <Loading />
                    }
                    {commands &&
                        <>
                            <CommandBar
                                globalCalloutTheme={ThemeDefinition.getTheme()?.getMainTheme()}
                                className={`${styles.commands}${commandBarClassName ? ` ${commandBarClassName}` : ''}`}
                                items={commands.filter(command => !command["farItem"])}
                                farItems={commands.filter(command => command["farItem"])} />
                            {hasUnsavedChanges &&
                                <Text
                                    styles={{
                                        root: {
                                            color: ribbonTheme.semanticColors.buttonTextDisabled
                                        }
                                    }}
                                    className={styles.unsavedChanges}>{window.TALXIS.Portal.Translations.getLocalizedString('components/dialogs/NavigationPromptDialog/Title')}</Text>
                            }
                        </>

                    }
                </ThemeProvider>
            }
        </>
    );
};