import React, { useState } from 'react';
import { CommandBarButton, DirectionalHint, IButtonProps, ICommandBarItemProps, ThemeProvider, TooltipHost } from '@fluentui/react';
import TabCommandBar, { ITabCommandBarProps } from '../../../../../../components/navigation/commandbars/TabCommandBar';
import './css/styles.css';
import { useEffect } from 'react';
import { IAppContext } from '@providers/AppProvider/interfaces';
import { AppContext } from '@providers/AppProvider';
import { useContext } from 'react';
import { SitemapDefinition } from '@definitions/SitemapDefinition';
import { ThemeDefinition } from '@definitions/ThemeDefinition';
import { HistoryContext } from '@src/providers/HistoryProvider/HistoryProvider';
const button: React.FunctionComponent<IButtonProps> = props => {
    return (
        <TooltipHost content={props.title}
            directionalHint={DirectionalHint.bottomCenter}
            hidden={!props.disabled}
        >
            <CommandBarButton {...props} />
        </TooltipHost >
    );
};

export const HorizontalPageNavigation: React.FC = () => {
    const [items, setItems] = useState<ICommandBarItemProps[]>([]);
    const appContext: IAppContext = useContext(AppContext);
    const theme = ThemeDefinition.getTheme().getNavigationTheme();
    const historyContext = useContext(HistoryContext);
    useEffect(() => {
        (async () => {
            setItems(await SitemapDefinition.getCurrentSiteMap().getHorizontalNavigationItems());
        })();
    }, [appContext.refreshSiteMap]);
    return (
        <ThemeProvider theme={theme}>
            <TabCommandBar theme={theme} data-sitemap-alignment={ThemeDefinition.getTheme().horizontalSitemapAlignment} selectedKey={historyContext.currentPage.sitemapKey} items={items} buttonAs={button} />
        </ThemeProvider>
    );
};