import { Callout, DirectionalHint, Text, FontIcon } from "@fluentui/react";
import React, { useMemo, useState } from "react";
import { TextField } from "@talxis/react-components/dist/components/TextField";
import { ITextFieldProps } from "@talxis/react-components";
import styles from './QuickFind.module.css';

export interface IQuickFindProps {
    columnDisplayNames: string[]
    textFieldProps: ITextFieldProps;
}

export const QuickFindComponent: React.FC<IQuickFindProps> = (props) => {
    const id = useMemo(() => `prefix_${window.self.crypto.randomUUID()}`, []);
    const [calloutVisible, setCalloutVisible] = useState<boolean>(false);
    const [isContainsSearchQuery, setIsContainsSearchQuery] = useState<boolean>(false);
    return (
        <>
            {calloutVisible &&
                <Callout
                    className={styles.callout}
                    calloutMaxWidth={250}
                    target={`#${id}`}
                    styles={{
                        calloutMain: styles.callout
                    }}
                    directionalHint={DirectionalHint.bottomLeftEdge}>
                    <div className={styles.calloutContent}>
                        {isContainsSearchQuery ?
                            <Text block>
                                <FontIcon iconName="Warning" />
                                {window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/View/EditColumns/SearchInfo*")}
                            </Text> :
                            <Text block>
                                {window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/View/EditColumns/SearchInfo/part1")}
                                <b>{window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/View/EditColumns/SearchInfo/part2")}</b>
                                {window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/View/EditColumns/SearchInfo/part3")}
                            </Text>
                        }
                        {props.columnDisplayNames.map(name => <Text key={name} block>{name}</Text>)}
                    </div>
                </Callout>
            }
            <TextField
                id={id}
                {...props.textFieldProps}
                onClick={() => setCalloutVisible(true)}
                onBlur={() => setCalloutVisible(false)}
                onChange={(event, newValue) => {
                    if (newValue.startsWith('*')) {
                        setCalloutVisible(true);
                        setIsContainsSearchQuery(true);
                    }
                    else {
                        setIsContainsSearchQuery(false);
                        setCalloutVisible(false);
                    }
                    props.textFieldProps.onChange(event, newValue);
                }}
            />
        </>
    );
};