import { OptionSet, IOptionSetDefinition } from '../../interfaces/optionset';
import { GlobalOptionSet, ITwoOptionsDefinition } from '../../interfaces/twooptions';
import { MultiSelectOptionSet, IMultiSelectOptionSetDefinition } from '../../interfaces/multiselectoptionset';
import { IODataResponse } from '../../interfaces/general';
import { sendMetadataGetRequest } from './MetadataApi';
import { IPromiseCache, cachedWrapper } from '@utilities/MemoryCachingHelpers';

export class OptionSetDefinition {
    private static _optionSetDefinition: IPromiseCache<IODataResponse<IOptionSetDefinition>> = {};
    private static _globalOptionSetDefinition: IPromiseCache<OptionSet> = {};
    private static _twoOptionsDefinition: IPromiseCache<IODataResponse<ITwoOptionsDefinition>> = {};
    private static _globalTwoOptionsDefinition: IPromiseCache<GlobalOptionSet> = {};
    private static _multiSelectOptionSetDefinition: IPromiseCache<IODataResponse<IMultiSelectOptionSetDefinition>> = {};
    private static _globalMultiSelectOptionSetDefinition: IPromiseCache<MultiSelectOptionSet> = {};

    static async getAsync(entityName: string): Promise<IODataResponse<IOptionSetDefinition>> {
        return cachedWrapper(entityName, () => new Promise<IODataResponse<IOptionSetDefinition>>(async (resolve) => {
            const optionSetsDefinition = this._getOptionSetsAsync(entityName);
            const statusOptionSetsDefinition = this._getStatusOptionSetsAsync(entityName);
            const stateOptionSetsDefinition = this._getStateOptionSetsAsync(entityName);
            const multiselectOptionSetsDefinition = this.getMultiSelectOptionSetAsync(entityName);
            const twoOptionsOptionSetsDefinition = this.getTwoOptionsAsync(entityName);

            const [optionSets, statusOptionSets, stateOptionSets, multiselectOptionSets, twoOptionsOptionSets] = await Promise.all([optionSetsDefinition, statusOptionSetsDefinition, stateOptionSetsDefinition, multiselectOptionSetsDefinition, twoOptionsOptionSetsDefinition]);

            const twoOptionsTransformed = twoOptionsOptionSets.value.map((twoOptionsOptionSet) => {
                const transformed: IOptionSetDefinition = {
                    DefaultFormValue: twoOptionsOptionSet.DefaultValue ? 1 : 0,
                    LogicalName: twoOptionsOptionSet.LogicalName,
                    RequiredLevel: twoOptionsOptionSet.RequiredLevel,
                    OptionSet: {
                        IsGlobal: twoOptionsOptionSet.OptionSet.IsGlobal,
                        Description: twoOptionsOptionSet.OptionSet.Description,
                        DisplayName: twoOptionsOptionSet.OptionSet.DisplayName,
                        ExternalTypeName: twoOptionsOptionSet.OptionSet.ExternalTypeName,
                        IsCustomOptionSet: twoOptionsOptionSet.OptionSet.IsCustomOptionSet,
                        Name: twoOptionsOptionSet.OptionSet.Name,
                        OptionSetType: twoOptionsOptionSet.OptionSet.OptionSetType,
                        Options: [{
                            Color: twoOptionsOptionSet.OptionSet.TrueOption.Color,
                            Description: twoOptionsOptionSet.OptionSet.TrueOption.Description,
                            Label: twoOptionsOptionSet.OptionSet.TrueOption.Label,
                            Value: twoOptionsOptionSet.OptionSet.TrueOption.Value,
                            ExternalValue: twoOptionsOptionSet.OptionSet.TrueOption.ExternalValue,
                            ParentValues: twoOptionsOptionSet.OptionSet.TrueOption.ParentValues,
                        }, {
                            Color: twoOptionsOptionSet.OptionSet.FalseOption.Color,
                            Description: twoOptionsOptionSet.OptionSet.FalseOption.Description,
                            Label: twoOptionsOptionSet.OptionSet.FalseOption.Label,
                            Value: twoOptionsOptionSet.OptionSet.FalseOption.Value,
                            ExternalValue: twoOptionsOptionSet.OptionSet.FalseOption.ExternalValue,
                            ParentValues: twoOptionsOptionSet.OptionSet.FalseOption.ParentValues,
                        }]
                    },
                };
                return transformed;
            });

            resolve({
                value: [...optionSets.value, ...statusOptionSets.value, ...stateOptionSets.value, ...multiselectOptionSets.value, ...twoOptionsTransformed]
            });
        }), this._optionSetDefinition);
    }
    static async getTwoOptionsAsync(entityName: string): Promise<IODataResponse<ITwoOptionsDefinition>> {
        return cachedWrapper(entityName, () => this._getTwoOptionsAsync(entityName), this._twoOptionsDefinition);
    }
    static async getGlobalTwoOptionsAsync(optionsetName: string): Promise<GlobalOptionSet> {
        return cachedWrapper(optionsetName, () => this._getGlobalOptionSetsAsync(optionsetName) as unknown as Promise<GlobalOptionSet>, this._globalTwoOptionsDefinition);
    }
    static async getGlobalAsync(optionsetName: string): Promise<OptionSet> {
        return cachedWrapper(optionsetName, () => this._getGlobalOptionSetsAsync(optionsetName), this._globalOptionSetDefinition);
    }
    static async getGlobalMultiSelectOptionSetAsync(optionsetName: string): Promise<MultiSelectOptionSet> {
        return cachedWrapper(optionsetName, () => this._getGlobalMultiSelectOptionSetAsync(optionsetName), this._globalMultiSelectOptionSetDefinition);
    }
    static async getMultiSelectOptionSetAsync(entityName: string): Promise<IODataResponse<IMultiSelectOptionSetDefinition>> {
        return cachedWrapper(entityName, () => this._getMultiSelectOptionSetAsync(entityName), this._multiSelectOptionSetDefinition);
    }

    private static async _getTwoOptionsAsync(entityName: string): Promise<IODataResponse<ITwoOptionsDefinition>> {
        const response = await sendMetadataGetRequest(`v9.1/EntityDefinitions(LogicalName='${entityName}')/Attributes/Microsoft.Dynamics.CRM.BooleanAttributeMetadata?$select=LogicalName,RequiredLevel,DefaultValue&$expand=OptionSet,GlobalOptionSet`);
        if (!response.ok) {
            throw new Error(`Failed to fetch option sets for ${entityName} Entity!`);
        }

        const optionSetsDefinition: IODataResponse<ITwoOptionsDefinition> = await response.json();
        return optionSetsDefinition;
    }

    private static async _getOptionSetsAsync(entityName: string): Promise<IODataResponse<IOptionSetDefinition>> {
        const response = await sendMetadataGetRequest(`v9.1/EntityDefinitions(LogicalName='${entityName}')/Attributes/Microsoft.Dynamics.CRM.PicklistAttributeMetadata?$select=LogicalName,RequiredLevel&$expand=OptionSet,GlobalOptionSet`);
        if (!response.ok) {
            throw new Error(`Failed to fetch option sets for ${entityName} Entity!`);
        }

        const optionSetsDefinition: IODataResponse<IOptionSetDefinition> = await response.json();
        return optionSetsDefinition;
    }
    private static async _getStatusOptionSetsAsync(entityName: string): Promise<IODataResponse<IOptionSetDefinition>> {
        const response = await sendMetadataGetRequest(`v9.1/EntityDefinitions(LogicalName='${entityName}')/Attributes/Microsoft.Dynamics.CRM.StatusAttributeMetadata?$select=LogicalName,RequiredLevel&$expand=OptionSet,GlobalOptionSet`);
        if (!response.ok) {
            throw new Error(`Failed to fetch option sets for ${entityName} Entity!`);
        }

        const optionSetsDefinition: IODataResponse<IOptionSetDefinition> = await response.json();
        return optionSetsDefinition;
    }
    private static async _getStateOptionSetsAsync(entityName: string): Promise<IODataResponse<IOptionSetDefinition>> {
        const response = await sendMetadataGetRequest(`v9.1/EntityDefinitions(LogicalName='${entityName}')/Attributes/Microsoft.Dynamics.CRM.StateAttributeMetadata?$select=LogicalName,RequiredLevel&$expand=OptionSet,GlobalOptionSet`);
        if (!response.ok) {
            throw new Error(`Failed to fetch option sets for ${entityName} Entity!`);
        }

        const optionSetsDefinition: IODataResponse<IOptionSetDefinition> = await response.json();
        return optionSetsDefinition;
    }
    private static async _getGlobalOptionSetsAsync(optionsetName: string): Promise<OptionSet> {
        const response = await sendMetadataGetRequest(`v9.1/GlobalOptionSetDefinitions(Name='${optionsetName}')`);
        if (!response.ok) {
            throw new Error(`Failed to fetch global option set ${optionsetName}!`);
        }

        const optionSetsDefinition: OptionSet = await response.json();
        return optionSetsDefinition;
    }
    private static async _getMultiSelectOptionSetAsync(entityName: string): Promise<IODataResponse<IMultiSelectOptionSetDefinition>> {
        const response = await sendMetadataGetRequest(`v9.1/EntityDefinitions(LogicalName='${entityName}')/Attributes/Microsoft.Dynamics.CRM.MultiSelectPicklistAttributeMetadata?$select=LogicalName,RequiredLevel&$expand=OptionSet,GlobalOptionSet`);
        if (!response.ok) {
            throw new Error(`Failed to fetch option sets for ${entityName} Entity!`);
        }

        const multiSelectOptionSetsDefinition: IODataResponse<IMultiSelectOptionSetDefinition> = await response.json();
        return multiSelectOptionSetsDefinition;
    }
    private static async _getGlobalMultiSelectOptionSetAsync(optionsetName: string): Promise<MultiSelectOptionSet> {
        const response = await sendMetadataGetRequest(`v9.1/GlobalOptionSetDefinitions(Name='${optionsetName}')`);
        if (!response.ok) {
            throw new Error(`Failed to fetch global option set ${optionsetName}!`);
        }

        const optionSetsDefinition: MultiSelectOptionSet = await response.json();
        return optionSetsDefinition;
    }
}