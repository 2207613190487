import { IBasePicker, IBasePickerSuggestionsProps, IButtonStyles, IconButton, IOverflowSetItemProps, ITag, Link, mergeStyles, OverflowSet, useTheme } from "@fluentui/react";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { CommandBarButton } from '@fluentui/react';
import { IItemProps, TagPicker } from "@talxis/react-components/dist/components/TagPicker";
import './styles.css';
import { EntityDefinition } from "@definitions/EntityDefinition";
import { LocalizeLabel } from "@localization/helpers";

export interface IMultiselectProps {
    readOnly: boolean;
    disabled?: boolean;
    borderless: boolean;
    placeholder: string;
    value: IMultiselectItem[];
    isInlineNewEnabled?: boolean;
    multipleEnabled?: boolean;
    targets: string[];
    onChange: (value: IMultiselectItem[]) => Promise<void>;
    onSearch: (entityName: string, searchText: string) => Promise<IMultiselectItem[]>;
    onCreateRecord: (entityName: string) => Promise<IMultiselectItem> | null;
    getTranslation: (value: string) => string;
}
export interface IMultiselectItem extends IItemProps {
    id: string;
    entityType: string;
}

export const Multiselect: React.FC<IMultiselectProps> = (props: IMultiselectProps) => {
    const [values, setValues] = useState<IMultiselectItem[] | undefined>();
    const [placeholder, setPlaceholder] = useState<string>("---");
    const [entityName, setEntityName] = useState<string>(props.targets[0]);
    const [targets, setTargets] = useState<string[]>(props.targets);
    const ref = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const pickerRef = useRef<IBasePicker<ITag>>(null);
    const inputValueRef = useRef<string>("");
    const [entityNameMap, setEntityNameMap] = useState<{ [entityName: string]: string }>({});

    useEffect(() => {
        if (values != null) {
            props.onChange(values);
        }
    }, [values]);

    useEffect(() => {
        // TODO: We should detect object changes properly
        if (props.value != null && (JSON.stringify(props.value) != JSON.stringify(values))) {
            setValues(props.value);
        }
        else if (props.value == null && props.value != values) {
            setValues([]);
        }
    }, [props.value]);

    useEffect(() => {
        let mounted = true;
        (async () => {
            const entityNameMap: { [entityName: string]: string } = {};
            if (targets.length > 1) {
                for (const target of targets) {
                    const entityDefinition = await EntityDefinition.getAsync(target);
                    entityNameMap[target] = LocalizeLabel(entityDefinition.DisplayName.LocalizedLabels);
                }
            }
            if (mounted) {
                setEntityNameMap(entityNameMap);
            }
        })();

        return () => { mounted = false; };
    }, []);

    const _handleAsync = async (): Promise<void> => {
        let result = await props.onCreateRecord(entityName);
        if (result) {
            setValues([result]);
        }
    };
    const renderNewButton = () => {
        if (props.isInlineNewEnabled) {
            return <CommandBarButton
                iconProps={{ iconName: 'Add' }}
                className={mergeStyles({
                    borderBottom: targets.length > 1 && `1px solid ${theme.semanticColors.bodyDivider}`
                })}
                text={props.getTranslation("newRecord")} onClick={_handleAsync} />;
        }
        return undefined;
    };
    const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
        return (
            <Link
                key={item.key}
                onClick={() => { onTargetSelected(item.key); }}
                style={{
                    fontWeight: entityName === item.key ? "bold" : null,
                    pointerEvents: "auto"
                }}>{entityNameMap[item.key] ?? item.key}</Link>
        );
    };

    const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
        const buttonStyles: Partial<IButtonStyles> = {
            root: {
                minWidth: 0,
                padding: '0 4px',
                alignSelf: 'stretch',
                height: 'auto',
            },
        };
        return (
            <IconButton
                styles={buttonStyles}
                menuIconProps={{ iconName: 'More' }}
                menuProps={{ items: overflowItems! }}
            />
        );
    };

    const onTargetSelected = (target: string, setAsFirstElement: boolean = false) => {
        (async () => {
            pickerRef.current.focusInput();
            setEntityName(target);
            if (setAsFirstElement) {
                setTargets(targets.sort(function (a, b) { return a == target ? -1 : b == target ? 1 : 0; }));
            }
            //@ts-ignore - We need to use internal methods to show and fill the suggestions on entity change
            pickerRef.current.suggestionStore.updateSuggestions([]);
            //@ts-ignore - ^^same as above
            pickerRef.current.setState({
                suggestionsVisible: true,
                suggestionsLoading: true,
            });
            //@ts-ignore - ^^same as above
            const results = await props.onSearch(target, pickerRef.current.input.current.value);
            //@ts-ignore - ^^same as above
            pickerRef.current.updateSuggestionsList(results);
            //@ts-ignore - ^^same above
            pickerRef.current.setState({
                isMostRecentlyUsedVisible: false,
                suggestionsVisible: true,
                moreSuggestionsAvailable: false,
            });
        })();
    };

    const pickerSuggestionProps: IBasePickerSuggestionsProps<any> = {
        noResultsFoundText: props.getTranslation("noRecordsFound"),
        className: `TALXIS__lookup__suggestions`,
        loadingText: props.getTranslation("searching"),
        //resultsFooterFull: renderNewButton,
        //resultsFooter: renderNewButton,
        //@ts-ignore - property only accepts string, but current fluent ui implementation allows custom elements => https://github.com/microsoft/fluentui/blob/4eec618fe480b129e08914b1f091a8509af60379/packages/react-experiments/src/components/FloatingSuggestionsComposite/FloatingSuggestionsList/FloatingSuggestionsList.tsx#L67
        suggestionsHeaderText:
            <>
                {renderNewButton()}
                {targets.length > 1 && <div>
                    <span style={{ paddingLeft: 5 }}>{props.getTranslation("resultsFrom")} </span>
                    <OverflowSet
                        styles={{
                            root: {
                                display: "inline-block",
                            },
                            item: {
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: 5,
                                paddingRight: 5
                            }
                        }}
                        items={targets.slice(0, 3).map(x => {
                            return {
                                key: x,
                                name: x
                            };
                        })}
                        overflowItems={targets.slice(3, targets.length).map(x => {
                            return {
                                key: x,
                                name: entityNameMap[x] ?? x,
                                onClick: () => { onTargetSelected(x, true); }
                            };
                        })}
                        onRenderOverflowButton={onRenderOverflowButton}
                        onRenderItem={onRenderItem}
                    />
                </div>}
            </>
    };

    return (
        <div ref={ref} className='TALXIS__lookup'>
            <TagPicker
                componentRef={pickerRef}
                stackItems
                borderless={props.borderless}
                transparent
                searchBtnProps={{
                    showOnlyOnHover: true,
                    iconProps: {
                        iconName: 'Search'
                    }
                }}
                disabled={props.disabled}
                readOnly={props.readOnly}
                removeButtonAriaLabel="Remove"
                resolveDelay={500}
                onResolveSuggestions={async (filterText: string, selectedItems?: IItemProps[]): Promise<IItemProps[]> => {
                    const results = await props.onSearch(entityName, filterText);
                    inputValueRef.current = filterText;
                    return results.filter(x => !values?.some(y => y.key === x.key));
                }}
                selectedItems={values}
                onItemSelected={(selectedItem?: IItemProps | undefined): IItemProps | PromiseLike<IItemProps> | null => {
                    if (selectedItem && values?.find(x => x.key === selectedItem.key) === undefined) {
                        if (!selectedItem.text) {
                            selectedItem.text = props.getTranslation("noName");
                        }
                        return selectedItem;
                    }
                    return null;
                }}
                onEmptyResolveSuggestions={(selectedItems: ITag[]): ITag[] | Promise<ITag[]> => {
                    return props.onSearch(entityName, "") as Promise<ITag[]>;
                }}
                onChange={(selectedItems?: IItemProps[]): void => {
                    if (selectedItems) {
                        setValues(selectedItems as IMultiselectItem[]);
                    }
                }}
                getTextFromItem={(item: ITag) => {
                    return item.name;
                }}
                pickerSuggestionsProps={pickerSuggestionProps}
                itemLimit={!props.multipleEnabled ? 1 : 10}
                inputProps={{
                    placeholder: placeholder,
                    onMouseEnter: () => {
                        setPlaceholder(props.placeholder);
                    },
                    onMouseLeave: () => {
                        setPlaceholder("---");
                    }
                }}
            />
        </div>
    );
};