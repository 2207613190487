import { Exception } from "../exceptions/Exception";
import { MultitenantProvider } from "../MultitenantProvider";

export enum NavigationType {
    horizontal,
    vertical
}

export enum ThemeType {
    light,
    dark
}
export interface ISpaConfiguration {
    authority: string;
    b2cLoginPolicy: string;
    b2cProfileEditPolicy: string;
    b2cSignupPolicy?: string;
    b2cPasswordChangePolicy: string;
    footerText?: string;
    clientId: string;
    edsApi: string;
    metadataApi: string;
    securityTokenServiceApi: string;
    instanceUrl: string;
    instanceTenant: string;
    instrumentationKey: string;
    themeType: ThemeType;
    navigationType: NavigationType;
    apiScopes: string[];
    defaultAppModule: string;
    webResourceHash: string;
    metadataVersion: string;
    talxisMetadataVersion: string;
}
export class SpaConfiguration {
    private static _spaConfiguration: ISpaConfiguration;

    static get(): ISpaConfiguration {
        if (!this._spaConfiguration) {
            throw new Error('Application confuguration not loaded! Did you forget to call loadAsync? :)');
        }
        return this._spaConfiguration;
    }
    static async loadAsync(): Promise<void> {
        if (this._spaConfiguration === undefined) {
            let metadataConfigurationUrl = process.env.REACT_APP_CONFIGURATION || (localStorage.getItem('METADATA_HOST_OVERRIDE') ? localStorage.getItem('METADATA_HOST_OVERRIDE') + '/api/portal/configuration.json' : null) || '/configuration.json';

            try {
                const response = await fetch(metadataConfigurationUrl, MultitenantProvider.getFetchHeader());
                this._spaConfiguration = await response.json();
                if (!this._spaConfiguration.edsApi) {
                    throw new Exception("Portal configuration could not be fetched.", new Exception(JSON.stringify(this._spaConfiguration)));
                }
                if (localStorage.getItem('BACKEND_HOST_OVERRIDE')) this._spaConfiguration.edsApi = localStorage.getItem('BACKEND_HOST_OVERRIDE');
                if (localStorage.getItem('STS_HOST_OVERRIDE')) this._spaConfiguration.securityTokenServiceApi = localStorage.getItem('STS_HOST_OVERRIDE');

                // Local development overrides
                if (window.location.port === "3000") {
                    if (this._spaConfiguration.metadataApi.startsWith("/")) {
                        if (localStorage.getItem('METADATA_HOST_OVERRIDE')) {
                            this._spaConfiguration.metadataApi = `${localStorage.getItem('METADATA_HOST_OVERRIDE')}/api/metadata`;
                        }
                        else {
                            this._spaConfiguration.metadataApi = "https://fast.metadata.portals.talxis.com/api/metadata";
                        }
                    }
                    if (this._spaConfiguration.edsApi.startsWith("/")) this._spaConfiguration.edsApi = "https://fast.api.portals.talxis.com/api/data";
                }
            }
            catch (error) {
                // Only network errors come here
                throw new Exception("Network error - failed to reach the configuration file from the metadata API " + metadataConfigurationUrl, error as Exception);
            }

            try {
                const backendHealthResponse = await fetch(this._spaConfiguration.edsApi + '/v9.1/health', MultitenantProvider.getFetchHeader());

                if (!backendHealthResponse.ok) {
                    var errorDetails = (backendHealthResponse) ? (await backendHealthResponse.json())?.message : "";
                    throw new Error('Backend API error! ' + errorDetails);
                }

                // if there is a relative backend url provided response is not JSON
                var responseContent = await backendHealthResponse.json();
            }
            catch (error) {
                // Only network errors come here
                throw new Exception("Network error - failed to reach the backend API " + this._spaConfiguration.edsApi + '/v9.1/health', error as Exception);
            }
        }
    };
}