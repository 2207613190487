import { ScriptLoader } from "./ScriptLoader";

export const loadReact = async () => {
    // This has to be loaded in proper order
    await ScriptLoader.loadAsync("react_17", "/static/libraries/react_17.0.2.js");
    await ScriptLoader.loadAsync("react-dom_17", "/static/libraries/react-dom_17.0.2.js");
};
export const loadFluentUiV8 = async () => {
    await ScriptLoader.loadAsync("fluentui_v8", "/static/libraries/fluentui-react_8.29.0.js");
    // @ts-ignore - Fluent V8 only populates window.FluentUIReact, but we need it also in FluentUIReactv8290 for compatibility with PCF
    window.FluentUIReactv8290 = window.FluentUIReact;
};
export const loadFluentUiV9 = async () => {
    throw new Error("FluentUI V9 is not supported yet!");

    await ScriptLoader.loadAsync("fluentui_v9", "/static/libraries/fluentui-react_9.19.1.js");
    // @ts-ignore - Fluent V9 only populates window.FluentUIReactV9, but we need it also in FluentUIReactv940 for compatibility with PCF
    window.FluentUIReactv940 = window.FluentUIReactV9;
};