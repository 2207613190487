import * as React from 'react';
import { useState } from 'react';
import { ITextFieldItemProps, TextField } from '@talxis/react-components/dist/components/TextField';
import { IDecimalProps } from '../interfaces';
import { useEffect } from 'react';

export const DecimalField: React.FunctionComponent<IDecimalProps> = (props) => {
    const [value, setValue] = useState<string>(props.value);
    const [errorMessage, setErrorMessage] = useState<string>(null);

    useEffect(() => {
        if (props.value && props.value !== value) {
            setValue(props.value);
        }
    }, [props.value]);

    const onChange = (e: any, value: string) => {
        setValue(value);
    };
    const onBlur = () => {
        let returnedValue = props.onBlurEvent(value);
        if (returnedValue !== undefined) {
            setValue(returnedValue);
            setErrorMessage(null);
        } else {
            setErrorMessage(props.getTranslatedString("incorrectNumberFormatError", { groupSeparator: props.groupSeparator, decimalSeparator: props.decimalSeparator }));
        }
    };
    const onClickDelete = () => {
        setValue(null);
        props.deleteButtonProps.onClick();
    };

    const getDeleteButtonProps = (): ITextFieldItemProps => {
        if (props.deleteButtonProps)
            return {
                key: props.deleteButtonProps.key ?? 'clickToDelete',
                onClick: onClickDelete,
                iconProps: props.deleteButtonProps.iconProps ?? { iconName: 'Cancel' },
                showOnlyOnInputHover: true
            };
        return null;
    };

    return (
        <TextField
            value={value}
            readOnly={props.readOnly}
            disabled={props.disabled}
            type={'text'}
            borderless={props.borderless}
            prefixItems={props.prefixItems}
            suffixItems={props.suffixItems}
            onChange={onChange}
            onBlur={onBlur}
            deleteButtonProps={getDeleteButtonProps()}
            clickToCopyProps={props.clickToCopyProps ? props.clickToCopyProps : null}
            errorMessage={errorMessage}
        />
    );
};
export default DecimalField;