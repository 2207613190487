import React, { useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import Home from '@pages/Home';
import { ControlPage } from '@pages/Control';
import { IAppContext } from '@providers/AppProvider/interfaces';
import { AppContext } from '@providers/AppProvider';
import Layout from '@pages/Layout';
import { updateGlobalContext } from './Functions';

const App: React.FC = () => {
  const appContext: IAppContext = useContext(AppContext);
  useEffect(() => {
    updateGlobalContext(appContext);
  }, [appContext]);
  return (
    <Layout>
      <Switch>
        <Route path="/:app/control/:controlName" component={ControlPage} />
        <Route path="/:app?/" component={Home} />
      </Switch>
    </Layout>
  );
};
export default App;
