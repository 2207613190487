import { Dialog, DialogType, DocumentCard, DocumentCardDetails, DocumentCardImage, DocumentCardTitle, ICommandBarItemProps, ImageFit } from "@fluentui/react";
import { CommandBar } from "@talxis/react-components/dist/components/CommandBar";
import React, { useEffect, useState } from "react";
import { Authentication } from "@app/classes/Authentication";
import { AppModule } from "@configuration/AppModule";
import styles from './AppSwitcherDialog.module.css';

interface IAppSwitcherDialogProps {
    appSwitcherDialogVisible: boolean;
    setAppSwitcherDialogVisible: (value: boolean) => void;
    onlyAllowedApps?: boolean | null;
}

export const AppSwitcherDialog: React.FC<IAppSwitcherDialogProps> = ({ appSwitcherDialogVisible, setAppSwitcherDialogVisible, onlyAllowedApps }) => {
    const [appModules, setAppModules] = useState<AppModule[]>([]);
    onlyAllowedApps = onlyAllowedApps == false ? false : true;
    const getDialogClassName = () => {
        const className = 'TALXIS__dialog--app-switcher';
        return `${className} ${styles.root}`;
    };
    const loadIcons = async () => {
        const appModules = await AppModule.getAllAppModules(onlyAllowedApps);
        const iconPromises: Promise<void>[] = [];
        for (const appModule of appModules) {
            iconPromises.push(appModule.loadIcon());
        }
        await Promise.all(iconPromises);
    };
    const init = async () => {
        const _appModules = await AppModule.getAllAppModules(onlyAllowedApps);
        setAppModules([..._appModules]);
        await loadIcons();
        //set state again to populate the cards with icons
        setAppModules([..._appModules]);
    };

    const getComamndBarFarItems = () => {
        const items: ICommandBarItemProps[] = [];
        if (!Authentication.isAuthenticated()) {
            items.push({
                key: 'signin',
                text: window.TALXIS.Portal.Translations.getLocalizedString("components/dialogs/AppSwitcherDialog/SignIn"),
                iconProps: {
                    iconName: 'SignIn'
                },
                onClick: (ev) => {
                    ev.preventDefault();
                    Authentication.loginAsync();
                },
                href: '#',
            });
            return items;
        }
        items.push({
            key: 'user',
            text: window.TALXIS.Portal.User.getDisplayName(),
            className: styles.userButton,
            onClick: (e) => {
                e.preventDefault();
            },
            href: '#',
            iconProps: {
                iconName: 'User'
            },
        });
        items.push({
            key: 'signout',
            text: window.TALXIS.Portal.Translations.getLocalizedString("components/dialogs/AppSwitcherDialog/SignOut"),
            iconProps: {
                iconName: 'SignOut'
            },
            onClick: (ev) => {
                ev.preventDefault();
                Authentication.logout();
            },
            href: '#'
        });
        return items;
    };
    useEffect(() => {
        init();
    }, []);

    return (
        <>
            {appModules.length > 0 && appSwitcherDialogVisible &&
                <Dialog modalProps={{ className: getDialogClassName() }} dialogContentProps={{
                    title: window.TALXIS.Portal.Translations.getLocalizedString("components/dialogs/AppSwitcherDialog/Title"),
                    onDismiss: () => setAppSwitcherDialogVisible(false),
                    topButtonsProps: [
                        {
                            className: styles.topButtons,
                            onRenderIcon: () => <CommandBar items={[]} farItems={getComamndBarFarItems()} />,
                        }
                    ],
                    type: AppModule.get() ? DialogType.close : DialogType.normal
                }}
                    hidden={false}>
                    <div className={styles.cards}>
                        {appModules.map((appModule, i) => {
                            return (
                                <DocumentCard
                                    key={i}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (appModule.uniquename != AppModule.get()?.uniquename) {
                                            localStorage.setItem('appModuleName', appModule.uniquename);
                                            window.location.href = `/${appModule.uniquename}`;
                                        }
                                        else {
                                            setAppSwitcherDialogVisible(false);
                                        }
                                    }}
                                    onClickHref="https://google.com">
                                    <DocumentCardImage height={150} imageFit={ImageFit.cover} iconProps={{
                                        imageProps: {
                                            src: appModule.icon?.value
                                        }
                                    }} />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle title={appModule.name} />
                                        <DocumentCardTitle title={appModule.description} shouldTruncate showAsSecondaryTitle />
                                    </DocumentCardDetails>
                                </DocumentCard>
                            );
                        })}
                    </div>
                </Dialog>
            }
        </>
    );
};