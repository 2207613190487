import { EntityDefinition } from "@definitions/EntityDefinition";
import { ICustomControl } from "@controls/interfaces/customcontrol";
import { IControlEventHandler } from "@controls/interfaces";
import { NestedPcfFactory, IVirtualComponentProps, IComponent } from "./interfaces/NestedPcf";
import ReactDOM from "react-dom";
import { NestedPcfWrapper } from "./NestedPcfWrapper";
import React from "react";
import { GetEntityMetadataResponse } from "../Xrm/GetEntityMetadataResponse";
import { IPromiseCache } from "@utilities/MemoryCachingHelpers";
import { metadataRetrieveMultiple } from "@definitions/MetadataApi";
import { OptionSetDefinition } from "@definitions/OptionSetDefinition";
import { IFileObject } from "@src/components/controls/interfaces/IFileObject";

export class Client implements ComponentFramework.Client {
    disableScroll: boolean;
    getFormFactor(): number {
        const width = window.innerWidth;
        if (width < 576) {
            return 3;
        }
        else if (width < 768) {
            return 2;
        }
        else {
            return 1;
        }
    }
    getClient(): string {
        return Xrm.Utility.getGlobalContext().client.getClient();
    }
    isOffline(): boolean {
        throw new Error("Method not implemented.");
    }
}

export class Device implements ComponentFramework.Device {
    captureAudio(): Promise<ComponentFramework.FileObject> {
        throw new Error("Method not implemented.");
    }
    captureImage(options?: ComponentFramework.DeviceApi.CaptureImageOptions): Promise<ComponentFramework.FileObject> {
        throw new Error("Method not implemented.");
    }
    captureVideo(): Promise<ComponentFramework.FileObject> {
        throw new Error("Method not implemented.");
    }
    getBarcodeValue(): Promise<string> {
        throw new Error("Method not implemented.");
    }
    getCurrentPosition(): Promise<ComponentFramework.DeviceApi.Position> {
        throw new Error("Method not implemented.");
    }
    pickFile(options?: ComponentFramework.DeviceApi.PickFileOptions): Promise<ComponentFramework.FileObject[]> {
        throw new Error("Method not implemented.");
    }
}

class Component implements IComponent {
    private _type: string;
    private _componentId: string;
    private _properties: IVirtualComponentProps;

    constructor(type: string, componentId: string, properties: IVirtualComponentProps) {
        this._type = type;
        this._componentId = componentId;
        this._properties = properties;
    }
    getType(): string {
        return this._type;
    }
    getComponentId(): string {
        return this._componentId;
    }
    getProperties(): IVirtualComponentProps {
        return this._properties;
    }
}

export class Factory implements NestedPcfFactory {
    private _childEventListeners: IControlEventHandler[] = [];
    private static _mountedComponents: { [id: string]: Element } = {};
    private _render: () => void;
    private _pageId: string;

    constructor(childEventListeners: IControlEventHandler[], render: () => void, pageId?: string) {
        this._render = render;
        if (childEventListeners) this._childEventListeners = [...childEventListeners];
        this._pageId = pageId;
    }
    getPopupService(): ComponentFramework.FactoryApi.Popup.PopupService {
        throw new Error("Method not implemented.");
    }
    requestRender(): void {
        this._render();
    }
    fireEvent(eventName: string, params: ComponentFramework.Dictionary): void {
        const event = this._childEventListeners?.find(x => x.eventname === eventName);
        if (event) {
            event.eventhandler(params);
        }
        else {
            console.warn(`Unable to find event listener: ${eventName}`);
        }
    }
    createComponent(type: string, id: string, properties: IVirtualComponentProps): IComponent {
        return new Component(type, id, properties);
    }
    bindDOMElement(virtualComponent: IComponent, DOMNode: Element): void {
        Factory._mountedComponents[virtualComponent.getComponentId()] = DOMNode;
        ReactDOM.render(React.createElement(
            NestedPcfWrapper,
            {
                component: virtualComponent,
                pageId: this._pageId
            }
        ), DOMNode);
    }
    // Shares the same implementation as bindDOMElement
    bindDOMComponent(virtualComponent: IComponent, DOMNode: Element): void {
        return this.bindDOMElement(virtualComponent, DOMNode);
    }
    unbindDOMComponent(componentId: string): boolean {
        if (Factory._mountedComponents[componentId]) {
            try {
                return ReactDOM.unmountComponentAtNode(Factory._mountedComponents[componentId]);
            }
            catch (err) {
                console.warn("Error when calling unbindDOMComponent!", err);
                return false;
            }
        }
        else {
            return false;
        }
    }
    updateComponent(id: string, props: ComponentFramework.Dictionary): void {
        console.warn(`PCF: Call to unimplemented method factory.updateComponent!`, id);
    }
    createFileObject(file: File): IFileObject {
        return {
            fileName: file.name,
            mimeType: file.type,
            fileSize: file.size,
            _file: file,
        };
    }
}

export class Resources implements ComponentFramework.Resources {
    private _controlDefiniton: ICustomControl;
    private _controlName: string;
    private static _resourceCache: IPromiseCache<string> = {};

    constructor(controlName: string, controlDefinition: ICustomControl) {
        this._controlDefiniton = controlDefinition;
        this._controlName = controlName;
    }

    private async _getResourceAsync(id: string): Promise<string> {
        //case for native controls images 
        if (this._controlDefiniton.img && this._controlDefiniton.img[id]) {
            return this._controlDefiniton.img[id];
        }
        const resources = this._controlDefiniton.manifest.resources;
        let path: string;
        for (const [key, resource] of Object.entries(resources)) {
            if (Array.isArray(resource)) {
                path = resource.find(prop => prop.path.endsWith(id))?.path;
            }
            else if (resource.path.endsWith(id)) {
                path = resource.path;
            }
            if (path) {
                break;
            }
        }
        const result = await metadataRetrieveMultiple(`v9.1/webresourceset?$select=content&$filter=endswith(name, 'cc_${this._controlName}/${path}')`);
        if (result.entities.length === 1) {
            return result.entities[0]["content"];
        }
        else {
            throw new Error(`Found ${result.entities.length} results for resource: ${id}`);
        }
    }

    getResource(id: string, success: (data: string) => void, failure: () => void): void {
        const executeAsync = async (): Promise<void> => {
            if (!Resources._resourceCache[`${this._controlName}/${id}`]) {
                Resources._resourceCache[`${this._controlName}/${id}`] = this._getResourceAsync(id);
            }

            try {
                const result = await Resources._resourceCache[`${this._controlName}/${id}`];
                success(result);
            }
            catch (err) {
                failure();
            }
        };
        executeAsync();
    }
    getString(id: string): string {
        return this._controlDefiniton.resx?.[id] ?? id;
    }
}

export class Utility implements ComponentFramework.Utility {
    private _customControlProperties: {
        id: string;
    }
    constructor(pageId?: string) {
        this._customControlProperties = {
            id: pageId
        };
    }

    async getEntityMetadata(entityName: string, attributes?: string[]): Promise<ComponentFramework.PropertyHelper.EntityMetadata> {
        return new GetEntityMetadataResponse(await EntityDefinition.getAsync(entityName), attributes, await OptionSetDefinition.getAsync(entityName));
    }
    hasEntityPrivilege(entityTypeName: string, privilegeType: ComponentFramework.PropertyHelper.Types.PrivilegeType, privilegeDepth: ComponentFramework.PropertyHelper.Types.PrivilegeDepth): boolean {
        throw new Error("Method not implemented.");
    }
    lookupObjects(lookupOptions: ComponentFramework.UtilityApi.LookupOptions): Promise<ComponentFramework.LookupValue[]> {
        return new Promise((resolve) => {
            if (lookupOptions.defaultEntityType || lookupOptions.viewIds) {
                console.error("Unsuported lookup options detected. Only allowMultiSelect, defaultViewId and entityTypes are currently supported.");
            }
            window.TALXIS.Portal.Context.setLookupPanelProps({
                isOpen: true,
                ...lookupOptions,
                onSuccessCallback: (result) => {
                    resolve(result);
                }
            });
        });
    }
}