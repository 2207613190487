import { DataProvider } from "./DataProvider/DataProvider";
import { GridContext } from "./GridContext";
import { cloneDeep } from "lodash";
import { ViewSelector } from "./ViewSelector/ViewSelector";
import { ControlLoader } from "@src/app/classes/loaders/ControlLoader";
import { Context } from "@src/ComponentFramework/PropertyClasses/Context";
import { ICustomControl } from "../interfaces/customcontrol";
import { State } from "@src/providers/HistoryProvider/State";
import { IDatasetDataProvider } from "./DataProvider/IDatasetDataProvider";
import { QuickFind } from "./QuickFind/QuickFind";
import { IViewDefinition } from "../native/View/interfaces/viewdefinition";
import { IFormContext } from "../native/Form/interfaces/IFormContext";
import { IExtendedXrmGridControl, IGridNativeStateValues, IDatasetControlProps, ICdsOptions, IInternalDatasetControlProps, IXrmGridRelationship, XrmGridType } from "./interfaces";
import { DatasetRibbon } from "@src/app/classes/models/Ribbon/DatasetRibbon";
import { HistoryManager } from "@src/providers/HistoryProvider/HistoryManager";

const DEFAULT_PAGE_SIZE_SUBGRID = 10;
const DEFAULT_PAGE_SIZE_GRID = 50;

export class Grid {
    private _datasetProvider: IDatasetDataProvider;
    private _internalDatasetProvider: DataProvider;
    private _gridContext: IExtendedXrmGridControl;
    private _controlStateRef: React.MutableRefObject<State>;
    private _nativeStateValuesRef: React.MutableRefObject<IGridNativeStateValues>;
    private _formContext: IFormContext;
    private _controlProps: IDatasetControlProps;
    private _viewSelector: ViewSelector;
    private _quickFind: QuickFind;
    private _ribbon: DatasetRibbon;
    private _control: ICustomControl;
    private _cdsOptions: ICdsOptions | null;
    private _controlInstance: ComponentFramework.StandardControl<any, any>
    private _container: HTMLDivElement;
    private _mounted: boolean = true;
    private _setDefaultStateValues: (values: IGridNativeStateValues) => void;
    private _initialized: boolean = false;

    constructor(
        controlStateRef: React.MutableRefObject<State>,
        nativeStateValuesRef: React.MutableRefObject<IGridNativeStateValues>,
        formContext: IFormContext,
        controlProps: IInternalDatasetControlProps,
        container: HTMLDivElement,
        setDefaultStateValues: (values: IGridNativeStateValues) => void,
    ) {
        this._container = container;
        this._formContext = formContext;
        this._controlProps = controlProps;
        this._controlStateRef = controlStateRef;
        this._nativeStateValuesRef = nativeStateValuesRef;
        this._setDefaultStateValues = setDefaultStateValues;
        this._gridContext = new GridContext(this);
        this._ribbon = new DatasetRibbon(this);
        this._viewSelector = new ViewSelector(this);
        this._quickFind = new QuickFind(this);
    }

    public async init(): Promise<boolean> {
        if (this.isRelatedSubgridOnNewRecord) {
            return false;
        }
        await this._initializeControlInstance();
        this._cdsOptions = this._getCdsOptions();
        if (this.bindings[this.datasetBindingName]?.TargetEntityType || this.bindings.TargetEntityType) {
            //create internal provider if entityName has been specified
            this._internalDatasetProvider = new DataProvider(this, this._nativeStateValuesRef);
        }
        this._datasetProvider = this.bindings[this.datasetBindingName]?.DataProvider ?? this._internalDatasetProvider;
        await this._internalDatasetProvider?.init((viewDefinition, fetchXml) => this._initDefaultStateValues(viewDefinition, fetchXml));
        //state is initialized in internal data provider since we need viewDefinition
        //therefore, in cases with no internal provider, we need to explicitly initialize it here
        if (this.isFullyVirtual) {
            this._initDefaultStateValues();
        }
        //make stored filters and sorting available on init
        this._datasetProvider.setFiltering(this._nativeStateValuesRef.current.userFilterExpression);
        this._datasetProvider.setSorting(this._nativeStateValuesRef.current.userSorting);
        if (this.isFullyVirtual || this.isHybridVirtual) {
            //fire loadExactPage first to load page stored in state
            if (this._nativeStateValuesRef.current.pageNumber !== 1) {
                await this._datasetProvider.getPaging().loadExactPage(this._nativeStateValuesRef.current.pageNumber);
            }
            else {
                await this._datasetProvider.refresh();
            }
        }
        this.formContext?.registerChildControl(this._controlProps.id, {
            gridRefresh: () => this.refresh()
        });
        //make refreshing of the homepage grid avaliable
        if (this.isHomepageGrid) {
            Xrm.Page = {
                data: {
                    //@ts-ignore - not implementing promise like
                    refresh: () => {
                        this.refresh();
                    }
                }
            };
        }
        HistoryManager.activeControls.add(this._controlInstance);
        //grid initialized, we do not care if the PCF has been rendered yet or not
        this._initialized = true;
        this.render(true);
        return true;
    }
    public get isInitialized() {
        return this._initialized;
    }
    public get isRelatedSubgridOnNewRecord() {
        if (this._formContext && !this.formContext.entityId && this.bindings.RelationshipName) {
            return true;
        }
        return false;
    }
    public get isFullyVirtual() {
        if (!this._internalDatasetProvider) {
            return true;
        }
        return false;
    }
    public get isHybridVirtual() {
        if (this._internalDatasetProvider && this._internalDatasetProvider !== this._datasetProvider) {
            return true;
        }
        return false;
    }
    public get entityName() {
        return this._internalDatasetProvider?.entityName;
    }
    /**
    *   Initial FetchXml as it came from API (with relationship applied).
    */
    public get initialFetchXml() {
        return this._internalDatasetProvider.initialFetchXml;
    }
    /**
    * FetchXml with applied filters from QueryBuilder
    */
    public get fetchXml() {
        //todo error handling for virtual grids where this might not be present
        return this._internalDatasetProvider?.fetchXml;
    }
    public set fetchXml(value: string) {
        this._internalDatasetProvider.fetchXml = value;
    }
    /**
    * Takes the FetchXml from view definition and applies all user defines filters, sorting and attributes.
    */
    public buildFetchXml(): string {
        return this._internalDatasetProvider.buildFetchXml();
    }
    public get viewId() {
        return this._internalDatasetProvider?.viewId;
    }
    public get viewSelector() {
        return this._viewSelector;
    }
    public get quickFind() {
        return this._quickFind;
    }
    public get ribbon() {
        return this._ribbon;
    }
    public get cdsOptions() {
        return this._cdsOptions;
    }
    public get control() {
        return this._control;
    }
    public get relationship(): IXrmGridRelationship | undefined {
        return this._internalDatasetProvider?.relationship;
    }
    public get formContext() {
        return this._formContext;
    }
    //TODO: possibly merge the provided and internal columns?
    public get columns() {
        if (this._internalDatasetProvider) {
            return this._internalDatasetProvider.getColumns();
        }
        return this._datasetProvider.getColumns();
    }
    public get quickFindColumnNames() {
        return this._internalDatasetProvider?.quickFindColumnNames;
    }
    public async addColumn(name: string) {
        return this._internalDatasetProvider.addColumn(name);
    }
    public async removeColumn(name: string) {
        return this._internalDatasetProvider.removeColumn(name);
    }
    public updateColumnOrder(columnOrder: string[]) {
        return this._internalDatasetProvider.updateColumnOrder(columnOrder);
    }
    public get title() {
        if (this.isHybridVirtual) {
            return this._internalDatasetProvider.getTitle();
        }
        return this._datasetProvider.getTitle();
    }
    public get paging(): ComponentFramework.PropertyHelper.DataSetApi.Paging {
        return {
            ...this._datasetProvider.getPaging(),
            loadExactPage: (pageNumber) => {
                if (!this.isFullyVirtual && !this.isHybridVirtual) {
                    //@ts-ignore - types
                    //polyfill for loadExactPage
                    if (pageNumber > this._datasetProvider.getPaging().pageNumber) {
                        this._renderDecorator(this._datasetProvider.getPaging().loadNextPage as any);
                    }
                    else {
                        this._renderDecorator(this._datasetProvider.getPaging().loadPreviousPage as any);
                    }
                }
                this._renderDecorator(this._datasetProvider.getPaging().loadExactPage as any);
            },
            reset: () => this._renderDecorator(this._datasetProvider.getPaging().reset as any),
            loadNextPage: () => this._renderDecorator(this._datasetProvider.getPaging().loadNextPage as any),
            loadPreviousPage: () => this._renderDecorator(this._datasetProvider.getPaging().loadPreviousPage as any)
        };
    }
    //clone deep should be done for any getters containing objects/array so mutating them wont affect the original
    //these should be mutable only by their set methods
    public get filtering(): ComponentFramework.PropertyHelper.DataSetApi.Filtering {
        return {
            clearFilter: () => {
                this._datasetProvider.setFiltering(null);
            },
            getFilter: () => cloneDeep(this._nativeStateValuesRef.current.userFilterExpression),
            setFilter: (expression) => {
                //all filters are stored on grid level
                this._nativeStateValuesRef.current.userFilterExpression = expression;
                this._datasetProvider.setFiltering(expression);
            },
        };
    }
    public get linking(): ComponentFramework.PropertyHelper.DataSetApi.Linking {
        return this._internalDatasetProvider?.getLinking();
    }
    public get sorting(): ComponentFramework.PropertyHelper.DataSetApi.SortStatus[] {
        //sorting is stored on grid level
        return this._nativeStateValuesRef.current.userSorting;
    }
    public get records() {
        const map: {
            [recordId: string]: ComponentFramework.PropertyHelper.DataSetApi.EntityRecord;
        } = {};
        this._datasetProvider.getRecords().map(record => {
            map[record.getRecordId()] = record;
        });
        return map;
    }
    public getRecords() {
        return this._datasetProvider.getRecords();
    }
    public get gridContext() {
        return this._gridContext;
    }
    public get selectedRecordIds() {
        return this._nativeStateValuesRef.current.selectedRecordIds;
    }
    public set selectedRecordIds(ids: string[]) {
        this._nativeStateValuesRef.current.selectedRecordIds = ids;
        if (this.selectedRecordIds.length === 1) {
            const selectedRecord = this.getRecords().filter(x => x.getRecordId() === this.selectedRecordIds[0])[0];
            const executionContext: Xrm.Events.EventContext = {
                /// @ts-ignore - We are not implementing entire EventContext, only required items by the scripts.
                getFormContext: () => {
                    return {
                        data: {
                            entity: {
                                getId: () => {
                                    return selectedRecord.getRecordId();
                                },
                                attributes: {
                                    get: (arg1?: any): any => {
                                        if (typeof arg1 === "string") {
                                            return {
                                                getValue: () => selectedRecord.getValue(arg1)
                                            } as Xrm.Attributes.Attribute;
                                        }
                                    }
                                }
                            }
                        }
                    };
                }
            };
            this._formContext?.onRecordSelect(this._controlProps.id, executionContext);
        }
        if (this.selectedRecordIds.length > 1) {
            const executionContext: Xrm.Events.EventContext = {
                /// @ts-ignore - We are not implementing entire EventContext, only required items by scripts.
                getFormContext: () => {
                    return {
                        getSelectedRows: () => {
                            return this.selectedRecordIds;
                        }
                    };
                }
            };
            this._formContext?.onRecordsSelect(this._controlProps.id, executionContext);
        }
        this.render();
        this.bindings[this.datasetBindingName]?.onRecordsSelected(ids);
        this.ribbon.refresh();
    }
    public get quickFindSearchValue(): string {
        return this._nativeStateValuesRef.current.searchValue;
    }
    public set quickFindSearchValue(value: string) {
        this._nativeStateValuesRef.current.searchValue = value;
    }
    public get loading() {
        return this._datasetProvider.isLoading();
    }
    public get gridType() {
        if (this.bindings.IsViewPage) {
            return XrmGridType.HomepageGrid;
        }
        return XrmGridType.Subgrid;
    }
    public get isHomepageGrid() {
        return this.gridType === XrmGridType.HomepageGrid;
    }
    public get isSubgrid() {
        return this.gridType === XrmGridType.Subgrid;
    }
    public get sortedRecordIds() {
        return this._datasetProvider.getRecords().map(record => record.getRecordId());
    }
    public set sorting(values: ComponentFramework.PropertyHelper.DataSetApi.SortStatus[]) {
        //all sorting is stored on grid level
        this._nativeStateValuesRef.current.userSorting = values;
        this._datasetProvider.setSorting(values);
    }
    public destroy() {
        this._mounted = false;
        this._controlInstance?.destroy();
        if (this.isHomepageGrid) window.Xrm.Page = undefined;
        HistoryManager.activeControls.delete(this._controlInstance);
        this._ribbon.destroy();
    }
    public isDirty(): boolean {
        return this._internalDatasetProvider?.isDirty();
    }
    public refresh() {
        this._renderDecorator(() => this._datasetProvider.refresh());
        this.viewSelector.refresh();
    }
    public async retrieveRecordCommand(recordIds: string[] = [], specificCommands: string[], filterByPriority: boolean, useNestedFormat: boolean, refreshAllRules: boolean): Promise<Ribbon.Command[]> {
        if (filterByPriority || useNestedFormat) {
            console.warn('filterByPriority and useNestedFormat parameters are currently not supported in Portal.');
        }
        let commands = await this._ribbon.getCommands(specificCommands, recordIds);
        if (recordIds.length === 0) {
            commands = commands.filter(command => command.shouldBeVisible);
        }
        return commands;
    }
    public openDatasetItem(entityReference: ComponentFramework.EntityReference) {
        entityReference = this._sanitizeLookupValue(entityReference);
        const openForm = () => {
            window.Xrm.Navigation.openForm({
                entityName: entityReference.etn,
                entityId: entityReference.id.guid,
            });
        };
        //opening other entity lookup
        if (this.entityName !== entityReference.etn) {
            openForm();
            return;
        }
        (async () => {
            const openRecordCommand = await this._ribbon.getCommand('Mscrm.OpenRecordItem');
            if (openRecordCommand) {
                openRecordCommand.execute();
                return;
            }
            openForm();
        })();
    }
    private async _renderDecorator(fn: () => Promise<any>) {
        this.render();
        await fn();
        switch (fn.name) {
            case 'loadPreviousPage':
            case 'loadExactPage':
            case 'loadNextPage': {
                //@ts-ignore - not part of typings
                this._nativeStateValuesRef.current.pageNumber = this.paging.pageNumber;
            }
        }
        this.render();
    }

    public get bindings() {
        return this._controlProps.bindings;
    }
    private async _initializeControlInstance() {
        this._control = await (await ControlLoader.getAsync(this._controlProps.name)).load();
        //@ts-ignore - call constructor
        this._controlInstance = new this._control.code();
    }
    public async render(init?: boolean) {
        const context = await Context.createDatasetContext(this, {
            ...this._controlProps, childeventlisteners: [
                {
                    eventname: "__updateColumnOrder",
                    eventhandler: (data) => this._internalDatasetProvider.updateColumnOrder(data)
                },
            ]
        }, this._control, this._controlStateRef.current);
        if (init) {
            this._controlInstance.init(context, () => { }, this._controlStateRef.current.getValues('pcf'), this._container);
        }
        if (this._mounted) {
            this._controlInstance.updateView(context);
        }
    }
    public get datasetBindingName() {
        return this._control.manifest.datasets[0].name;
    }
    private _getCdsOptions(): ICdsOptions | null {
        const inputString = this._control.manifest.datasets[0].cdsOptions;
        let jsonObject: any = {};
        if (!inputString) {
            //the default values should correspond to the default subgrid settings
            jsonObject = {
                displayCommandBar: true,
                displayIndex: false,
                displayPaging: true,
                displayQuickFind: false,
                displayViewSelector: false,
            } as ICdsOptions;
        }
        else {
            //lowest priority => cdsOptions from manifest
            try {
                const keyValuePairs = inputString.split(';');
                for (const pair of keyValuePairs) {
                    if (!pair) continue;
                    const [key, value] = pair.split(':');
                    const formattedKey = `${key.trim()}`; // Add double quotes around the key
                    jsonObject[formattedKey] = JSON.parse(value.trim()); // Parse the value and add it to the object
                }
            }
            catch (err) {
                throw new Error(`Invalid cdsOptions parameter in control ${this._controlProps.name}`);
            }
        }
        //medium priority => virtual grid dataset binding
        const cdsOptions = {
            ...jsonObject,
            ...this._controlProps.bindings[this.datasetBindingName]?.DataSetUIOptions
        } as ICdsOptions;
        //highest priority => bindings for specific attributes 
        if (this._controlProps.bindings.EnableQuickFind) {
            cdsOptions.displayQuickFind = this._controlProps.bindings.EnableQuickFind.value === 'true';
        }
        if (this._controlProps.bindings.EnableViewPicker) {
            cdsOptions.displayViewSelector = this._controlProps.bindings.EnableViewPicker.value === 'true';
        }
        if (this._controlProps.bindings.DisplayCommandBar) {
            cdsOptions.displayCommandBar = this._controlProps.bindings.DisplayCommandBar.value === 'true';
        }
        if (this._controlProps.bindings.DisplayPaging) {
            cdsOptions.displayPaging = this._controlProps.bindings.DisplayPaging.value === 'true';
        }
        return cdsOptions;
    }

    private _initDefaultStateValues(viewDefinition?: IViewDefinition, fetchXml?: string) {
        this._setDefaultStateValues({
            fetchxml: fetchXml,
            layoutjson: viewDefinition?.layoutjson,
            pageSize: (() => {
                if (this.bindings.RecordsPerPage) {
                    return parseInt(this.bindings.RecordsPerPage.value);
                }
                if (this.formContext) {
                    return DEFAULT_PAGE_SIZE_SUBGRID;
                }
                return DEFAULT_PAGE_SIZE_GRID;
            })(),
            pageNumber: 1,
            previousPages: [],
            searchValue: null,
            selectedRecordIds: [],
            userFilterExpression: null,
            userSorting: [],
            userLinkedEntities: []
        });
    }
    private _sanitizeLookupValue(value: ComponentFramework.EntityReference | ComponentFramework.LookupValue): ComponentFramework.EntityReference {
        if ((<ComponentFramework.EntityReference>value).id.guid) {
            return value as ComponentFramework.EntityReference;
        }
        value = value as ComponentFramework.LookupValue;
        return {
            id: {
                guid: value.id
            },
            name: value.name,
            etn: value.entityType
        } as ComponentFramework.EntityReference;
    }
}