import { Dropdown, getTheme, IDropdownProps, IToggleProps, Toggle } from '@fluentui/react';
import React from 'react';
import ReactDOM from "react-dom";
// import { TextField as TextFieldBase } from './component';
// import { ITextFieldProps } from './component/interfaces';

interface IInputs {
    value: ComponentFramework.PropertyTypes.TwoOptionsProperty;
}
interface IOutputs {
    value?: boolean;
}

export class TwoOptions implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _context: ComponentFramework.Context<IInputs>;
    private _notifyOutputChanged: () => void;
    private _container: HTMLDivElement;

    private _value: boolean;

    constructor() { }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
        this._context = context;
        this._notifyOutputChanged = notifyOutputChanged;
        this._container = document.createElement("div");
        container.appendChild(this._container);
    }

    public updateView(context: ComponentFramework.Context<IInputs>): void {
        this._context = context;
        this._value = context.parameters.value.raw ?? context.parameters.value.attributes?.DefaultValue ?? !!context.parameters.value.attributes?.Options[0].Value;

        const theme = getTheme();

        const options = this._context.parameters.value.attributes?.Options;

        const props: IToggleProps = {
            placeholder: "---",
            onText: options.find(x => x.Value == 1).Label,
            offText: options.find(x => x.Value == 0).Label,
            checked: this._value,
            disabled: this._context.mode.isControlDisabled,
            styles: {
                root: {
                    marginBottom: 0
                }
            },
            // TODO: This should be probably on focus change or something, so we don't send updates back and forth
            onChange: (e, value) => {
                this._value = value;
                this._notifyOutputChanged();
            }
        };

        ReactDOM.render(React.createElement(Toggle, props), this._container);
    }

    public getOutputs(): IOutputs {
        return {
            value: this._value
        };
    }

    public destroy(): void {
    }
}