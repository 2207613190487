import React from 'react';
import styles from './HorizontalNavigationLoading.module.css';
import { Shimmer } from '@fluentui/react/lib/components/Shimmer/Shimmer';
import { ShimmerElementType } from '@fluentui/react/lib/components/Shimmer/Shimmer.types';
import { ITheme, ThemeProvider, getTheme } from '@fluentui/react';

interface IHorizontalNavigationLoading {
    theme?: ITheme;
}

export const HorizontalNavigationLoading = (props: IHorizontalNavigationLoading) => {
    return (
        <ThemeProvider theme={props.theme ?? getTheme()} className={styles.root}>
            <Shimmer
                className={styles.sectionHeader}
                shimmerElements={[
                    { type: ShimmerElementType.gap, width: 15, height: 30 },
                    { type: ShimmerElementType.line, height: 10, width: 100 },
                    { type: ShimmerElementType.gap, width: 15, height: 30 },
                    { type: ShimmerElementType.gap, width: 15, height: 30 },
                    { type: ShimmerElementType.line, height: 10, width: 100 },
                    { type: ShimmerElementType.gap, width: 15, height: 30 },
                    { type: ShimmerElementType.gap, width: 15, height: 30 },
                    { type: ShimmerElementType.line, height: 10, width: 100 },
                    { type: ShimmerElementType.gap, width: 15, height: 30 },
                    { type: ShimmerElementType.gap, width: 15, height: 30 },
                    { type: ShimmerElementType.line, height: 10, width: 100 },
                    { type: ShimmerElementType.gap, width: 15, height: 30 },
                    { type: ShimmerElementType.gap, width: 15, height: 30 },
                    { type: ShimmerElementType.line, height: 10, width: 100 },
                    { type: ShimmerElementType.gap, width: 15, height: 30 }
                ]} />
        </ThemeProvider>
    );
};