import { Grid } from "../Grid";
import { ViewDefinition } from "@src/app/classes/definitions/ViewDefinition";
import { history } from '@providers/HistoryProvider/HistoryProvider';

export class ViewSelector implements Xrm.Controls.ViewSelector {
    private _grid: Grid;
    private _refreshCallback: () => void = () => { }
    private _changeViewCallback: (queryId: string) => void;

    constructor(grid: Grid) {
        this._grid = grid;
    }
    public get grid() {
        return this._grid;
    }
    public init(refreshCallback: () => void, changeViewCallback: (queryId: string) => void) {
        this._refreshCallback = refreshCallback;
        this._changeViewCallback = changeViewCallback;
        this.refresh();
    }
    public getCurrentView(): Xrm.LookupValue {
        return {
            entityType: this._grid.entityName,
            id: this._grid.viewId,
            name: this._grid.title
        };
    }
    public isVisible(): boolean {
        if (this._grid.isFullyVirtual) {
            return false;
        }
        return this._grid.cdsOptions.displayViewSelector;
    }
    public setCurrentView(viewSelectorItem: Xrm.LookupValue): void {
        if (this._grid.isHomepageGrid) {
            const data = {
                entityName: viewSelectorItem.entityType,
                viewId: `${viewSelectorItem.id}`,
            };
            const url = `${window.location.pathname}?data=${JSON.stringify(data)}`;
            history.push(url);
            return;
        }
        //this call back should result in the entire grid being reinitialized
        this._changeViewCallback(viewSelectorItem.id);
    }
    public async getViewNamesAndIds() {
        return ViewDefinition.getViewNamesAndIds(this._grid.entityName);
    }
    public async refresh() {
        this._refreshCallback();
    }

}