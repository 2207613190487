import { RibbonDefinition } from "@src/app/classes/definitions/RibbonDefinition";
import { executeFunctionByName } from "@src/app/Functions";
import { ControlLoader, ControlRegistration } from "@src/app/classes/loaders/ControlLoader";
import { ScriptLoader } from "@src/app/classes/loaders/ScriptLoader";
import { IFrameFactory } from "@src/app/classes/utilities/IFrameFactory";
import { UserSettingsDefinition } from "../../definitions/UserSettingsDefinition";

interface Dependencies {
    isInitialized: () => boolean;
    getEntityName: () => string,
    getLocation: () => Ribbon.Location,
    getParameterValuesOverride: (parameters: Ribbon.Definition.ButtonFunctionParameter[], selectedRecordIds?: string[]) => string[],
    isEnabled: () => boolean,
    getFormContext: () => Xrm.FormContext,
    onControlsResolved?: () => void;
    shouldIsolateScripts?: boolean;
}

export class Ribbon {
    private _location: Ribbon.Location
    private _entityName: string;
    private _hasInitStarted: boolean = false;
    private _finishInitialization: (value: boolean) => void;
    private _initialized: Promise<boolean>;
    private _refreshCallback: () => void = () => { }
    private _getParameterValuesOverride: (parameters: Ribbon.Definition.ButtonFunctionParameter[], selectedRecordIds?: string[]) => string[] = () => { return []; };
    private _isEnabled: () => boolean = () => { return true; };
    private _resolveControls: () => void = () => { };
    private _hasControlsBeenResolved: boolean = false;
    private _controlInstances: Map<string, any> = new Map();
    private _controlPromises: Promise<void>[] = [];
    private _dependencies: Dependencies;
    private _iframe: HTMLIFrameElement;
    private _iframeId: string;
    private _hasBeenDestroyed: boolean;
    protected _formContext: Xrm.FormContext | null;

    protected constructor(dependencies: Dependencies) {
        this._initialized = new Promise((resolve) => {
            this._finishInitialization = resolve;
        });
        this._dependencies = dependencies;
    }
    public async init(refreshCallback?: () => Promise<void>) {
        if (!this._dependencies.isInitialized()) {
            throw new Error(`Cannot create Ribbon because it's dependencies has not been correctly initialized. Please make sure that you correctly initialized the Form/Grid before creating it's Ribbon.`);
        }
        this._hasInitStarted = true;
        this._refreshCallback = refreshCallback ?? this._refreshCallback;
        this._entityName = this._dependencies.getEntityName();
        this._location = this._dependencies.getLocation();
        this._formContext = this._dependencies.getFormContext();
        this._resolveControls = this._dependencies.onControlsResolved ?? this._resolveControls;
        this._getParameterValuesOverride = this._dependencies.getParameterValuesOverride;
        this._isEnabled = this._dependencies.isEnabled;
        if (this._dependencies.shouldIsolateScripts) {
            this._iframeId = `Ribbon_${this._location}_${this._entityName}_${window.self.crypto.randomUUID()}`;
            this._iframe = await IFrameFactory.createIFrame({ id: this._iframeId, context: window });
        }
        //special case where the user quickly goes to other page => the ribbon is destroyed but this keeps executing
        if (this._hasBeenDestroyed) {
            return;
        }
        const ribbonDefinition = await RibbonDefinition.get(this._entityName, this._location);
        for (const libraryName of ribbonDefinition.libraries) {
            if (this._hasBeenDestroyed) {
                return;
            }
            await ScriptLoader.loadWebResourceLibraryAndDependencies(libraryName, this._iframe);
        }
        //we need to load the scripts here
        this._finishInitialization(true);
    }

    public refresh() {
        this._refreshCallback();
    }
    /**
     * Gets the full Ribbon Definition
     * @param {string} commandButtonIds: If `refreshAllRules` is set to `true`, you can use this parameter to specify which buttons will get their
     * Enable Rules triggered. If not set and the `refreshAllRules` is set to `true`, Enable Rules on all buttons will be executed.
     */
    public async getDefinition(commandButtonIds: string[] = [], selectedRecordIds?: string[]): Promise<Ribbon.Definition.Root> {
        //could be in cases where the ribbon is not visible (and therefore has not been fetched), but some script or PCF asks for it
        if (!this._hasInitStarted) {
            this.init();
        }
        await this._initialized;
        const ribbonDefinition = await RibbonDefinition.get(this._entityName, this.location);
        const processButtonsRecursively = async (buttons: Ribbon.Definition.Button[]) => {
            for (const button of buttons) {
                if (button.menuSections) {
                    for (const menuSection of button.menuSections) {
                        await processButtonsRecursively(menuSection.buttons);
                    }
                }
                if ((commandButtonIds.length === 0 || commandButtonIds.includes(button.id)) && button.visible) {
                    button.visible = await this._executeButtonRules(button.rules, selectedRecordIds);
                }
                if (!button.function) {
                    continue;
                }
                button.function.execute = () => {
                    //execute in Power Apps returns empty promise
                    return new Promise(async (resolve) => {
                        const parameterValues = this._getParameterValuesOverride(button.function.parameters, selectedRecordIds);
                        if (button.function.action) {
                            await button.function.action(parameterValues);
                            resolve();
                            return;
                        }
                        await executeFunctionByName(button.function.command, this._iframe, parameterValues);
                        resolve();
                    });
                };
            }
        };
        await processButtonsRecursively(ribbonDefinition.groups.flatMap(group => group.buttons));
        return ribbonDefinition;
    }
    public isEnabled() {
        return this._isEnabled();
    }
    public async registerControl(button: Ribbon.Definition.Button): Promise<[ControlRegistration, any]> {
        const controlRegistration = await ControlLoader.getAsync(button.label);
        await controlRegistration.load();
        if (this._controlInstances.has(button.id)) {
            return [controlRegistration, this._controlInstances.get(button.id)];
        }
        ///@ts-ignore - The official PCF context doesn't expose constructor, but we need to create a new instance.
        const controlInstance = new (await controlRegistration.registration).code();
        let controlResolve: () => void = null;
        const controlPromise: Promise<void> = new Promise((resolve) => {
            controlResolve = resolve;
        });
        controlInstance.init(null, controlResolve, null, null);
        this._controlPromises.push(controlPromise);
        this._controlInstances.set(button.id, controlInstance);
        return [controlRegistration, controlInstance];
    }
    public async resolveControls() {
        if (this._hasControlsBeenResolved) {
            return;
        }
        await Promise.all(this._controlPromises);
        this._hasControlsBeenResolved = true;
        this._resolveControls();
    }
    public destroy() {
        this._hasBeenDestroyed = true;
        IFrameFactory.destroyIFrame(this._iframeId);
    }
    public get location() {
        return this._location;
    }
    private async _executeButtonRules(rules: Ribbon.Definition.ButtonRule[], selectedRecordIds?: string[]): Promise<boolean> {
        let executionResult = true;
        for (const rule of rules) {
            let buttonVisible = false;
            const parameterValues = this._getParameterValuesOverride(rule.function.parameters, selectedRecordIds);
            if (rule.type === 'custom') {
                if (rule.function.action) {
                    buttonVisible = await rule.function.action(parameterValues);
                }
                else {
                    try {
                        buttonVisible = await executeFunctionByName(rule.function.command, this._iframe, parameterValues, null, 5000);
                    }
                    catch (err) {
                        RibbonDefinition.logError(`Failed to process ${rule.function.command}`, err);
                    }
                }
            }
            if (rule.type === 'selectionCount' || rule.type === 'value') {
                //TODO: investigate the need for this logic
                buttonVisible = await rule.function.action.apply(null, [parameterValues[0]]);
            }
            if (rule.invertResult) {
                buttonVisible = !buttonVisible;
            }
            if (!buttonVisible) {
                executionResult = false;
                break;
            }

        }
        return executionResult;
    }
    protected _getParameterValues(parameters: Ribbon.Definition.ButtonFunctionParameter[]): string[] {
        const parameterValues: any[] = [];
        for (const parameter of parameters) {
            if (parameter.type === 'StringParameter') {
                parameterValues.push(parameter.value);
                continue;
            }
            switch (parameter.value) {
                case 'PrimaryControl': {
                    parameterValues.push(this._formContext);
                    break;
                }
                case 'FirstPrimaryItemId': {
                    parameterValues.push(this._formContext?.data?.entity?.getId() ?? null);
                    break;
                }
                case 'PrimaryEntityTypeCode':
                case 'PrimaryEntityTypeName': {
                    parameterValues.push(this._formContext?.data?.entity?.getEntityName() ?? null);
                    break;
                }
                case 'UserLcid': {
                    parameterValues.push(UserSettingsDefinition.getUserSettings().languageId);
                    break;
                }
                default: {
                    //PrimaryControlId, CommandProperties, OrgLcid, OrgName
                    parameterValues.push(null);
                    console.warn("Unsupported ribbon parameter!", parameter);
                    break;
                }

            }
        }
        return parameterValues;
    }
}