import { Grid } from "../Grid";
import { useRef, useState } from "react";
import { ITextFieldProps } from "@talxis/react-components";
import { IQuickFindProps } from "./QuickFindComponent";
import { QuickFind } from "./QuickFind";

export const useQuickFind = (): [IQuickFindProps, (grid: Grid) => void, () => void] => {
    const [quickFindProps, setQuickFindProps] = useState<IQuickFindProps>(null);
    const quickFindRef = useRef<QuickFind>(null);
    const searchValueRef = useRef<string>(null);

    const createTextFieldProps = (readOnly?: boolean): ITextFieldProps => {
        return {
            value: searchValueRef.current,
            readOnly: readOnly,
            placeholder: quickFindRef.current.placeholder,
            deleteButtonProps: {
                key: 'delete',
                iconProps: {
                    styles: {
                        root: {
                            fontSize: 14
                        }
                    },
                    iconName: 'ChromeClose'
                },
                onClick: () => {
                    quickFindRef.current.searchValue = null;
                    searchValueRef.current = null;
                }
            },
            onKeyPress: (e) => {
                if (e.key === 'Enter') {
                    quickFindRef.current.searchValue = searchValueRef.current;
                }
            },
            onChange: (e, newValue) => {
                searchValueRef.current = newValue;
                refresh();
            },
            suffixItems: [
                {
                    key: 'search',
                    iconProps: {
                        iconName: 'Search'
                    },
                    onClick: () => { quickFindRef.current.searchValue = searchValueRef.current; }
                }
            ]
        } as ITextFieldProps;
    };

    const clear = () => {
        searchValueRef.current = null;
        setQuickFindProps({
            columnDisplayNames: [],
            textFieldProps: createTextFieldProps(true)
        });
    };

    const init = (grid: Grid) => {
        quickFindRef.current = grid.quickFind;
        searchValueRef.current = quickFindRef.current.searchValue;
        quickFindRef.current.init(refresh);
    };

    const refresh = async () => {
        if (!quickFindRef.current.isVisible()) {
            setQuickFindProps(null);
            return;
        }
        setQuickFindProps({
            columnDisplayNames: await quickFindRef.current.getColumnDisplayNames(),
            textFieldProps: createTextFieldProps()
        });
    };
    return [quickFindProps, init, clear];
};