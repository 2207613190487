import * as React from 'react';
import { useState } from 'react';
import { ComboBox } from '@talxis/react-components/dist/components/ComboBox';
import { IDecimalProps } from '../interfaces';
import { useEffect } from 'react';
import { IComboBox, IComboBoxOption } from '@fluentui/react';
import { DurationErrorCodes } from '../Enums/ErrorCodes';

export const DecimalField: React.FunctionComponent<IDecimalProps> = (props) => {
    const [errorMessage, setErrorMessage] = useState<string>(null);
    const [options, setOptions] = useState<IComboBoxOption[]>(props.options);
    const [text, setText] = useState<string | undefined>(undefined);
    const [selectedKey, setSelectedKey] = useState<string>();

    useEffect(() => {
        if (props.value) {
            const formattedValue = props.formatNumber(props.value);
            let _options = options;
            if (!_options.find(x => x.key === formattedValue)) {
                _options = _options.filter(x => x.key != "loadedValue");
                _options.push({
                    key: "loadedValue",
                    text: formattedValue,
                    hidden: true
                });
                setOptions(_options);
                setSelectedKey("loadedValue");
            } else {
                setSelectedKey(formattedValue);
            }
        }
    }, [props.value]);

    const onChange = (e: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        let validationResult: number | undefined;
        try {
            validationResult = props.validate(value);
        } catch (error) {
            setText(undefined);
            if (error && typeof error === 'object' && 'errorCode' in error) {
                if (error.errorCode === DurationErrorCodes.FORMAT_ERROR) {
                    setErrorMessage(props.getTranslatedString("durationErrorMessage"));
                } else if (error.errorCode === DurationErrorCodes.LIMIT_ERROR) {
                    setErrorMessage(props.getTranslatedString("durationLimitErrorMessage"));
                }
            }
            return;
        }
        if (!isNaN(validationResult)) {
            setErrorMessage(undefined);
            setText(undefined);
            props.onChange(e, option, index, value);
        }
    };

    return (
        <ComboBox
            {...props}
            options={options}
            selectedKey={selectedKey}
            onChange={onChange}
            text={text}
            allowFreeform
            errorMessage={errorMessage}
        />
    );
};
export default DecimalField;