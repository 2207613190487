import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IAppContext } from '@providers/AppProvider/interfaces';
import { AppContext } from '@providers/AppProvider';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import AlertDialog from '../../components/dialogs/AlertDialog';
import FormDialog from '../../components/dialogs/FormDialog';
import Footer from './components/Footer';
import { AppModule } from '@configuration/AppModule';
import { Navbar } from './components/Navbar';
import './css/styles.css';
import { HorizontalPageNavigation } from './components/PageNavigation/components/HorizontalPageNavigation';
import { VerticalPageNavigation } from './components/PageNavigation/components/VerticalPageNavigation';
import { mergeStyles } from '@fluentui/react';
import { Notifications } from './components/Notifications';
import { SitemapDefinition } from '@definitions/SitemapDefinition';
import { ThemeDefinition } from '@definitions/ThemeDefinition';
import { SitemapOrientation } from '@models/Theme';
import { LookupPanel } from '@src/components/navigation/panels/LookupPanel';
import { VerticalNavigationLoading } from '@src/components/loadings/VerticalNavigationLoading/VerticalNavigationLoading';
import { HorizontalNavigationLoading } from '@src/components/loadings/HorizontalNavigationLoading/HorizontalNavigationLoading';
import { useLayoutStyles } from './useLayoutStyles';
import { HistoryContext } from '@src/providers/HistoryProvider/HistoryProvider';
import { AppRibbon } from '@src/app/classes/models/Ribbon/AppRibbon';

const Layout: React.FC = (props) => {
    const isVertical = ThemeDefinition.getTheme().sitemapOrientation === SitemapOrientation.Vertical;
    const isBackgroundGradient = ThemeDefinition.getTheme().gradientBackground;
    const globalNotificationCollapseCount = ThemeDefinition.getTheme().globalNotificationCollapseCount;
    const stylesLoaded = useLayoutStyles(isVertical);
    const appContext: IAppContext = useContext(AppContext);
    const historyContext = useContext(HistoryContext);
    const appRibbonRef = useRef<AppRibbon>(new AppRibbon(() => {
        document.head.querySelector('meta[name="theme-color"]').setAttribute('content', ThemeDefinition.getTheme().getNavbarTheme().semanticColors.bodyBackground);
        appContext.setIsNavbarLoaded(true);
    }));
    const [navbarLoaded, setNavbarLoaded] = useState<boolean>(false);
    const [verticalNavigationVisible, setVerticalNavigationVisibility] = useState<boolean>(undefined);
    const [verticalNavigationExpanded, setVerticalNavigationExpanded] = useState<boolean>(true);
    const [navbarHidden, setNavbarHidden] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const portalContentRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLElement>(null);
    const horizontalNavRef = useRef<HTMLElement>(null);

    const getPortalStyles = () => {
        return mergeStyles({
            ':global(body)': {
                ...ThemeDefinition.getTheme().getCSSVariables(),
            },
            'background': `${isBackgroundGradient ? 'linear-gradient(180deg, var(--talxis-main-bodyBackgroundMain), white) fixed' : 'inherit'}`,
        });
    };

    const portalStyles = useMemo(() => {
        return getPortalStyles();
    }, []);

    const onScroll = (e: any) => {
        if (isVertical) {
            e.preventDefault();
            return;
        }
        if (window.top.scrollY < 15) {
            horizontalNavRef.current?.setAttribute('style', 'transform: translateY(0px)');
        }
        else if (window.top.scrollY > headerRef.current?.clientHeight) {
            horizontalNavRef.current?.setAttribute('style', `transform: translateY(-${horizontalNavRef.current?.clientHeight + 1}px)`);
        }
    };

    const setDocumentHeadTitle = () => {
        const currentSubArea = SitemapDefinition.getCurrentSiteMap().getSitemapSubAreaByProp('url', historyContext.currentPage.sitemapKey);
        document.querySelector('head > title').innerHTML = `${AppModule.get().name} | ${currentSubArea.title}`;
    };

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const navbar = queryParameters.get("navbar");
        const verticalNavigationExpanded = localStorage.getItem('verticalNavigationExpanded');
        if (verticalNavigationExpanded === 'false') {
            setVerticalNavigationExpanded(false);
        }
        if (navbar === 'off') {
            setNavbarHidden(true);
        }
        if (navbar !== 'off' && !navbarHidden) {
            init();
        }
        else {
            appContext.setIsNavbarLoaded(true);
        }
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    useEffect(() => {
        if (historyContext.currentPage?.sitemapKey) {
            setDocumentHeadTitle();
        }
    }, [historyContext.currentPage]);

    const init = async (): Promise<void> => {
        setNavbarLoaded(true);
    };

    const setVerticalNavigationExpandedHandler = async (newValue: boolean) => {
        localStorage.setItem('verticalNavigationExpanded', newValue.toString());
        setVerticalNavigationExpanded(newValue);
    };

    return (
        <div ref={ref} style={isVertical ? {
            //@ts-ignore - CSS properties
            '--vertical-nav-display': verticalNavigationVisible ? 'flex' : 'none',
            '--vertical-nav-width': verticalNavigationExpanded ? '220px' : '50px'
        } as React.CSSProperties : undefined} className={`TALXIS__portal ${portalStyles}`}>
            {!navbarHidden &&
                <header ref={headerRef} className='TALXIS__portal__header'>
                    <Navbar
                        logoSrc={ThemeDefinition.getTheme().getLogoUrl()}
                        navbarLoaded={navbarLoaded}
                        ribbon={appRibbonRef.current}
                        verticalNavigationEnabled={isVertical}
                        verticalNavigationVisible={verticalNavigationVisible}
                        onToggleVerticalNavigation={(value) => setVerticalNavigationVisibility(value)} />
                    <Notifications isGlobal collapseCount={globalNotificationCollapseCount} notifications={appContext.globalNotifications} />
                    {!appContext.isLoading && appContext.isNavbarLoaded && !isVertical &&
                        <section ref={horizontalNavRef} className={`TALXIS__navigation--horizontal`}>
                            <HorizontalPageNavigation />
                        </section>

                    }
                    {!isVertical && !appContext.isNavbarLoaded &&
                        <HorizontalNavigationLoading theme={ThemeDefinition.getTheme().getNavigationTheme()} />
                    }
                </header>
            }
            {stylesLoaded &&
                <div className='TALXIS__portal__layout'>
                    {!appContext.isLoading && appContext.isNavbarLoaded && isVertical && verticalNavigationVisible !== false && !navbarHidden &&
                        <aside>
                            <VerticalPageNavigation
                                areasAsTabs={ThemeDefinition.getTheme().sitemapVerticalAreasAsTabs}
                                visible={verticalNavigationVisible}
                                expanded={verticalNavigationExpanded}
                                onToggleExpandVerticalNavigation={setVerticalNavigationExpandedHandler}
                                onToggleVerticalNavigationVisibility={() => {
                                    if (window.innerWidth <= 768) {
                                        setVerticalNavigationVisibility(false);
                                    }
                                }} />
                        </aside>
                    }
                    {!appContext.isNavbarLoaded && isVertical && !navbarHidden &&
                        <aside>
                            <VerticalNavigationLoading theme={ThemeDefinition.getTheme().getNavigationTheme()} />
                        </aside>
                    }
                    <main className='TALXIS__portal__content' ref={portalContentRef}>
                        {props.children}
                    </main>
                    {!isVertical &&
                        <Footer />
                    }
                </div>
            }
            <ConfirmationDialog {...appContext.confirmationDialogProps} />
            <AlertDialog {...appContext.alertDialogProps} />
            {
                appContext.formDialogs.map((dialogProps, index) => {
                    return <FormDialog key={dialogProps.id} {...dialogProps} />;
                })
            }
            <LookupPanel {...appContext.lookupPanelProps} />
        </div >
    );
};
export default Layout;
