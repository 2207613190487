import { ICalendarDayProps } from '@fluentui/react/lib/components/Calendar/CalendarDay/CalendarDay.types';
import * as React from 'react';
import { Calendar, DayOfWeek, defaultCalendarStrings } from '@fluentui/react/lib/components/Calendar';
import { IDateTimePickerProps } from '../interfaces';
import { DatePicker } from '@talxis/react-components/dist/components/DatePicker';
import { IComboBox } from '@fluentui/react/lib/components/ComboBox';
import { TimePicker } from '@talxis/react-components/dist/components/TimePicker';
import { useEffect } from 'react';
import { mergeStyles } from '@fluentui/react';
import dayjs from 'dayjs';

export const DatePickerField: React.FunctionComponent<IDateTimePickerProps> = (props) => {
    const [value, setValue] = React.useState<Date | undefined>(props.value);
    const [avaliableDates] = React.useState<Date[] | undefined>(props.availableDates);
    const [excludedDates] = React.useState<Date[] | undefined>(props.excludedDates);
    const [excludedDaysOfTheWeek] = React.useState<number[] | undefined>(props.excludeDaysOfTheWeekArray);
    const [timePickerErrorMessage, setTimePickerErrorMessage] = React.useState<string | undefined>();
    const [timeKey, setTimeKey] = React.useState<number>(0);
    const timePickerRef = React.useRef<IComboBox>(null);

    // ACTIONS
    const onFormatDate = (date?: Date): string => {
        if (!date) {
            return undefined;
        }
        if (props.hideDaySelection) {
            return props.formatting.formatDateYearMonth(date);
        }
        else {
            return props.formatting.formatDateShort(date);
        }
    };
    const onFormatTime = (date?: Date): string => {
        if (!date) {
            return "";
        }
        if (props.behavior === 3) {
            return dayjs(date).utc().format(props.userSettings.dateFormattingInfo.shortTimePattern);
        }
        return dayjs(date).format(props.userSettings.dateFormattingInfo.shortTimePattern);
    };

    const onSelectDate = (date: Date): void => {
        let _value: Date = new Date(date);
        if (value) {
            _value.setHours(value.getHours(), value.getMinutes(), value.getSeconds(), value.getMilliseconds());
        } else {
            // In PowerApps, this value is set in User Settings (Activities->Default Work Hours->Start Time)
            // Currently in Access Principals we don't have this information so default value is set to 8 (same as default start time in PowerApps)
            _value.setHours(8);
        }
        setValue(_value);
        props.onSelectDate(_value);
    };

    const onSelectTime = (event: React.FormEvent<IComboBox>, date: Date): void => {
        if (date.toString().toUpperCase() !== "INVALID DATE") {
            let _value: Date = new Date(value.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
            setValue(_value);
            setTimePickerErrorMessage(null);
            props.onSelectDate(_value);
        } else {
            setTimePickerErrorMessage("Invalid time. Reverted to previous value.");
        }
        setTimeKey(timeKey + 1);
    };

    const calendarStrings = {
        ...defaultCalendarStrings,
        ...(props.languageCode === 1029 &&
        {
            days: ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
            shortDays: ['N', 'P', 'Ú', 'S', 'Č', 'P', 'S'],
            months: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Zaří', 'Říjen', 'Listopad', 'Prosinec'],
            shortMonths: ['led', 'úno', 'bře', 'dub', 'kvě', 'čvn', 'čvc', 'srp', 'zář', 'říj', 'lis', 'pro'],
            prevMonthAriaLabel: 'Přejít na předchozí měsíc',
            nextMonthAriaLabel: 'Přejít na další měsíc',
            prevYearAriaLabel: 'Přejít na předchozí rok',
            nextYearAriaLabel: 'Přejít na další rok',
        })
    };

    const onDeleteButtonClick = () => {
        setValue(null);
        props.onDeleteButtonClick();
    };

    const calendarDayProps: Partial<ICalendarDayProps> = {
        customDayCellRef: (element, date, classNames) => {
            if (element) {
                if (avaliableDates) {
                    if (!avaliableDates?.find(x => x.getDate() === date.getDate() && x.getMonth() === date.getMonth() && x.getFullYear() === date.getFullYear())) {
                        classNames.dayOutsideBounds && element.classList.add(classNames.dayOutsideBounds);
                        (element.children[0] as HTMLButtonElement).disabled = true;
                    }
                }
                if (excludedDates) {
                    if (excludedDates?.find(x => x.getDate() === date.getDate() && x.getMonth() === date.getMonth() && x.getFullYear() === date.getFullYear())) {
                        classNames.dayOutsideBounds && element.classList.add(classNames.dayOutsideBounds);
                        (element.children[0] as HTMLButtonElement).disabled = true;
                    }
                }
                if (excludedDaysOfTheWeek?.find(x => x == date.getDay())) {
                    classNames.dayOutsideBounds && element.classList.add(classNames.dayOutsideBounds);
                    (element.children[0] as HTMLButtonElement).disabled = true;
                }
            }
        },
    };

    useEffect(() => {

        let tempValue: Date = null;
        tempValue = props.value;
        if (props.behavior === 3 && tempValue) {
            //the date in javascript gets automatically adjusted to local time zone
            //this will set a new Date, where the UTC date is interpreted as local so it displays correctly in UI
            tempValue = new Date(tempValue.toISOString().replace('Z', ''));
        }
        setValue(tempValue);
        //@ts-ignore - accessing currentPendingValue to set custom time value
        if (timePickerRef?.current?.state) {
            //@ts-ignore - accessing currentPendingValue to set custom time value
            timePickerRef.current.state.currentPendingValue = onFormatTime(tempValue);
        }
    }, [props.value]);

    useEffect(() => {
        //@ts-ignore - accessing currentPendingValue to set custom time value
        if (timePickerRef?.current?.state) {
            //@ts-ignore - accessing currentPendingValue to set custom time value
            timePickerRef.current.state.currentPendingValue = onFormatTime(value);
        }
    }, [timeKey]);
    return (

        <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', flexWrap: 'wrap' }}>

            <DatePicker
                value={value}
                className={mergeStyles({
                    flex: 'auto'
                })}
                readOnly={props.readOnly}
                disabled={props.disabled}
                borderless={props.borderless}
                showGoToToday={false}
                calendarProps={avaliableDates || excludedDates || excludedDaysOfTheWeek ? { calendarDayProps: calendarDayProps } : null}
                formatDate={onFormatDate}
                onSelectDate={onSelectDate}
                // Lowest date supported by CDS: https://learn.microsoft.com/en-us/previous-versions/dynamicscrm-2016/developers-guide/dn996866(v=crm.8)?redirectedfrom=MSDN
                minDate={new Date('1753-01-01T00:00:00.000Z')}
                placeholder="---"
                strings={calendarStrings}
                deleteButtonProps={{
                    key: 'Delete',
                    onClick: onDeleteButtonClick,
                    showOnlyOnHover: true,
                    iconProps: {
                        iconName: 'Cancel'
                    }
                }}
                firstDayOfWeek={props.languageCode === 1029 ? DayOfWeek.Monday : DayOfWeek.Sunday}
                calendarAs={
                    (calendarProps) =>
                        <Calendar
                            {...calendarProps}
                            isDayPickerVisible={!props.hideDaySelection}
                            isMonthPickerVisible={!props.hideMonthSelection}
                            strings={calendarStrings}
                        />
                }
            />
            {value && props.format === 'DateAndTime' &&
                <TimePicker
                    key={timeKey}
                    className={mergeStyles({
                        flex: 'auto'
                    })}
                    // This should come from PCF's userSettings
                    useHour12={onFormatTime(new Date()).includes("AM") || onFormatTime(new Date()).includes("PM")}
                    componentRef={timePickerRef}
                    defaultValue={new Date()}
                    readOnly={props.readOnly}
                    disabled={props.disabled}
                    errorMessage={timePickerErrorMessage}
                    useComboBoxAsMenuWidth
                    autoCapitalize="off"
                    autoComplete="off"
                    iconButtonProps={{
                        iconProps: {
                            iconName: "Clock",
                            styles: {
                                root: {
                                    fontSize: 17
                                }
                            }
                        }
                    }}
                    borderless={props.borderless}
                    onChange={onSelectTime}
                    onFormatDate={onFormatTime}
                />
            }
        </div>
    );
};
export default DatePickerField;