import { IFormContext } from "../native/Form/interfaces/IFormContext";
import { Grid } from "./Grid";
import { IDataSetColumn } from "../native/View/interfaces/viewdefinition";
import { IExtendedXrmGridControl, IXrmGridRelationship, XrmGridType } from "./interfaces";

export class GridContext implements IExtendedXrmGridControl {
    private _grid: Grid;
    constructor(grid: Grid) {
        this._grid = grid;
    }
    getFetchXml(): string {
        return this._grid.fetchXml;
    }
    getViewColumns(): IDataSetColumn[] {
        return this._grid.columns;
    }
    getGridType(): XrmGridType {
        return this._grid.gridType;
    }
    getRelationship(): IXrmGridRelationship {
        return this._grid.relationship;
    }
    getParentForm?(): IFormContext {
        return this._grid.formContext;
    }
    getGrid(): Xrm.Controls.Grid {
        return {
            getSelectedRows: () => {
                return {
                    forEach: (delegate) => {
                        for (const [index, id] of Object.entries(this._grid.selectedRecordIds)) {
                            const item: Xrm.Controls.Grid.GridRow = {
                                data: {
                                    entity: {
                                        getId: () => id,
                                    },
                                } as any, // TODO: This shouldn't be any, but Xrm.Data
                                getData: () => { throw new Error("Not implemented!"); },
                            };
                            delegate(item, +index);
                        }
                    },
                    get: () => { throw new Error("Not implemented!"); },
                    getLength: () => this._grid.selectedRecordIds.length,
                };
            },
            getRows: () => { throw new Error("Not implemented!"); },
            getTotalRecordCount: () => { throw new Error("Not implemented!"); },
        };
    }
    addOnLoad?(handler: Xrm.Events.ContextSensitiveHandler): void {
        throw new Error("Method not implemented.");
    }
    getContextType?(): XrmEnum.GridControlContext {
        throw new Error("Method not implemented.");
    }
    getEntityName?(): string {
        return this._grid.entityName;
    }
    getViewSelector?(): Xrm.Controls.ViewSelector {
        return this._grid.viewSelector;
    }
    refresh?(): void {
        this._grid.refresh();
    }
    refreshRibbon?(): void {
        this._grid.ribbon.refresh();
    }
    removeOnLoad?(handler: () => void): void {
        throw new Error("Method not implemented.");
    }
    getControlType?(): string {
        throw new Error("Method not implemented.");
    }
    getName?(): string {
        return this._grid.title;
    }
    getParent?(): Xrm.Controls.Section {
        throw new Error("Method not implemented.");
    }
    getLabel?(): string {
        throw new Error("Method not implemented.");
    }
    setLabel?(label: string): void {
        throw new Error("Method not implemented.");
    }
    getVisible?(): boolean {
        throw new Error("Method not implemented.");
    }
    setVisible?(visible: boolean): void {
        throw new Error("Method not implemented.");
    }
}