import { Authentication } from "../Authentication";
import { UserSettings } from "../models/UserSettings";

export const NUMBER_OF_RECORDS_PER_VIEW_PAGE = [
    { id: 742070000, value: 25 },
    { id: 742070001, value: 50 },
    { id: 742070002, value: 100 },
    { id: 742070003, value: 250 }
];

export class UserSettingsDefinition {
    private static _userSettings: UserSettings
    public static async load(accessPrincipalId?: string): Promise<UserSettings> {
        if (this._userSettings) {
            return this._userSettings;
        }
        if (!accessPrincipalId) {
            this._userSettings = new UserSettings();
        }
        else {
            try {
                const retrievedUserSettings = await Xrm.WebApi.retrieveRecord("talxis_accessprincipal", accessPrincipalId, "?$select=talxis_accessprincipalid&$expand=talxis_accessprincipalsettingsid($select=talxis_language,talxis_numberofrecordsperviewpagetypecode),talxis_contactid($select=emailaddress1)");
                this._userSettings = new UserSettings({
                    id: accessPrincipalId,
                    settingsId: retrievedUserSettings?.['talxis_accessprincipalsettingsid']?.['talxis_accessprincipalsettingsid'],
                    username: Authentication.getUser().displayName,
                    languageId: retrievedUserSettings?.['talxis_accessprincipalsettingsid']?.['talxis_language'],
                    pagingLimit: NUMBER_OF_RECORDS_PER_VIEW_PAGE.find(x => x.id === retrievedUserSettings?.['talxis_accessprincipalsettingsid']?.['talxis_numberofrecordsperviewpagetypecode'])?.value
                });
            }
            catch (ex) {
                console.warn("UserSettings", "Unable to retrieve user settings, falling back to built-in defaults!", ex);
                this._userSettings = new UserSettings({
                    id: accessPrincipalId,
                    username: Authentication.getUser()?.displayName
                });
            }
        }
        return this._userSettings;
    }

    public static getUserSettings() {
        if (!this._userSettings) {
            throw new Error('User definition has not been loaded. Have you called UserSettingsDefinition.load()?');
        }
        return this._userSettings;
    }
}