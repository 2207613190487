import { AppModule as IAppModule } from '../../interfaces/appmodule';
import { IconLoader, IIcon } from '@loaders/IconLoader';
import { SpaConfiguration } from './SpaConfiguration';
import { LocalizeLabel } from '@localization/helpers';
import { metadataRetrieveMultiple } from '@definitions/MetadataApi';
import { WebsiteDefinition } from '../definitions/WebsiteDefinition';

export class AppModule {
    private static _currentAppModule: AppModule;
    private static _allAppModules: AppModule[] = [];
    private static _allowedAppModules: AppModule[] = [];

    private _iconName?: string;
    public name: string;
    public description: string;
    public uniquename: string;
    public appmoduleid: string;
    public ismanaged: boolean;
    public icon?: IIcon;
    public descriptor: string;

    private constructor(appModule: IAppModule) {
        this.name = LocalizeLabel(appModule.__labels["name"]) ?? appModule.name;
        this.uniquename = appModule.uniquename;
        this.appmoduleid = appModule.appmoduleid;
        this.description = LocalizeLabel(appModule.__labels["description"]) ?? appModule.description;
        this.descriptor = appModule.descriptor;
        this.ismanaged = appModule.ismanaged;
        if (appModule.descriptor) {
            //IconLoader currently expects only this type of icon reference
            this._iconName = `/WebResource/${JSON.parse(appModule.descriptor).webResourceInfo.Name}`;
        }
    }
    static async getAllAppModules(allowedOnly: boolean = true): Promise<AppModule[]> {
        //TODO: permissions
        if (allowedOnly) {
            if (this._allowedAppModules?.length > 0) {
                return this._allowedAppModules;
            };
            const website = WebsiteDefinition.getWebsite();
            let filterQueryArray = [];
            if (website?.allowedAppModules) {
                for (const appModule of website.allowedAppModules.values()) {
                    filterQueryArray.push(`uniquename eq '${appModule}'`);
                }
            }
            const filterQueryString = (website?.allowedAppModules?.length > 0 && allowedOnly) ? `&$filter=${filterQueryArray.join(" or ")}` : "";
            const response = await metadataRetrieveMultiple(`v9.1/appmodules?$select=appmoduleid, name,uniquename,descriptor,description,ismanaged${filterQueryString}&$orderby=name`);
            for (const entity of response.entities) {
                const appModule = new AppModule(entity as IAppModule);
                this._allowedAppModules.push(appModule);
            }
            return this._allowedAppModules;
        }
        else {
            if (this._allAppModules?.length > 0) {
                return this._allAppModules;
            };
            const response = await metadataRetrieveMultiple(`v9.1/appmodules?$select=appmoduleid, name,uniquename,descriptor,description,ismanaged&$orderby=name`);
            for (const entity of response.entities) {
                const appModule = new AppModule(entity as IAppModule);
                this._allAppModules.push(appModule);
            }
            return this._allAppModules;
        }
    };
    static get(): AppModule {
        return this._currentAppModule;
    }
    static async loadAsync(name?: string): Promise<boolean> {
        if (!name) {
            //try to get app module from URL
            name = window.location.pathname.split('/')[1];
        }
        let appModule = await this._getAppModuleAsync(name);
        //if app module was not found, try to get it from configuration or local storage
        if (!appModule) {
            name = localStorage.getItem('appModuleName');
            if (!name) {
                name = SpaConfiguration.get().defaultAppModule;
            }
            //if the name was not found in the configuration or local storage, return false to tell the app provider to show app switcher
            if (!name) {
                return false;
            }
            appModule = await this._getAppModuleAsync(name);
            if (!appModule) {
                throw new Error(`App module ${name} could not be loaded!`);
            }
        }
        this._currentAppModule = new AppModule(appModule);
        localStorage.setItem('appModuleName', this._currentAppModule.uniquename);
        await this._currentAppModule.loadIcon();
        return true;
    }
    public async loadIcon(): Promise<void> {
        this.icon = await IconLoader.getAsync(this._iconName);
    }
    private static async _getAppModuleAsync(name?: string): Promise<IAppModule> {
        if (!name) {
            return undefined;
        }
        const response = await metadataRetrieveMultiple(`v9.1/appmodules?$filter=uniquename eq '${name}'&$select=name,appmoduleid,uniquename,descriptor,ismanaged`);
        if (response.entities.length === 0) {
            console.log(`App module ${name} was not found!`);
            return undefined;
        }
        const appModule: IAppModule = response.entities[0] as IAppModule;
        return appModule;
    }
}