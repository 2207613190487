import React, { useState } from 'react';
import { mergeStyles, PanelType, ThemeProvider } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react';
import { CommandBar } from '@talxis/react-components/dist/components/CommandBar';
import { AppPanel } from './components/AppPanel';
import { useEffect } from 'react';
import { AppModule } from '@configuration/AppModule';
import styles from './Navbar.module.css';
import { useMemo } from 'react';
import { history } from '@providers/HistoryProvider/HistoryProvider';
import { ThemeDefinition } from '@definitions/ThemeDefinition';
import { AppSwitcherDialog } from '../../../../components/dialogs/AppSwitcherDialog';
import { SitemapDefinition } from '@src/app/classes/definitions/SitemapDefinition';
import { RibbonController } from '@src/components/navigation/ribbon/RibbonController';
import { AppRibbon } from '@src/app/classes/models/Ribbon/AppRibbon';

interface INavbarProps {
    navbarLoaded: boolean;
    ribbon: AppRibbon;
    verticalNavigationEnabled?: boolean;
    verticalNavigationVisible?: boolean;
    logoSrc?: string;
    onToggleVerticalNavigation?: (visible: boolean) => void;
}

export const Navbar: React.FC<INavbarProps> = (props) => {
    const theme = ThemeDefinition.getTheme().getNavbarTheme();
    const [isAppPanelOpen, setIsAppPanelOpen] = useState<boolean>(false);
    const [showAppPanelBtn, setShowAppPanelBtn] = useState<boolean>(false);
    const [appSwitcherDialogVisible, setAppSwitcherDialogVisible] = useState<boolean>(false);
    const commandBarStyles = useMemo(() => {
        return mergeStyles({
            '.TALXIS__ribbon__icon__svg path': {
                fill: theme.palette.themePrimary
            },
            ' > div > div > .ms-CommandBar': {
                height: 48,
                '> .ms-CommandBar-primaryCommand': {
                    justifyContent: 'flex-end'
                },
                '> div > div > .TALXIS__ribbon__pcf>div>.ms-Button--commandBar': {
                    backgroundColor: 'transparent',
                    height: 48,
                    '> span .ms-Persona-primaryText, > span .ms-Button-label': {
                        color: theme.semanticColors.bodyText,
                        fontWeight: 600
                    },
                    ':hover': {
                        backgroundColor: theme.semanticColors.buttonBackgroundHovered
                    },
                    ':active': {
                        backgroundColor: theme.semanticColors.buttonBackgroundPressed
                    },
                }
            }
        });
    }, [theme]);
    const getLeftSideItems = () => {
        const items: ICommandBarItemProps[] = [];
        if (props.verticalNavigationEnabled) {
            const menuButton: ICommandBarItemProps = {
                key: 'menu',
                className: styles.menuButton,
                iconProps: {
                    iconName: 'GlobalNavButton'
                },
                onClick: () => props.onToggleVerticalNavigation(!props.verticalNavigationVisible)
            };
            items.push(menuButton);
        }
        if (showAppPanelBtn) {
            items.push({
                key: 'appSwitcher',
                className: styles.appSwitcherButton,
                iconProps: {
                    iconName: 'WaffleOffice365'
                },
                onClick: () => setIsAppPanelOpen(true)
            });
        }

        if (props.logoSrc) {
            items.push({
                key: 'logo',
                className: `TALXIS__navbar__left-side__logo ${styles.logo}`,
                onClick: (e) => {
                    e.preventDefault();
                    history.push(SitemapDefinition.getCurrentSiteMap().getDefinition().areas[0]?.groups[0]?.subAreas[0]?.url);
                },
                iconProps: {
                    imageProps: {
                        shouldFadeIn: false,
                        src: props.logoSrc
                    }
                }
            });
        }
        if (showAppPanelBtn) {
            items.push({
                key: "appModuleName",
                text: AppModule.get().name,
                className: styles.appModuleName,
                onClick: () => {
                    if (props.verticalNavigationEnabled && (window.innerWidth < 768)) {
                        setIsAppPanelOpen(true);
                    }
                    else {
                        setAppSwitcherDialogVisible(true);
                    }
                }
            });
        }
        return items;
    };
    const init = async () => {
        const appModules = await AppModule.getAllAppModules();
        if (appModules.length > 1) {
            setShowAppPanelBtn(true);
        }
    };
    useEffect(() => {
        if (!props.navbarLoaded) {
            return;
        }
        init();
    }, [props.navbarLoaded]);
    const getCSSVariables = (): any => {
        if (props.verticalNavigationVisible !== undefined) {
            return {
                '--menu-button-display': !props.verticalNavigationVisible ? 'block' : 'none'
            };
        }
    };
    return (
        <>
            <nav style={getCSSVariables()} className={`TALXIS__navbar ${styles.root} ${mergeStyles({
                borderBottom: `1px solid ${theme.semanticColors.menuDivider}`
            })}`}>
                <ThemeProvider theme={theme}>
                    <CommandBar
                        className={`TALXIS__navbar__left-side ${styles.leftSide} ${commandBarStyles}`}
                        items={[]}
                        farItems={getLeftSideItems()} />
                    {props.navbarLoaded &&
                        <RibbonController
                            className={`${styles.ribbon} ${styles.rightSide}`}
                            commandBarClassName={`${commandBarStyles} ${styles.ribbonCommandBar}`}
                            ribbon={props.ribbon} />
                    }
                </ThemeProvider>
                <AppPanel
                    isOpen={isAppPanelOpen}
                    type={PanelType.customNear}
                    customWidth={'350px'}
                    isLightDismiss
                    headerText={window.TALXIS.Portal.Translations.getLocalizedString("components/dialogs/AppSwitcherDialog/Title")}
                    onDismiss={() => setIsAppPanelOpen(false)} />
                {appSwitcherDialogVisible &&
                    <AppSwitcherDialog appSwitcherDialogVisible={appSwitcherDialogVisible} setAppSwitcherDialogVisible={setAppSwitcherDialogVisible} />
                }
            </nav>
        </>
    );
};
