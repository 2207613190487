import { Shimmer, ShimmerElementsGroup } from '@fluentui/react';
import { ShimmerElementType } from '@fluentui/react/lib/components/Shimmer/Shimmer.types';
import React from 'react';
import styles from './FormLoading.module.css';
import { ThemeDefinition } from '@src/app/classes/definitions/ThemeDefinition';
import { RibbonController } from '@src/components/navigation/ribbon/RibbonController';

interface IFormLoadingProps {
    ribbonPlaceholder?: {
        backButton?: true
    }
    headerPlaceholder?: {
        showPersonaPlaceholder?: boolean;
    }
    style?: React.CSSProperties;
}

export const FormLoading: React.FC<IFormLoadingProps> = (props) => {
    const lines = [1, 2, 3, 4];
    const columns = [1, 2, 3, 4, 5, 6];
    return (
        <div className={styles.root} style={props.style}>
            <div className={styles.headerWrapper}>
                {props.ribbonPlaceholder &&
                    <RibbonController
                        theme={ThemeDefinition.getTheme()?.getRibbonTheme()}
                        showBackButton={props.ribbonPlaceholder?.backButton} />
                }
                {props.headerPlaceholder &&
                    <div className={styles.header}>
                        <Shimmer customElementsGroup={
                            <div style={{ display: 'flex' }}>
                                {props.headerPlaceholder.showPersonaPlaceholder &&
                                    <ShimmerElementsGroup
                                        shimmerElements={[
                                            { type: ShimmerElementType.circle, height: 60 },
                                            { type: ShimmerElementType.gap, width: 16, height: 60 },
                                        ]}
                                    />
                                }
                                <ShimmerElementsGroup
                                    flexWrap
                                    width="100%"
                                    shimmerElements={[
                                        { type: ShimmerElementType.line, width: '15%', height: 10 },
                                        { type: ShimmerElementType.gap, width: '85%', height: 30 },
                                        { type: ShimmerElementType.line, width: '25%', height: 10 },
                                        { type: ShimmerElementType.gap, width: '75%', height: 30 },
                                    ]}
                                />
                            </div>
                        }
                        />
                    </div>
                }
                {props.headerPlaceholder &&
                    <div className={styles.tabs}></div>
                }
            </div>
            <div className={styles.body}>
                <div className={styles.columns}>
                    {columns.map((column) =>
                        <div className={styles.column} key={column}>
                            <Shimmer
                                className={styles.sectionHeader}
                                shimmerElements={[
                                    { type: ShimmerElementType.gap, width: 15, height: 30 },
                                    { type: ShimmerElementType.line, height: 10 },
                                    { type: ShimmerElementType.gap, width: 15, height: 30 }
                                ]} />
                            <Shimmer
                                shimmerElements={[
                                    { type: ShimmerElementType.gap, height: 5, width: '100%' }
                                ]} />
                            {lines.map((line) =>
                                <Shimmer
                                    key={line}
                                    width='100%'
                                    shimmerElements={[
                                        { type: ShimmerElementType.gap, width: 15, height: 30 },
                                        { type: ShimmerElementType.line, height: 10 },
                                        { type: ShimmerElementType.gap, width: 15, height: 30 }
                                    ]} />
                            )}
                            <Shimmer
                                shimmerElements={[
                                    { type: ShimmerElementType.gap, height: 21, width: '100%' }
                                ]} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};