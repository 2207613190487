import React, { useEffect, useState, useRef, useContext } from 'react';
import { IControlProps } from '../interfaces';
import { Context, getRequiredLevel } from '@ComponentFramework/PropertyClasses/Context';
import Loading from '../../loadings/Loading';
import { IFormContext } from '../native/Form/interfaces/IFormContext';
import { FormContext, isFieldValid } from '../native/Form/Form';
import { FormContext as NestedPcfFormContext } from '@ComponentFramework/NestedPcfWrapper';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { ControlLoader, ControlRegistration } from '@loaders/ControlLoader';
import { DataType } from '@ComponentFramework/interfaces/DataType';
import { EntityDefinition } from '../../../app/classes/definitions/EntityDefinition';
import { Attribute } from '../../../app/interfaces/entitydefinition';
import { IAttributeConfiguration } from '../native/Form/interfaces/IAttributeConfiguration';
import cloneDeep from 'lodash/cloneDeep';
import { FileAttribute, IFileAttributeConnection } from '@talxis/client-libraries/dist/components/FileAttribute';
import { IFileObject } from '../interfaces/IFileObject';
import { executeAuthenticatedRequest } from '@src/ComponentFramework/PropertyClasses/WebApi';
import { SpaConfiguration } from '@src/app/classes/configuration/SpaConfiguration';
import { useControlState } from '../useControlState';
import { HistoryManager } from '@src/providers/HistoryProvider/HistoryManager';

export const FieldControl: React.FC<IControlProps> = (props) => {
    const formContext: IFormContext = props.formContext ?? useContext(FormContext) ?? useContext(NestedPcfFormContext);
    const container = useRef(null);
    const [isRendered, setIsRendered] = useState<boolean>(false);
    const [renderFailed, setRenderFailed] = useState<boolean>(false);
    const [isInititalized, setIsInitialized] = useState<boolean>(false);
    const [datafieldChanged, setDatafieldChanged] = useState<boolean>(false);
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
    const [controlInstance, setControlInstance] = useState<ComponentFramework.StandardControl<any, any>>(null);
    const formEntityChangesRef = useRef<ComponentFramework.WebApi.Entity>({});
    const previousFormEntityChangesRef = useRef<ComponentFramework.WebApi.Entity>({});
    const previousPropsRef = useRef<IControlProps>(null);
    const scheduledUpdateRef = useRef<boolean>(null);
    const previousAttributeConfigurationRef = useRef<{ [name: string]: IAttributeConfiguration }>({});
    const controlRegistrationRef = useRef<ControlRegistration>(null);
    const controlInstanceRef = useRef<ComponentFramework.StandardControl<any, any> | ComponentFramework.ReactControl<any, any>>(null);
    const attributeMetadataRef = useRef<Attribute>(undefined);
    const fieldControlStateRef = useControlState(props.id, props.cellId);
    const updatedPropertiesRef = useRef<string[]>([]);
    const mountedRef = useRef(true);

    const render = async (init?: boolean) => {
        try {
            const context = await Context.createFieldContext(
                props,
                formContext?.entityName,
                { ...formContext?.entity, ...formContext?.entityChanges },
                await controlRegistrationRef.current.registration,
                formContext?.attributeConfiguration,
                fieldControlStateRef.current,
                () => render(),
                formContext?.entityId,
                updatedPropertiesRef.current
            );
            if (!mountedRef.current) {
                return;
            }

            if (init) {
                controlInstanceRef.current.init(context, () => notifyOutputChanged(), fieldControlStateRef.current.getValues('pcf'), !isVirtual() && container.current);
            }

            updateView(context);
        }
        catch (err) {
            console.error(`Error message from custom control: ${props.name}`, err);
        }
    };

    const isVirtual = (): boolean => {
        return controlRegistrationRef.current.manifest.controlType == 'virtual';
    };

    const updateView = async (context: Context) => {
        if (isVirtual()) {
            if (!container.current) {
                console.warn("Container not found, skipping updateView.", props.name, props);
                return;
            }
            const pcfDomElement = controlInstanceRef.current.updateView(context);

            if (pcfDomElement) {
                window.ReactDOM.render(pcfDomElement, container.current);
            } else {
                throw new Error('updateView returned an invalid DOM element.');
            }
        } else {
            controlInstanceRef.current.updateView(context);
        }
    };

    useEffect(() => {
        // As per https://www.debuggr.io/react-update-unmounted-component/

        const initAsync = async () => {
            try {
                controlRegistrationRef.current = await ControlLoader.getAsync(props.name);
                if (formContext?.entityName && props.datafieldname) {
                    attributeMetadataRef.current = (await EntityDefinition.getAsync(formContext.entityName)).Attributes.find(x => x.LogicalName === props.datafieldname);
                }
                await controlRegistrationRef.current.load();

                ///@ts-ignore - The official PCF context doesn't expose constructor, but we need to create a new instance.
                controlInstanceRef.current = new (await controlRegistrationRef.current.registration).code();
                if (mountedRef.current) {
                    setControlInstance(controlInstanceRef.current);
                }
            }
            catch (err) {
                console.error(`Error message from custom control: ${props.name}`, err);

                if (mountedRef.current) setRenderFailed(true);
            }
            if (mountedRef.current) setIsRendered(true);
        };

        initAsync();

        return () => {
            mountedRef.current = false;
            if (controlInstanceRef.current) {
                controlInstanceRef.current.destroy();
                HistoryManager.activeControls.delete(controlInstanceRef.current);
            }
        };
    }, []);

    useEffect(() => {
        // When we run without from context - eg. standalone control like notifications, we are not expected to write back any changes.
        if (!formContext) return;
        // Do not update the control until we have initialized it first but schedule the update to run once we are initialized
        if (isInititalized === false) {
            if (scheduledUpdateRef.current === false) {
                scheduledUpdateRef.current = true;
                console.log(props.name, props.id, `Control not initialized yet, update will be scheduled.`);
            }
            return;
        }
        if (shouldUpdate === true && isInititalized === true) {
            scheduledUpdateRef.current = false;
            setShouldUpdate(false);
            console.log(props.name, props.id, `Running scheduled update which was missed during initialization.`);
        }

        formEntityChangesRef.current = formContext.entityChanges;

        let shouldPerformUpdate = false;
        updatedPropertiesRef.current = [];

        // TODO: Do we call updateView if output (update) came from this control?
        for (const [property, value] of Object.entries(formContext.entityChanges)) {
            // TODO: Replace has to be done properly for _xxx_value - this is for lookups to be notified
            const found = Object.values(props.bindings).find(x => x.value === property || x.value === property.replace('_value', '').replace('_', ''));
            if (found != null) {
                shouldPerformUpdate = true;
                if (value !== previousFormEntityChangesRef.current[property]) {
                    let updatedPropertyName;
                    for (const [propertyName, property] of Object.entries(props.bindings)) {
                        if (property.value === found.value) {
                            updatedPropertyName = propertyName;
                            break;
                        }
                    }
                    if (updatedPropertyName) {
                        updatedPropertiesRef.current.push(updatedPropertyName);
                    }
                }
            }
        }

        const attributes = Object.values(props.bindings).filter(x => x.isStatic === false).map(x => x.value);
        for (const attribute of attributes) {
            // When options change, we should send update to the control to refresh options
            if (formContext?.attributeConfiguration?.[attribute]?.options) {
                if (JSON.stringify(formContext?.attributeConfiguration[attribute]?.options) !== JSON.stringify(previousAttributeConfigurationRef.current[attribute]?.options)) {
                    shouldPerformUpdate = true;
                }
            }
        }

        if (previousPropsRef.current?.disabled != props.disabled || previousPropsRef.current?.label != props.label) {
            shouldPerformUpdate = true;
        }

        if (shouldPerformUpdate) {
            // console.log(props.name, props.id, `Calculated new updatedProperties:`, updatedProperties);

            previousPropsRef.current = props;
            previousFormEntityChangesRef.current = { ...formContext.entityChanges };
            render();

        }

        previousAttributeConfigurationRef.current = cloneDeep(formContext?.attributeConfiguration);
    }, [formContext?.entityChanges, props.label, props.disabled, formContext?.attributeConfiguration, shouldUpdate, isInititalized]);

    const _toBase64 = (file: File) => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    };

    const notifyOutputChanged = (): void => {
        // When we run without from context - eg. standalone control like notifications, we are not expected to write back any changes.
        if (!formContext) return;

        const outputs = controlInstanceRef.current.getOutputs();
        const changedFields: ComponentFramework.WebApi.Entity = {};

        for (const [property, binding] of Object.entries(props.bindings)) {
            const outputValue = outputs[property];
            const manifestDefinition = controlRegistrationRef.current.manifest.properties.find(x => x.name == property);

            if (manifestDefinition?.usage === 'bound' || manifestDefinition?.usage === 'output') {
                if (manifestDefinition?.ofType === "Lookup.Simple") {
                    const lookupValue = outputValue as ComponentFramework.LookupValue[];
                    changedFields[binding.value] = lookupValue ?? [];
                }
                else if (manifestDefinition?.ofType === "File") {
                    (async (newFileObject: IFileObject) => {
                        const storage = new FileAttribute(window.Xrm.WebApi);
                        if (newFileObject?._file) {
                            const connection: IFileAttributeConnection = {
                                entityName: formContext.entityName,
                                recordId: formContext.entityId,
                                fileAttribute: binding.value
                            };
                            const file = {
                                base64: await _toBase64(newFileObject._file),
                                name: newFileObject._file.name
                            };
                            const response = await storage.uploadFileToAttribute(file, connection) as Xrm.CreateResponse;
                            if (response?.id) {
                                newFileObject.fileId = response.id;
                                changedFields[binding.value] = newFileObject;

                            }
                        } else if (Object.keys(formContext.entity).includes(binding.value)) {
                            const entityMetadata = await EntityDefinition.getAsync(formContext.entityName);
                            const deleteSinglePropertyRequestUrl = `${SpaConfiguration.get().edsApi}/v9.2/${entityMetadata.EntitySetName}(${formContext.entityId})/${binding.value}`;
                            const requestInit: RequestInit = {
                                method: 'DELETE',
                            };
                            await executeAuthenticatedRequest(deleteSinglePropertyRequestUrl, requestInit);
                            changedFields[binding.value] = null;
                        }
                        formEntityChangesRef.current = { ...formEntityChangesRef.current, ...changedFields };
                        formContext.setEntityChanges({ ...formEntityChangesRef.current, ...changedFields });
                    })(outputValue);
                    return;
                }
                else {
                    if (outputValue !== undefined) {
                        changedFields[binding.value] = outputValue;
                    }
                    else if (Object.keys(outputs).includes(property)) {
                        changedFields[binding.value] = null;
                    }
                }

                let originalValue = formEntityChangesRef.current[binding.value];
                let newValue = changedFields[binding.value];

                if (originalValue instanceof Date || newValue instanceof Date) {
                    originalValue = originalValue?.getTime();
                    newValue = newValue?.getTime();
                }

                if (originalValue !== newValue && newValue !== undefined) {
                    if (binding.value === props.datafieldname && !datafieldChanged) {
                        setDatafieldChanged(true);
                    }
                }
            }
        }
        formContext.setEntityChanges({ ...formEntityChangesRef.current, ...changedFields });
    };

    useEffect(() => {
        // As per https://www.debuggr.io/react-update-unmounted-component/
        let mounted = true;

        if (controlInstance !== null && controlRegistrationRef.current !== null && !isInititalized) {
            const runAsync = async () => {
                await render(true);
                if (mounted) {
                    HistoryManager.activeControls.add(controlInstanceRef.current);
                    if (props.onControlInstanceSet) {
                        props.onControlInstanceSet(controlInstanceRef.current);
                    }
                    setIsInitialized(true);
                }

                if (scheduledUpdateRef.current) {
                    scheduledUpdateRef.current = false;
                    setShouldUpdate(true);
                }
            };
            runAsync();
        }

        return () => { mounted = false; };
    }, [controlInstance]);

    // if(renderFailed) {
    //     // TODO: Improve error handling when control load fails
    //     throw new Error("Failed to fetch");
    // }

    // TODO: This comes from FormMapper.ts and should be unified.
    // TODO: Primary (binding) field is the first field set to "bound" in PCF manifest, which we don't know here yet, so we just use the first non-static field
    const primaryBoundField = Object.entries(props.bindings).find(x => x[1].isStatic === false);
    const manifestDefinition = controlRegistrationRef.current?.manifest.properties.find(x => x.name == primaryBoundField?.[0]);
    const resultingEntity = { ...formContext?.entity, ...formContext?.entityChanges };
    const type = manifestDefinition?.ofType ?? DataType.SingleLineText;
    const isRequired = getRequiredLevel(formContext?.attributeConfiguration?.[primaryBoundField?.[1]?.value], props.isRequired);
    const isValid = formContext?.attributeConfiguration?.[primaryBoundField?.[1]?.value]?.isValid;

    return (
        <div className='TALXIS__control--field'>
            <div className={props.name} ref={container} />
            {!props.disableLoading && !isRendered &&
                <Loading />
            }
            {((!renderFailed && controlRegistrationRef.current && (formContext?.validate || datafieldChanged) && (isRequired === 1 || isRequired === 2) && !props.disabled && props.visible && primaryBoundField[1] &&
                !isFieldValid(isRequired, props.disabled, props.visible, type, resultingEntity, primaryBoundField[1].value, isValid)
            ) || isValid === false) === true &&
                <MessageBar messageBarType={MessageBarType.error} styles={{ root: { marginTop: 5 } }}>
                    {isValid === false && formContext?.attributeConfiguration?.[primaryBoundField?.[1]?.value]?.isValidMessage ?
                        formContext?.attributeConfiguration?.[primaryBoundField?.[1]?.value]?.isValidMessage :
                        window.TALXIS.Portal.Translations.getLocalizedString("@controls/FieldControl/FilledFieldError")
                    }
                </MessageBar>
            }
            {renderFailed &&
                <MessageBar>
                    {window.TALXIS.Portal.Translations.getLocalizedString("@controls/DatasetControl/ControlRenderError")}
                </MessageBar>
            }
        </div>
    );
};