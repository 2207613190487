import React from 'react';
import ReactDOM from "react-dom";
import { IDecimalProps } from './interfaces';
import DecimalField from './components';
import { ITextFieldItemProps } from '@talxis/react-components/dist/components/TextField';
import { Liquid } from "liquidjs";

interface IInputs {
    value: ComponentFramework.PropertyTypes.DecimalNumberProperty;
    enableDeleteButton?: ComponentFramework.PropertyTypes.StringProperty;
    enableCopyButton?: ComponentFramework.PropertyTypes.StringProperty;
    EnableBorder?: ComponentFramework.PropertyTypes.StringProperty;
    ForceDisable?: ComponentFramework.PropertyTypes.StringProperty;
}
interface IOutputs {
    value?: number;
}

export class Decimal implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _context: ComponentFramework.Context<IInputs>;
    private _notifyOutputChanged: () => void;
    private _container: HTMLDivElement;
    private _enableDeleteButton: boolean = false;
    private _enableCopyButton: boolean = false;
    private _value: number = 0;
    private _decimalSeparator: string;
    private _groupSeparator: string;
    private _liquid: Liquid;
    private _precision: number;

    constructor() {
        this._liquid = new Liquid();
    }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
        this._context = context;
        this._notifyOutputChanged = notifyOutputChanged;
        this._container = document.createElement("div");
        container.appendChild(this._container);
        this._decimalSeparator = this._context.userSettings.numberFormattingInfo.numberDecimalSeparator;
        this._groupSeparator = this._context.userSettings.numberFormattingInfo.numberGroupSeparator;
        this._precision = this._context.parameters.value.attributes.Precision;
        this._enableDeleteButton = this._context.parameters.enableDeleteButton?.raw === 'true';
        this._enableCopyButton = this._context.parameters.enableCopyButton?.raw === 'true';
    }

    public updateView(context: ComponentFramework.Context<IInputs>): void {
        this._context = context;
        this._value = this._context.parameters.value.raw ?? null;
        const deleteButtonProps: ITextFieldItemProps = {
            key: 'clickToDelete',
            showOnlyOnInputHover: true,
            onClick: this._onClick,
            iconProps: { iconName: 'Cancel' },
        };
        const copyButtonProps: ITextFieldItemProps = {
            key: 'clickToCopy',
            showOnlyOnInputHover: true,
            iconProps: { iconName: 'Copy' }
        };

        const decimalFieldProps: IDecimalProps = {
            value: this._context.formatting.formatDecimal(this._value, this._precision) ? this._context.formatting.formatDecimal(this._value, this._precision) : null,
            borderless: this._context.parameters.EnableBorder?.raw !== 'true',
            readOnly: this._context.mode.isControlDisabled,
            disabled: this._context.parameters.ForceDisable?.raw === 'true',
            decimalSeparator: this._decimalSeparator,
            groupSeparator: this._groupSeparator,
            deleteButtonProps: this._enableDeleteButton ? deleteButtonProps : null,
            clickToCopyProps: this._enableCopyButton ? copyButtonProps : null,
            getTranslatedString: this._getTranslation.bind(this),
            onBlurEvent: (value: string) => {
                if (value) {
                    const regEx = new RegExp(`[^0-9\\${this._decimalSeparator}\\${this._groupSeparator}\\-]`, 'g');
                    let adjustedValue = /\d/g.test(value) ? value.replace(regEx, "") : value;
                    adjustedValue = adjustedValue.replaceAll(this._decimalSeparator, '.').replaceAll(this._groupSeparator, '');
                    const newValue: number | undefined = !isNaN(Number(adjustedValue)) ? Number(adjustedValue) : undefined;

                    if (newValue !== undefined && this._value !== newValue) {
                        this._value = +newValue.toFixed(this._precision ?? 2);
                        this._notifyOutputChanged();
                    }
                    return this._context.formatting.formatDecimal(newValue, this._precision) ? this._context.formatting.formatDecimal(newValue, this._precision) : undefined;
                }
                this._value = null;
                this._notifyOutputChanged();
                return null;
            }
        };

        ReactDOM.render(React.createElement(DecimalField, decimalFieldProps), this._container);
    }

    private _getTranslation(string: string, variables: {} = {}): string {
        return Object.keys(variables).length > 0 ? this._liquid.parseAndRenderSync(this._context.resources.getString(string), variables) : this._context.resources.getString(string);
    }

    public getOutputs(): IOutputs {
        return {
            value: this._value ?? undefined
        };
    }

    public destroy(): void {
    }
    private _onClick = (): void => {
        this._value = null;
        this._notifyOutputChanged();
    }
}