import { ICommandBarItemProps } from "@talxis/react-components/dist/components/CommandBar/CommandBar.types";
import { useEffect, useState, useRef } from "react";
import { useTheme, mergeStyles, ContextualMenuItemType, IContextualMenuItem } from "@fluentui/react";
import "external-svg-loader";
import styles from './Ribbon.module.css';
import { PcfNavbarWrapper } from "@src/pages/Layout/components/Navbar/components/PcfNavbarWrapper";
import { FormControlType } from "@src/components/controls/native/Form/interfaces/enums";
import { ControlRegistration } from "@src/app/classes/loaders/ControlLoader";
import { Ribbon } from '@src/app/classes/models/Ribbon/Ribbon';
import { RibbonButtonOverrider } from "@src/app/classes/definitions/RibbonDefinition/RibbonButtonOverrider";

export const useRibbon = (ribbon?: Ribbon): ICommandBarItemProps[] => {
    const mountedRef = useRef<boolean>(true);
    const [commands, setCommands] = useState<ICommandBarItemProps[]>(null);
    const activeCommandIdsRef = useRef<string[]>([]);
    const cachedRibbonDefinitionRef = useRef<Ribbon.Definition.Root>(null);
    const theme = useTheme();
    useEffect(() => {
        (async () => {
            if (!ribbon || !ribbon.isEnabled()) {
                return;
            }
            await ribbon.init(refresh);
            await refresh();
        })();
    }, [ribbon]);
    const refresh = async () => {
        const commands = await getCommands();
        if (!mountedRef.current) {
            return;
        }
        setCommands(commands);

    };
    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);
    const getCommands = async () => {
        const commands: ICommandBarItemProps[] = [];
        const definition = cachedRibbonDefinitionRef.current ?? await ribbon.getDefinition();
        for (const group of definition.groups) {
            for (const button of group.buttons) {
                //TODO: replace with new logic for inline buttons
                if (button.visible === false || button.id === 'Mscrm.OpenRecordItem' || (button.isInline && !RibbonButtonOverrider.supportedNativeButtons.includes(button.command))) {
                    continue;
                }
                const item = await createButton(definition, button);
                commands.push(item);
            }
        }
        await ribbon.resolveControls();
        return commands;

    };
    const createButton = async (definition: Ribbon.Definition.Root, button: Ribbon.Definition.Button): Promise<ICommandBarItemProps> => {
        let controlInstance: any;
        let controlRegistration: ControlRegistration;
        const isFarItem: boolean = RibbonButtonOverrider.rightAlignedNativeButtons.includes(button.command);
        if (button.type === 'PCF') {
            [controlRegistration, controlInstance] = await ribbon.registerControl(button);
        }
        const item: ICommandBarItemProps = {
            key: button.id,
            name: button.label,
            ["data-id"]: button.id,
            ["data-command"]: button.command,
            farItem: isFarItem,
            iconOnly: isFarItem,
            className: styles.commandButton,
            disabled: activeCommandIdsRef.current.includes(button.id),
            split: button.type === 'SplitButton' && button.menuSections.flatMap(section => section.buttons).length > 0 ? true : false,
            onRenderIcon: onRenderIcon(button),
            iconProps: {
                iconName: button.icon?.type === 'fluent' ? button.icon?.value : undefined,
                imageProps: button.icon?.type === 'url' ? {
                    src: button.icon?.value
                } : undefined
            },
            onRender: button.type === "PCF" ? () => renderPCF(button, controlRegistration, controlInstance) : undefined,
            onClick: () => {
                (async () => {
                    activeCommandIdsRef.current.push(button.id);
                    cachedRibbonDefinitionRef.current = definition;
                    setCommands(await getCommands());
                    await button.function?.execute();
                    activeCommandIdsRef.current = activeCommandIdsRef.current.filter(x => x !== button.id);
                    setCommands(await getCommands());
                    cachedRibbonDefinitionRef.current = null;
                })();
            },
            subMenuProps: button.menuSections.flatMap(section => section.buttons).length > 0 ? {
                items: await Promise.all(button.menuSections.map(async section => {
                    return {
                        key: section.id,
                        itemType: ContextualMenuItemType.Section,
                        sectionProps: {
                            title: section.label,
                            bottomDivider: true,
                            items: await Promise.all(section.buttons.map(async subButton => {
                                return createButton(definition, subButton);
                            }))
                        }
                    } as IContextualMenuItem;
                }))
            } : undefined
        };

        return item;
    };
    const renderPCF = (button: Ribbon.Definition.Button, controlRegistration: ControlRegistration, controlInstance: any) => {
        return (
            <div className={`TALXIS__ribbon__pcf ${styles.pcf}`} onClick={button.function?.execute}>
                <PcfNavbarWrapper
                    bindings={{}}
                    id=""
                    classId=""
                    name={button.label}
                    datafieldname={null}
                    disabled={false}
                    type={FormControlType.Field}
                    visible={true}
                    isUnbound={true}
                    isRequired={false}
                    definition={controlRegistration}
                    instance={controlInstance}
                    onResolve={() => { }} />
            </div>);
    };
    const onRenderIcon = (button: Ribbon.Definition.Button) => {
        const value = button.icon?.value;
        const type = button.icon?.type;
        if (type === 'url' && value.includes('.svg')) {
            return () => <svg
                className={`TALXIS__ribbon__icon__svg ${mergeStyles({
                    width: 16,
                    height: 16,
                    marginLeft: 4,
                    marginRight: 4,
                    'path': {
                        fill: theme.palette.themePrimary
                    }
                })}`}
                data-src={value}
            />;
        }
        return undefined;
    };
    return commands;
};