import { ComboBox, IComboBoxProps } from '@talxis/react-components/dist/components/ComboBox';
import React from 'react';
import ReactDOM from "react-dom";

interface IInputs {
    value: ComponentFramework.PropertyTypes.OptionSetProperty;
    deleteButton?: ComponentFramework.PropertyTypes.StringProperty;
    EnableBorder?: ComponentFramework.PropertyTypes.StringProperty;
}
interface IOutputs {
    value?: number;
}

export class OptionSet implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _context: ComponentFramework.Context<IInputs>;
    private _notifyOutputChanged: () => void;
    private _container: HTMLDivElement;
    private _deleteButton: boolean = true;
    private _value: number;

    constructor() { }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
        this._context = context;
        this._notifyOutputChanged = notifyOutputChanged;
        this._container = document.createElement("div");
        this._deleteButton = this._context.parameters.deleteButton?.raw !== 'false';
        container.appendChild(this._container);
    }

    private _onClick = (): void => {
        this._value = null;
        this._notifyOutputChanged();
    }
    public updateView(context: ComponentFramework.Context<IInputs>): void {
        this._context = context;
        this._value = context.parameters.value.raw ?? context.parameters.value.attributes?.DefaultValue;

        const dropdownProps: IComboBoxProps = {
            placeholder: "---",
            borderless: this._context.parameters.EnableBorder?.raw !== 'true',
            readOnly: this._context.mode.isControlDisabled,
            options: this._context.parameters.value.attributes?.Options.map(x => { return { key: x.Value, text: x.Label }; }),
            selectedKey: this._value ?? null,
            deleteButtonProps: this._deleteButton ? {
                key: 'Delete',
                onClick: this._onClick,
                iconProps: { iconName: 'Cancel' },
                showOnlyOnHover: true
            } : undefined,
            // TODO: This should be probably on focus change or something, so we don't send updates back and forth
            onChange: (e: any, value: any) => {
                this._value = value.key as number;
                this._notifyOutputChanged();
            }
        };

        ReactDOM.render(React.createElement(ComboBox, dropdownProps), this._container);
    }

    public getOutputs(): IOutputs {
        return {
            value: this._value
        };
    }

    public destroy(): void {
    }
}