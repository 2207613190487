import React, { useContext, useRef } from 'react';
import { Control } from '../../../../';
import { IFormContext } from '../../interfaces/IFormContext';
import { FormContext } from '../../Form';
import { Form } from "../../interfaces/form";
import { Label } from '@fluentui/react/lib/components/Label/Label';
import { Icon } from '@fluentui/react/lib/components/Icon/Icon';
import { mergeStyles, useTheme } from '@fluentui/react';
import { getRequiredLevel } from '@ComponentFramework/PropertyClasses/Context';
import { FormControlType } from '../../interfaces/enums';

export interface ICellProps {
    cell: Form.Cell;
    index?: number;
    tabContentType?: 'cardSections' | 'singleComponent',
    formContext?: IFormContext;
    onFormUpdated: (arg: { formContext: Xrm.FormContext }) => void;
};

export const Cell: React.FC<ICellProps> = (props) => {
    const formContext: IFormContext = props.formContext ?? useContext(FormContext);
    const theme = useTheme();
    const cell = props.cell;
    const control = props.cell.control;
    const ref = useRef<HTMLDivElement>(null);
    let dataIdClass = "fieldControl_container";
    const requiredLevel = getRequiredLevel(formContext.attributeConfiguration[control?.datafieldname], control?.isRequired);
    if (cell?.control?.classId.toLowerCase() === "{4273edbd-ac1d-40d3-9fb2-095c621b552d}") {
        dataIdClass = "fieldControl-text-box-container";
    }
    const labelVisible = () => {
        if (props.tabContentType === 'singleComponent' && props.index === 0) {
            return false;
        }
        if (cell?.label && cell?.showLabel && cell?.control && cell?.control?.bindings?.EnableViewPicker?.value !== 'true' && cell?.control?.name !== 'TALXIS.PCF.Portal.Label') {
            return true;
        }
        return false;
    };
    const shouldDisplay = () => {
        if (((control && (control.visible ?? true)) || (!cell.control && (cell?.visible ?? true))) && (!formContext.isMobile || (cell?.availableForPhone ?? true))) {
            return true;
        }
        return false;
    };
    const isMultipleOrSubgrid = () => {
        const bindingTypeGroup = cell?.control?.definition?.manifest.properties[0]?.ofTypeGroup;
        if (cell?.control?.definition?.manifest.properties[0]?.ofType === 'Multiple') {
            return true;
        }
        if (bindingTypeGroup && cell?.control?.definition?.manifest.typeGroups.find(typeGroup => typeGroup.name === bindingTypeGroup && typeGroup.types.includes('Multiple')))
            if (cell?.control?.definition?.manifest.typeGroups.find(typeGroup => typeGroup.types.includes('Multiple'))) {
                return true;
            }
        if (cell.control?.name === 'TALXIS.PCF.Portal.View') {
            return true;
        }
        return false;
    };
    if (props.tabContentType === 'singleComponent' && props.index !== 0) {
        return <></>;
    }
    return (
        //In dialogs controls may not be defined for label placeholders and in that case labels should be displayed
        <>
            <div ref={ref} style={cell.control?.type === FormControlType.DataSet ? {
                '--cell-display-mode': 'block',
                '--cell-label-width': 'initial'
            } as React.CSSProperties : undefined} data-is-multiple-line={isMultipleOrSubgrid()} data-use-available-vertical-space={cell.auto} className={`TALXIS__form__cell ${mergeStyles({
                gridColumnEnd: cell.colspan && `span ${cell.colspan}`,
                gridRowEnd: cell.rowspan && `span ${parseInt(cell.rowspan) * 5}`,
                '.TALXIS__form__cell__required-indicator': {
                    color: theme.semanticColors.errorIcon,
                    marginLeft: 3
                },
                '>div>label>[data-icon-name^="Lock"]': {
                    visibility: control?.disabled ? 'visible' : 'hidden'
                }

            })}`}>
                {/* //we always need to render the top level cell element even if it's not visible
                //if we do not, the layout with spacers might get broken as we do not seperate between rows
                //in HTML (to allow rowspan for elements), if the cells disappears the spacers might appear in different row which break the layout */}
                {shouldDisplay() &&
                    <div>
                        {labelVisible() &&
                            <>
                                <Label>
                                    <Icon iconName='Lock12' />
                                    <span>{cell.label}</span>
                                    {(requiredLevel === 1 || requiredLevel === 2) &&
                                        <span className='TALXIS__form__cell__required-indicator'>*</span>
                                    }
                                </Label>
                            </>
                        }
                        {cell?.control &&
                            <div data-id={`${cell.control?.id}.${dataIdClass}`}>
                                <Control cellId={cell.id} formContext={formContext}
                                    {...cell.control}
                                    bindings={{
                                        ...cell.control.bindings,
                                        ...(props.tabContentType === 'singleComponent' ? {
                                            UseContainerAsHeight: {
                                                isStatic: true,
                                                value: 'true'
                                            }
                                        } : undefined)
                                    }}
                                    label={cell.label}
                                    childeventlisteners={cell.control.name?.includes('PCF.Portal.Form') ? [
                                        {
                                            eventname: "__onFormUpdated",
                                            eventhandler: props.onFormUpdated
                                        }
                                    ] : undefined} />
                            </div>
                        }
                    </div>
                }
            </div>
        </>
    );
};
export default Cell;