import React from 'react';
import styles from './VerticalNavigationLoading.module.css';
import { Shimmer } from '@fluentui/react/lib/components/Shimmer/Shimmer';
import { ShimmerElementType } from '@fluentui/react/lib/components/Shimmer/Shimmer.types';
import { ITheme, ThemeProvider, getTheme } from '@fluentui/react';

interface IVerticalPageNavigationProps {
    theme?: ITheme;
}

export const VerticalNavigationLoading: React.FC<IVerticalPageNavigationProps> = (props) => {
    return (
        <ThemeProvider theme={props.theme ?? getTheme()} className={styles.root}>
            <div className={styles.header}>
                <Shimmer
                    className={styles.sectionHeader}
                    shimmerElements={[
                        { type: ShimmerElementType.gap, width: 15, height: 30 },
                        { type: ShimmerElementType.line, height: 10 },
                        { type: ShimmerElementType.gap, width: 15, height: 30 }
                    ]} />
            </div>
            <div className={styles.body}>
                {Array.from(Array(20).keys()).map((item, i) =>
                    <Shimmer
                        key={i}
                        className={styles.sectionHeader}
                        shimmerElements={[
                            { type: ShimmerElementType.gap, width: 15, height: 30 },
                            { type: ShimmerElementType.line, height: 10 },
                            { type: ShimmerElementType.gap, width: 15, height: 30 }
                        ]} />
                )}
            </div>
            <div className={styles.footer} />
        </ThemeProvider>
    );
};