import { SiteMap } from "@src/app/interfaces/sitemap";
import { AppModule } from "../configuration/AppModule";
import { Theme } from "./Theme";
import { ManifestDefinition } from "../definitions/ManifestDefinition";
import { IWebManifest } from "@src/app/interfaces/manifest";

export class Manifest {
    private _theme: Theme;
    private _currentAppModule: AppModule;
    constructor(theme: Theme, currentAppModule: AppModule) {
        this._theme = theme;
        this._currentAppModule = currentAppModule;
        this._injectManifestFile();
    }

    public async injectSubAreaShortcuts(subAreas: SiteMap.SubArea[]) {
        const appModuleManifest = ManifestDefinition.getLocallyStoredManifestForCurrentAppModule();
        const url = `${window.location.origin}`;
        appModuleManifest.manifest.shortcuts = [];
        for (const subArea of subAreas) {
            const iconUrl = subArea.icon?.value && await this._getOptimizedIconUrl(subArea.icon.value);
            appModuleManifest.manifest.shortcuts.push({
                name: subArea.title,
                url: `${url}${subArea.url}`,
                icons: iconUrl ? [
                    {
                        src: iconUrl,
                        sizes: '192x192'
                    }
                ] : []
            });
        }
        const stringManifest = JSON.stringify(appModuleManifest.manifest);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        window.localStorage.setItem(`${this._currentAppModule.uniquename}_manifest`, JSON.stringify({
            baseAppModuleIcon: this._currentAppModule.icon.value,
            manifest: appModuleManifest.manifest
        }));
        document.head.querySelector('link[rel="manifest"]').setAttribute('href', manifestURL);

    }

    private _getOptimizedIconUrl = async (iconUrl: string): Promise<string> => {
        const isMac = navigator.userAgent.indexOf('Mac') != -1;
        const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const image = new Image();
        image.width = 192;
        image.height = 192;
        if (!iconUrl.startsWith('http')) {
            iconUrl = `${window.location.origin}${iconUrl}`;
        }
        const imageUrl = new URL(iconUrl);
        //&talxisIcon parameter is used so the url does not end with .com since it breaks icon fetching
        imageUrl.searchParams.append('talxis_icon', 'true');
        image.src = imageUrl.toString();
        image.crossOrigin = "anonymous";
        return new Promise((resolve) => {
            image.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = 192;
                canvas.height = 192;
                const ctx = canvas.getContext('2d');
                if (iOS) {
                    ctx.fillStyle = 'white';
                    //adds background to the icon
                    ctx.fillRect(0, 0, 192, 192);
                }
                if (iOS || isMac) {
                    //adds padding to the icon
                    ctx.drawImage(image, 21, 21, 150, 150);
                }
                else {
                    ctx.drawImage(image, 0, 0, 192, 192);
                }
                resolve(canvas.toDataURL('image/png'));
            };
        });
    }
    private _injectManifestInfoToLoading = () => {
        const mainLoading = document.querySelector('[class*="MainLoading_root"]');
        if (!mainLoading) {
            return;
        }
        mainLoading.querySelector('[class*="MainLoading_loadingText"]').innerHTML = this._currentAppModule.name;
        mainLoading.querySelector('[class*="MainLoading_icon"]>img').setAttribute('src', this._currentAppModule.icon.value);
    }
    private async _injectManifestFile() {
        const appIconUrl = await this._getOptimizedIconUrl(this._currentAppModule.icon.value);
        const url = `${window.location.origin}/${this._currentAppModule.uniquename}/`;
        //TODO: read the shortcuts from the local storage
        const manifest: IWebManifest = {
            "theme_color": this._theme.getNavbarTheme().semanticColors.bodyBackground,
            "background_color": this._theme.bodyBackgroudColor,
            "display": "standalone",
            "description": this._currentAppModule.description,
            "name": this._currentAppModule.name,
            "short_name": this._currentAppModule.name,
            "scope": url,
            "start_url": url,
            "id": `/${this._currentAppModule.uniquename}/`,
            "orientation": "any",
            "display_override": ["window-controls-overlay"],
            "icons": [
                {
                    "src": appIconUrl,
                    "sizes": "192x192",
                    "type": "image/png",
                },
                {
                    "src": appIconUrl,
                    "sizes": "256x256",
                    "type": "image/png",
                },
                {
                    "src": appIconUrl,
                    "sizes": "384x384",
                    "type": "image/png",
                },
                {
                    "src": appIconUrl,
                    "sizes": "512x512",
                    "type": "image/png",
                }
            ]
        };
        this._injectManifestInfoToLoading();
        //reinject shortcuts to manifest file while in PWA mode
        if (ManifestDefinition.isOpenedInPWA()) {
            const shortcuts = ManifestDefinition.getLocallyStoredManifestForCurrentAppModule()?.manifest.shortcuts;
            if (shortcuts) {
                manifest.shortcuts = shortcuts;
            }
        }
        const stringManifest = JSON.stringify(manifest);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        window.localStorage.setItem(`${this._currentAppModule.uniquename}_manifest`, JSON.stringify({
            baseAppModuleIcon: this._currentAppModule.icon.value,
            manifest: manifest
        }));
        document.head.querySelector('link[rel="manifest"]').setAttribute('href', manifestURL);
    }

}