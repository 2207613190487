import React from 'react';
import { useState, useRef } from 'react';
import { TextField } from '@fluentui/react/lib/components/TextField';
import { Text } from '@fluentui/react/lib/Text';
import Dialog from '../Dialog';
import { rootStyles } from './Styles';
import { FontIcon } from '@fluentui/react/lib/components/Icon/FontIcon';
import { DialogFooter } from '@fluentui/react/lib/components/Dialog/DialogFooter';
import { IErrorDialogProps } from './interfaces';
import { defaultProps } from '../Dialog/Constants';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

const ErrorDialog: React.FC<IErrorDialogProps> = (props) => {
    const [displayMoreDetails, setDisplayMoreDetails] = useState<boolean>(false);
    const moreDetailsTextFieldRef = useRef<HTMLDivElement>(null);
    const copyValueToClipboard = (ref: any) => {
        const input: HTMLInputElement = ref.current.getElementsByTagName('textarea')[0];
        input.focus();
        input.select();
        document.execCommand("copy");
    };
    return (
        <Dialog
            {...props}
            minWidth={400}
            modalProps={{
                ...props.modalProps,
                className: `${props.modalProps.className} ${rootStyles} `
            }}
            dialogContentProps={{
                ...props.dialogContentProps,
                title: <Text variant='xLarge'>
                    <FontIcon className='TALXIS-Portal-errorDialog-title-icon' iconName='ErrorBadge' /> {props.dialogContentProps.title}
                </Text>
            }}>
            {displayMoreDetails &&
                <div ref={moreDetailsTextFieldRef}>
                    <TextField
                        value={props.dialogContentProps.moreDetailsText}
                        multiline
                        readOnly={true}
                        resizable={true}
                        rows={10}
                    />
                </div>
            }
            {
                props.hidden !== undefined &&
                <DialogFooter>
                    {props.dialogContentProps.moreDetailsText && !displayMoreDetails &&
                        <DefaultButton
                            text={window.TALXIS.Portal.Translations.getLocalizedString("components/dialogs/ErrorDialog/ShowDetails")}
                            onClick={() => setDisplayMoreDetails(true)} />}
                    {displayMoreDetails &&
                        <DefaultButton
                            text={window.TALXIS.Portal.Translations.getLocalizedString("components/dialogs/ErrorDialog/CopyError")}
                            onClick={() => copyValueToClipboard(moreDetailsTextFieldRef)} />}
                    <PrimaryButton text={props.dialogContentProps.cancelButtonLabel ?
                        props.dialogContentProps.cancelButtonLabel : window.TALXIS.Portal.Translations.getLocalizedString("components/dialogs/ErrorDialog/Close")} onClick={props.onDismiss} />
                </DialogFooter>
            }
        </Dialog >
    );
};
export default ErrorDialog;
ErrorDialog.defaultProps = defaultProps;