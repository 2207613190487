
import { IQuickFindView, IDataSetColumn, IViewDefinition } from '@controls/native/View/interfaces/viewdefinition';
import { EntityDefinition } from './EntityDefinition';
import { ColumnLayoutJson } from '../../interfaces/columnLayoutJson';
import { AppComponents } from '@configuration/AppComponents';
import { LocalizeLabel } from '@localization/helpers';
import { serializeFetchXml } from '@src/components/controls/DatasetControl/utils/FetchXmlUtils';
import { DataType } from '@ComponentFramework/interfaces/DataType';
import { IPromiseCache, cachedWrapper } from '@utilities/MemoryCachingHelpers';
import { sanitizeGuid } from '../../Functions';
import { sendMetadataGetRequest, metadataRetrieveMultiple } from './MetadataApi';
import { DomParser, ribbonButtonsColumnName } from '../../Constants';

export class ViewDefinition {
    private static _viewDefinitions: IPromiseCache<IViewDefinition> = {};
    private static _quickFindViewDefinitions: IPromiseCache<IQuickFindView> = {};
    private static _lookupViewDefinitions: IPromiseCache<IQuickFindView> = {};
    private static _entityViewIdNameMap: IPromiseCache<{
        savedqueryid: string,
        name: string
    }[]> = {};

    static async getAsync(queryId: string, entityName: string): Promise<IViewDefinition> {
        queryId = sanitizeGuid(queryId);
        return cachedWrapper(queryId, () => new Promise(async (resolve, reject) => {
            try {
                const response = await sendMetadataGetRequest(`v9.1/savedqueries(${queryId})?$select=name,savedqueryid,fetchxml,returnedtypecode,layoutjson`);
                const _viewDefinitions: IViewDefinition = await response.json();
                const columns = await this.createColumnsAsync(_viewDefinitions, _viewDefinitions.layoutjson, _viewDefinitions.fetchxml);
                const viewDefinition: IViewDefinition = {
                    name: _viewDefinitions.name,
                    savedqueryid: _viewDefinitions.savedqueryid,
                    fetchxml: _viewDefinitions.fetchxml,
                    columns: columns,
                    layoutjson: _viewDefinitions.layoutjson,
                    returnedtypecode: _viewDefinitions.returnedtypecode
                };
                resolve(viewDefinition);
            }
            catch (err) {
                reject(err);
            }
        }), this._viewDefinitions);
    };

    static async getQuickFindViewAsync(entityName: string): Promise<IQuickFindView> {
        return cachedWrapper(entityName, () => new Promise(async (resolve, reject) => {
            const quickFindDefinitions = await metadataRetrieveMultiple(`v9.1/savedqueries?$select=fetchxml&$filter=querytype eq 4 and isquickfindquery eq true and returnedtypecode eq '${entityName}'`);

            if (quickFindDefinitions.entities && quickFindDefinitions.entities[0]) {
                resolve(quickFindDefinitions.entities[0] as IQuickFindView);
            }
            else {
                reject(`Didn't find a Quick Find View for entity ${entityName}`);
            }
        }), this._quickFindViewDefinitions);
    };

    static async getLookupViewAsync(entityName: string): Promise<IQuickFindView> {
        return cachedWrapper(entityName, () => new Promise(async (resolve, reject) => {
            // Attempt to obtain lookup view from App Module
            const appModuleViewIds = await AppComponents.filterAppComponents(entityName, 26);
            if (appModuleViewIds.length > 0) {
                const lookupViews = await metadataRetrieveMultiple(`v9.1/savedqueries?$select=fetchxml&$filter=querytype eq 64 and (${appModuleViewIds.map(x => `savedqueryid eq ${x}`).join(" or ")})`);
                if (lookupViews.entities.length > 0) {
                    if (lookupViews.entities.length > 1) {
                        console.warn(`Found multiple lookup views in App Module for entity ${entityName}, selecting first one!`);
                    }
                    resolve(lookupViews.entities[0] as IQuickFindView);
                    return;
                }
            }

            // No lookup views found in the App Module, fall back to global search
            const quickFindDefinitions = await metadataRetrieveMultiple(`v9.1/savedqueries?$select=fetchxml&$filter=isdefault eq true and querytype eq 64 and returnedtypecode eq '${entityName}'`);

            if (quickFindDefinitions.entities && quickFindDefinitions.entities[0]) {
                resolve(quickFindDefinitions.entities[0] as IQuickFindView);
            }
            else {
                reject(`Didn't find a Quick Find View for entity ${entityName}`);
            }
        }), this._lookupViewDefinitions);
    };

    static async getViewNamesAndIds(entityName: string, viewIds?: string[]): Promise<{ savedqueryid: string; name: string }[]> {
        const key = `${entityName}_${viewIds?.join('_')}`;

        return cachedWrapper(key, () => new Promise(async (resolve) => {
            let _viewIds = viewIds;
            if (!_viewIds) {
                _viewIds = await AppComponents.filterAppComponents(entityName, 26);
            }

            let filter = `?$filter=returnedtypecode eq '${entityName}' and querytype eq 0&$select=name,savedqueryid`;
            if (_viewIds?.length > 0) {
                filter = `?$select=name,savedqueryid&$filter=querytype eq 0 and (${_viewIds.map(x => `savedqueryid eq ${x}`).join(' or ')})`;
            }
            const result = await metadataRetrieveMultiple(`v9.1/savedqueries${filter}`);
            const views: { savedqueryid: string; name: string }[] = [];
            for (const view of result.entities) {
                views.push({
                    savedqueryid: view["savedqueryid"],
                    name: LocalizeLabel(view["__labels"]?.["name"]) ?? view.name
                });
            }
            resolve(views);
        }), this._entityViewIdNameMap);
    }

    public static async createColumnsAsync(viewDefinition: IViewDefinition, layoutJson: string, _fetchXml: string): Promise<IDataSetColumn[]> {
        const columns: IDataSetColumn[] = [];
        if (!layoutJson) {
            throw new Error(`Could not render the view ${viewDefinition.savedqueryid} because layoutjson is missing.`);
        }
        const layout = JSON.parse(layoutJson) as ColumnLayoutJson.RootObject;
        const fetchXml = DomParser.parseFromString(_fetchXml, "application/xml");
        const cells = layout.Rows[0].Cells;
        const viewEntityDefinition = await EntityDefinition.getAsync(viewDefinition.returnedtypecode);
        let order = 0;
        for (const cell of cells) {
            const entityName = cell.RelatedEntityName === "" ? viewDefinition.returnedtypecode : cell.RelatedEntityName;
            let entityDefinition = await EntityDefinition.getAsync(entityName);

            let odataExpandAttribute: string = null;

            const isExpand = cell.Name.includes('.');
            let alias = "";
            if (isExpand) {
                const fetchXmlAlias = cell.Name.split('.')[0];
                const linkEntity = [...fetchXml.getElementsByTagName("link-entity")].find(x => x.getAttribute("alias") === fetchXmlAlias);
                const linkEntityName = linkEntity.getAttribute("name");

                if (cell.RelatedEntityName === "") {
                    entityDefinition = await EntityDefinition.getAsync(linkEntityName);
                }

                // Attempt to find the proper relationship name, check both types because we can be link-entity in a link-entity (see entityName above)
                let relationship = viewEntityDefinition.OneToManyRelationships.find(x =>
                    x.ReferencingAttribute === linkEntity.getAttribute("from") &&
                    x.ReferencedAttribute === linkEntity.getAttribute("to") &&
                    x.ReferencingEntity === linkEntityName);
                if (!relationship) {
                    relationship = viewEntityDefinition.ManyToOneRelationships.find(x =>
                        x.ReferencingAttribute === linkEntity.getAttribute("to") &&
                        x.ReferencedAttribute === linkEntity.getAttribute("from") &&
                        (x.ReferencedEntity === linkEntity.getAttribute("name") || x.ReferencedEntity === linkEntityName));
                    odataExpandAttribute = relationship.ReferencingEntityNavigationPropertyName;
                }
                else {
                    odataExpandAttribute = relationship.ReferencedEntityNavigationPropertyName;
                }

                alias = linkEntity.getAttribute("alias");
                if (!alias) {
                    alias = linkEntity.getAttribute("to");
                    linkEntity.setAttribute("alias", alias);
                }

                if (linkEntity.parentElement.tagName === "link-entity") {
                    let parent: Element;
                    let parentEntityName: string;
                    do {
                        parent = linkEntity.parentElement;
                        parentEntityName = parent.getAttribute("name");
                        // TODO: We should unify this code across the whole portal
                        let parentExpandAttribute: string = null;
                        let relationship = viewEntityDefinition.OneToManyRelationships.find(x =>
                            x.ReferencingAttribute === parent.getAttribute("from") &&
                            x.ReferencedAttribute === parent.getAttribute("to") &&
                            x.ReferencingEntity === parentEntityName);
                        if (!relationship) {
                            relationship = viewEntityDefinition.ManyToOneRelationships.find(x =>
                                x.ReferencingAttribute === parent.getAttribute("to") &&
                                x.ReferencedAttribute === parent.getAttribute("from") &&
                                (x.ReferencedEntity === parent.getAttribute("name") || x.ReferencedEntity === parentEntityName));
                            parentExpandAttribute = relationship.ReferencingEntityNavigationPropertyName;
                        }
                        else {
                            parentExpandAttribute = relationship.ReferencedEntityNavigationPropertyName;
                        }
                        odataExpandAttribute = `${parentExpandAttribute}.${odataExpandAttribute}`;
                    } while (parent.parentElement.tagName === "link-entity");
                }
            }
            const cellName = cell.Name.split(".").pop();
            const attribute = entityDefinition.Attributes.filter(attribute => attribute.LogicalName === cellName)[0];
            // TODO: Handle null display name
            let displayName = LocalizeLabel(attribute?.DisplayName.LocalizedLabels) ?? cell.Name;
            if (displayName === ribbonButtonsColumnName) {
                displayName = null;
            }

            // const alias = entityDefinition.LogicalName === viewDefinition.returnedtypecode ? "" : entityDefinition.LogicalName;
            const name = (alias !== "" ? `${alias}.` : "") + (attribute?.LogicalName ?? cell.Name);
            let dataType: DataType = null;
            // TODO: Support more types in future, https://docs.microsoft.com/en-us/power-apps/developer/component-framework/reference/column#datatype
            switch (attribute?.AttributeType) {
                case "DateTime":
                    switch (attribute?.Format) {
                        case "DateAndTime":
                            dataType = DataType.DateAndTimeDateAndTime;
                            break;
                        default:
                            dataType = DataType.DateAndTimeDateOnly;
                            break;
                    }
                    break;
                case "Picklist":
                case "State":
                case "Status":
                    dataType = DataType.OptionSet;
                    break;
                case "Money":
                    dataType = DataType.Currency;
                    break;
                case "Lookup":
                    switch (attribute?.Format) {
                        case "Regarding":
                            dataType = DataType.LookupRegarding;
                            break;
                        default:
                            dataType = DataType.LookupSimple;
                            break;
                    }
                    break;
                case "Customer":
                    dataType = DataType.LookupCustomer;
                    break;
                case "Owner":
                    dataType = DataType.LookupOwner;
                    break;
                case "Decimal":
                    dataType = DataType.Decimal;
                    break;
                case "Decimal":
                case "Double":
                    dataType = DataType.FP;
                    break;
                case "Integer":
                    if (attribute.Format === 'Duration') {
                        dataType = DataType.WholeDuration;
                    }
                    else {
                        dataType = DataType.WholeNone;
                    }
                    break;
                case "Boolean":
                    dataType = DataType.TwoOptions;
                    break;
                case "Virtual":
                    if (attribute.AttributeTypeName.Value === "MultiSelectPicklistType") {
                        dataType = DataType.MultiSelectPicklist;
                        break;
                    }
                    else if (attribute.AttributeTypeName.Value === "FileType") {
                        dataType = DataType.File;
                        break;
                    }
                    else if (attribute.AttributeTypeName.Value === "ImageType") {
                        dataType = DataType.Image;
                        break;
                    }
                    else {
                        console.warn("Unsupported data type for Virtual type encountered!", attribute.AttributeTypeName.Value, attribute);
                        dataType = DataType.SingleLineText;
                        break;
                    }
                case "String":
                default:
                    switch (attribute?.FormatName?.Value) {
                        case 'Url':
                            dataType = DataType.SingleLineUrl;
                            break;
                        case 'Email':
                            dataType = DataType.SingleLineMail;
                            break;
                        case 'Phone': {
                            dataType = DataType.SingleLinePhone;
                            break;
                        }
                        default: {
                            dataType = DataType.SingleLineText;
                        }
                    }
                    break;
            }
            columns.push({
                isHidden: cell.IsHidden,
                disableSorting: cell.DisableSorting,
                alias: `${alias}`,
                dataType: dataType,
                displayName: displayName,
                isPrimary: attribute?.IsPrimaryId || attribute?.IsPrimaryName,
                name: name,
                order: order++,
                visualSizeFactor: cell.Width,
                __odataExpandAttribute: odataExpandAttribute,
                __relatedEntityName: cell.RelatedEntityName
            });
        }

        viewDefinition.fetchxml = serializeFetchXml(fetchXml);

        return columns;
    };
}